import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {isEqual} from "lodash";
import {Backdrop, Card, Divider, Grid, Typography, Button} from "@mui/material";
import {makeStyles} from "@mui/styles";
import MultipleSelector from "../../../../common/multipleSelector/MultipleSelector";
import {getStationOptions, STATION_SEARCHABLE_FIELDS} from "../../../../../utils/stationUtil";
import OmitSensors from "./OmitSensors";


const useStyles = makeStyles({
    card: {
        paddingTop: 16,
        paddingBottom: 10,
    },
    title: {
        marginLeft: 30,
        color: "#434343",
        marginBottom: 16
    },
    buttons: {
        marginRight: 30,
        display: "flex",
        marginTop: 10,
        justifyContent: "flex-end"
    },
    dividerBottom: {
        marginTop: 8
    },
    popUpContainer: {
        maxWidth: 1080,
        minWidth: 800
    },
    selectorContainer: {
        padding: 30,
        maxWidth: 1000,
        justifyContent: "center"
    }
});

const SELECT_STATION = 0;
const SELECT_SENSOR = 1;

const PublicStationsPopup = ({open, initialSelection, saveCallback, closeCallback}) => {

    const {t} = useTranslation();
    const classes = useStyles();

    const [stationsValid, setStationsValid] = useState(false);
    const [selectedStations, setSelectedStations] = useState([]);
    const [step, setStep] = useState(SELECT_STATION);

    const stations = useSelector(state => getStationOptions(state.dashboardUI.stations, ["sensors"]), isEqual);

    const filteredStations = useMemo(()=>{
        return stations.map(item => {return {...item,sensors: item.sensors.filter(item2 => !["wind"].includes(item2))};});
    },[stations]);

    useEffect(() => {
        if (initialSelection) {
            console.log("initialSelection",initialSelection);
            const output = initialSelection.map((item) => {
                const station = filteredStations.find((station) => station.id === item.id);
                if (station)
                    return {...station, omitSensors: item.omitSensors ?? []};
                return undefined;
            }).filter((station) => station !== undefined);
            setSelectedStations(output);
        }
    }, [initialSelection, filteredStations]);

    const setStationStepValid = useCallback((value)=>{
        setStationsValid(value);
    },[setStationsValid]);

    const updateSelectedStations = useCallback((data) => {
        setSelectedStations(prevSelectedStations => {
            return data.map(item => {
                const station = prevSelectedStations.find(station => station.id === item.id);
                return station ?? {...item, omitSensors: []};
            });
        });
    }, []);

    const handleSave = useCallback(() => {
        setStep(SELECT_STATION);
        saveCallback(selectedStations.map((item) => {
            return {id: item.id, omitSensors: item.omitSensors};
        }));
        closeCallback();
    }, [selectedStations, saveCallback, closeCallback]);

    const handleNext = useCallback(() => {
        setStep(SELECT_SENSOR);
    }, [setStep]);

    const handleBack = useCallback(() => {
        setStep(SELECT_STATION);
    }, [setStep]);

    const handleSensorChange = useCallback((station, sensor) => {
        const output = selectedStations.map(item => {
            if (item.id !== station)
                return item;
            else
                return {
                    ...item,
                    omitSensors: item.omitSensors.includes(sensor) ? item.omitSensors.filter(item => item !== sensor) : [...item.omitSensors, sensor]
                };
        });
        setSelectedStations(output);
    }, [selectedStations]);

    return open && (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={open}
        >
            <Card className={classes.card} data-testid={"public-stations-popup"}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"h6"}
                                    className={classes.title}>{t("organizationPublic.select_stations")}</Typography>
                        <Divider/>
                    </Grid>


                    {step === SELECT_STATION &&
                        <Grid container item xs={12} className={classes.selectorContainer}>
                            <MultipleSelector initialSelected={selectedStations}
                                              items={filteredStations}
                                              isStationList
                                              selectedCallback={updateSelectedStations}
                                              onValidateSelection={setStationStepValid}
                                              min={1}
                                              searchableFields={STATION_SEARCHABLE_FIELDS}
                                              isStation={true}
                            />
                        </Grid>
                    }{
                    step === SELECT_SENSOR &&
                    <Grid container item xs={12} className={classes.selectorContainer}>
                        <OmitSensors selectedStations={selectedStations} handleSensorChange={handleSensorChange}/>
                    </Grid>
                }
                    <Grid item xs={12}>
                        <Divider className={classes.dividerBottom}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            {step === SELECT_SENSOR &&
                                <Button
                                    onClick={_ => {
                                        handleBack();
                                        closeCallback();
                                    }}>{t("cancel")}</Button>}
                            <Button
                                onClick={step === SELECT_STATION ? closeCallback : handleBack}>{step === SELECT_STATION ? t("cancel") : t("back")}</Button>
                            <Button
                                data-testid={"save-stations"}
                                disabled={step === SELECT_STATION && !stationsValid}
                                onClick={step === SELECT_SENSOR ? handleSave : handleNext}>{step === SELECT_SENSOR ? t("action.save") : t("next")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default PublicStationsPopup;
