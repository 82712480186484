import {useState} from "react";
import {TextField} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";
import "moment/locale/es";

const esFormat = ["ca", "es"].some((lang) => window.navigator.language.startsWith(lang));

export const getLocale = ()=> {
    if (esFormat) {
        return "es";
    } else {
        return "en";
    }
};

const DatePickerField = ({size, variant, value, onChange, ...props}) => {
    const [inputValue, setInputValue] = useState(moment.utc(value));

    const handleChange = (newValue) => {
        setInputValue(newValue);
        // Check if the value is a valid object
        if (newValue && newValue.isValid()) {
            const newDate = new Date(newValue.toDate());
            newDate.setUTCHours(0, 0, 0, 0);
            onChange(newDate);
        } else {
            onChange(null);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment} adapterLocale={getLocale()}>
            <DatePicker
                value={inputValue}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField size={size} variant={variant} {...params}  />
                )}
                {...props}
            />
        </LocalizationProvider>
    );
};

export default DatePickerField;
