import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ScheduleAlarmCardView from "./ScheduleAlarmCardView";
import clsx from "clsx";
import NotificationsCardView from "./NotificationsCardView";
import {downloadAlarmsRequest} from "../../../../requests/alarms/schedule/downloadAlarmRequest";
import useUnits from "../../../../hooks/useUnits";
import {useMountComponent} from "../../../../hooks/useMountComponent";
import useMessage from "../../../../hooks/useMessage";
import useRequest from "../../../../hooks/useRequest";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    root: {
        maxWidth: 1800,
        paddingBottom: 20,
        paddingTop: 20,
        paddingLeft: 40,
        paddingRight: 40,
    },
    card: {
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
        background: "white",
        padding: 20,
        borderRadius: 5,
        minHeight: 245
    },
    firstRowCard: {
        minHeight: 500
    },
    secondRowCard: {
        minHeight: 600
    }
});

const AlarmsScreen = () => {

    const classes = useStyles();

    const {t} = useTranslation();
    const timer = useRef(null);
    const {units} = useUnits();
    const [alarms, updateAlarms] = useState([]);
    const [loading, updateLoading] = useState(false);
    const isMounted = useMountComponent();
    const {setError} = useMessage();
    const {handleErrorResponse} = useRequest();

    useEffect(() => {
            loadAlarmData(true, true,true);
            return () => {
                if (timer.current) {
                    clearTimeout(timer.current);
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);


    const loadingCallback = useCallback((loading)=>{
        updateLoading(loading);
    },[]);

    const loadAlarmData = useCallback((displayLoading = false, displaySuccess = false, displayError = false) => {

        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(loadAlarmData, 15000, false, false, false);

        if (displayLoading)
            updateLoading(true);

        downloadAlarmsRequest(units.temperature, units.pollutants, (err, data) => {

            if (!isMounted.current) {
                return;
            }
            if (displayLoading)
                updateLoading(false);

            if (!err && data) {
                updateAlarms(data);
            }
            if (err) {
                handleErrorResponse(data, response => {
                    if (displayError) {
                        setError(response, false, `${t("error")} ${response.status},
                        ${t("alarmScreen.error_fetching_alarms")}`);
                    }
                });
            }
        });
    }, [updateLoading, units.temperature, units.pollutants, isMounted, updateAlarms, handleErrorResponse, setError, t]);

    return (
        <Grid className={classes.root} container direction={"row"}
              alignItems={"center"} justifyContent="space-around" spacing={2}>
            <Grid item xs={12}>
                <ScheduleAlarmCardView
                    loadingCallback={loadingCallback}
                    loading = {loading}
                    alarms={alarms}
                    updateAlarmCallback={loadAlarmData}
                    className={clsx(classes.card, classes.firstRowCard)}
                />
            </Grid>
            <Grid item xs={12}>
                <NotificationsCardView
                    className={clsx(classes.card, classes.secondRowCard)}
                    alarms={alarms}
                    updateAlarmCallback={loadAlarmData}
                />
            </Grid>
        </Grid>
    );
};

export default AlarmsScreen;
