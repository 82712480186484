import React, {useCallback, useEffect, useState} from 'react';
import {
    Card,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import HelpPopup from "../../../../common/HelpPopup";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {updateLegalThresholdsRequest} from "../../../../../requests/organization/organizationRequests";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import CardViewLoading from "../../../../common/chart/ChartLoading";
import DataNotFound from "../../../../common/DataNotFound";
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../hooks/useAuth";
import useMessage from "../../../../../hooks/useMessage";
import useRequest from "../../../../../hooks/useRequest";
import {WRITE_ORGANIZATION_CONFIG} from "../members/UserPermissions";
import SaveButton from "../../../../common/buttons/SaveButton";
import {legalThresholdsOptions} from "../../../../../constants";
import {updateOrganizationAction} from "../../../../../reducers/authReducer";
import {useDispatch} from "react-redux";

const useStyles = makeStyles({
    saveButton: {
        color:'#ffffff',
    },
    title: {
        fontWeight:"bold"
    },
    helpButton: {
        color:"black"
    }
});

const ThresholdsConfig = ({className, legalThresholdsConfig}) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { hasPermission } = useAuth();
    const [{organizationThreshold, saving}, updateState] = useState({
        organizationThreshold: null, saving: false
    });
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [loadedConfig, setLoadedConfig] = useState(null);
    const isMounted = useMountComponent();
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const {handleErrorResponse} = useRequest();
    const {setError,setSuccess} = useMessage();

    const classes = useStyles();

    const resetData = useCallback(() => {
        updateState(state => ({...state, organizationThreshold: legalThresholdsConfig}));
    }, [legalThresholdsConfig]);

    useEffect(() => {
        if (legalThresholdsConfig) {
            setLoading(false);
            setLoadingError(false);
            resetData();
            setLoadedConfig(legalThresholdsConfig);
        } else {
            setLoading(legalThresholdsConfig !== null);
            setLoadingError(legalThresholdsConfig === null);
        }
    }, [legalThresholdsConfig, resetData]);

    const handleThresholdChange = (event) => {
        updateState(state =>({...state, organizationThreshold: event.target.value}));
    };

    const handleSave = useCallback(() => {
        updateState(state =>({...state, saving: true}));
        const data = {legalThreshold: organizationThreshold};
        updateLegalThresholdsRequest(data).then(response => {
            if (!isMounted.current) return;
            if (response.status === 204) {
                setSuccess("organizationLegalThreshold.legal_threshold_updated");
                setLoadedConfig(organizationThreshold);
                dispatch(updateOrganizationAction(data));
            }
        }).catch(error => {
            if (!isMounted.current) return;
            resetData();
            handleErrorResponse(error, response => {
                setLoading(false);
                setError(response,
                    false,
                    `${t("error")} ${response.status},
                    ${t("organizationLegalThreshold.updating_organization_legal_threshold")}`);
            });
        }).finally(() => {
            if (!isMounted.current) return;
            updateState(state => ({...state, saving: false}));
        });
    }, [handleErrorResponse, isMounted, organizationThreshold, resetData, setError, setSuccess, t, dispatch]);

    const hasChanges = loadedConfig !== organizationThreshold;

    return (
        <Card className={className}>
            {loading && <CardViewLoading/>}
            {loadingError && <DataNotFound/>}
            {!loading && !loadingError && (
                <Grid container direction={"row"} spacing={2}>
                    <Grid item container xs={12}>
                        <Typography className={classes.title} variant={"h4"}>{t("organizationLegalThreshold.title")}</Typography>
                        <IconButton className={classes.helpButton} aria-label="help"
                                    onClick={e => setAnchorEl(e.currentTarget)}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel >{t("organizationLegalThreshold.legal_threshold")}</InputLabel>
                            <Select
                                id={"preference-selector-threshold"}
                                data-testid = "preference-threshold"
                                disabled={saving || !hasPermission(WRITE_ORGANIZATION_CONFIG)}
                                onChange={handleThresholdChange}
                                label={t("organizationLegalThreshold.legal_threshold")}
                                value={organizationThreshold || ""}
                            >
                                {Object.keys(legalThresholdsOptions).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {t(`analyticScreen.rdThreshold.legalThresholdsName.${key.toLowerCase()}`)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={8} lg={9}/>
                    {hasPermission(WRITE_ORGANIZATION_CONFIG) && <Grid item xs={12} md={4} lg={3}>
                        <SaveButton className={classes.saveButton} data-testid={"preference-save"}
                                    disabled={saving || !hasChanges} saving={saving} fullWidth
                                    onSave={handleSave} />
                    </Grid>}
                </Grid>
            )}
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("organizationLegalThreshold.help")}/>
        </Card>
    );
};

export default ThresholdsConfig;
