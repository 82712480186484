import {Button, Card, CardContent, CircularProgress, Grid, IconButton, Link, Tooltip, Typography} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGrid} from "@mui/x-data-grid";
import {makeStyles} from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import clsx from "clsx";
import ConfirmDialog from "../../../common/ConfirmDialog";
import {useDispatch, useSelector} from "react-redux";
import {useMountComponent} from "../../../../hooks/useMountComponent";
import DownloadProgress from "../download/DownloadProgress";
import ReportConfigPopUp from "./report_config_menu/ReportConfigPopUp";
import {ERROR_500} from "../../../../constants";
import {downloadReportListRequest} from "../../../../requests/report/downloadReportListRequest";
import {createReportRequest} from "../../../../requests/report/createReportRequest";
import {setReportListAction} from "../../../../reducers/reportReducer";
import {deleteReportRequest} from "../../../../requests/report/deleteReportRequest";
import {webClient} from "../../../../config/axios";
import CommonDialog from "../../../CommonDialog";
import {useTranslation} from "react-i18next";
import useLoading from "../../../../hooks/useLoading";
import useMessage from "../../../../hooks/useMessage";
import useAuth from "../../../../hooks/useAuth";
import {READ_ORGANIZATION_REPORTS, WRITE_REPORTS} from "../organization/members/UserPermissions";
import DataNotFound from "../../../common/DataNotFound";
import downloadFileFromBlob from "../../../../utils/downloadFileFromBlob";
import {getFormattedDateTime} from "../../../../utils/getFormattedDateTime";
import ReportDetailPopUp from "./ReportDetailPopUp";
import DescriptionIcon from "@mui/icons-material/Description";
import useRequest from "../../../../hooks/useRequest";


const LIST_PAGE_SIZE = 7;
const AUTO_REPORT_LIST_PAGE_SIZE = 4;

const useStyles = makeStyles({
    root: {
        maxWidth: 1800,
        paddingBottom: 20,
        paddingTop: 20,
        paddingLeft: 40,
        paddingRight: 40,
    },
    error: {
        color: "#d22929",
        fontWeight: "bold"
    },
    card: {
        background: "white",
        padding: 20,
        borderRadius: 5,
        minHeight: 245
    },
    manualReportCard: {
        minHeight: 600
    },
    autoReportCard: {
        minHeight: 439
    },
    cardTitle: {
        marginLeft: 20,
        color: "#000000",
        fontWeight: "bold"
    },
    deleteButton: {
        color: "#ffffff",
        background: "#f6343d",
        '&:hover': {
            background: "#81191c"
        },
    },
    rowTable: {
        display: "flex",
        width: "100%vw",
        justifyContent: "center",
        marginTop: 32
    },
    table: {
        maxWidth: 1600,
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {},
    },
    manualReportTable: {
        height: 475
    },
    autoReportTable: {
        height: 319
    },
    not_deletable: {
        fontWeight: '500',
        color: '#bcbcbc',
        backgroundColor: 'rgba(105,105,105,0.05)',
    },
    deletable: {}
});

const DOWNLOAD_LOADING = "downloadLoading";
const DOWNLOAD_ERROR = "downloadError";
const DOWNLOAD_SUCCESS = "downloadSuccess";

const downloadStatusReducer = (state, action) => {
    const {id, status} = action;
    return {
        ...state,
        [id]: status
    };
};

const popUpDialogOptions = {
    closed: "closed",
    openRegularReport: "openRegularReport",
    openAutoReport: "openAutoReport"
};


const ReportScreen = () => {

    const classes = useStyles();
    const {t} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const {reportList, reportProcessingProgressList} = useSelector(state => state.report);
    const isMounted = useMountComponent();
    const dispatch = useDispatch();
    const {loading, setLoading} = useLoading();
    const {setError} = useMessage();
    const {hasPermission} = useAuth();
    const [downloadStatus, dispatchDownloadStatus] = React.useReducer(downloadStatusReducer, {});
    const [reportDetail, setReportDetail] = useState(null);

    const [{
        enableDelete,
        enableAutoReportDelete,
        selectionModel,
        autoReportSelectionModel,
        deleteConfirmeDialogState,
        configPopUpState,
        repeatedReportId
    }, updateState] =
        useState({
            autoReportSelectionModel: [],
            selectionModel: [],
            repeatedReportId: "",
            deleteConfirmeDialogState: popUpDialogOptions.closed,
            enableDelete: false,
            enableAutoReportDelete: false,
            data: [],
            selectedDate: new Date(),
            configPopUpState: popUpDialogOptions.closed
        });

    const hideDialog = useCallback(() => {
        updateState(state => ({...state, deleteConfirmeDialogState: popUpDialogOptions.closed}));
    }, []);

    const handleDeleteButtonPressed = () => {
        updateState(state => ({...state, deleteConfirmeDialogState: popUpDialogOptions.openRegularReport}));
    };

    const handleDeleteAutoReportButtonPressed = () => {
        updateState(state => ({...state, deleteConfirmeDialogState: popUpDialogOptions.openAutoReport}));
    };

    const handleDetail = useCallback((id) => {
        setReportDetail(id);
    }, []);

    const handleReportDownload = useCallback(async (report) => {
        const {id, name} = report;
        dispatchDownloadStatus({id, status: DOWNLOAD_LOADING});
        const url = `/report/${id}`;
        try {
            const response = await webClient.get(url, {responseType: 'blob', timeout: 0});
            downloadFileFromBlob(response.data, `${name}.pdf`, "application/pdf");
            dispatchDownloadStatus({id, status: DOWNLOAD_SUCCESS});
        } catch (err) {
            dispatchDownloadStatus({id, status: DOWNLOAD_ERROR});
            setError("reportScreen.download_error");
        }
    }, [dispatchDownloadStatus, setError]);

    const columns = useMemo(() => [
        {
            field: 'name', headerName: t("reportScreen.name"), width: 320, cellClassName: (params) => (
                params.row.status.state === "completed" || params.row.status.state === "error" ?
                    classes.deletable : classes.not_deletable)
        },
        {
            field: 'status', headerName: t("reportScreen.status"), width: 280,
            renderCell: (params) => {
                switch (params.value.state) {
                    case "queue":
                        return <Typography>{t("reportScreen.queued")}</Typography>;
                    case "progress":
                        if (reportProcessingProgressList.find(item => item.id === params.row.id) === undefined) {
                            return <Typography>{t("reportScreen.processing")}</Typography>;
                        } else {
                            return <DownloadProgress
                                progress={reportProcessingProgressList.find(item => item.id === params.row.id).progress}/>;
                        }
                    case "scheduled":
                        return <Typography>{t("reportScreen.scheduled")}</Typography>;
                    case "completed":
                        return <Typography>{t("reportScreen.completed")}</Typography>;
                    case "error":
                        return <Typography className={classes.error}>{t("error")}</Typography>;
                    default:
                        return;
                }
            },
            cellClassName: (params) => (
                params.row.status.state === "completed" || params.row.status.state === "error" ?
                    classes.deletable : classes.not_deletable)
        },
        {
            field: 'id', headerName: t("reportScreen.link"), hide: false, width: 180, sortable: false,
            renderCell: (params) => {
                const reportId = params.value;
                const status = downloadStatus[reportId];
                switch (status) {
                    case DOWNLOAD_LOADING:
                        return <CircularProgress size={20}/>;
                    default:
                        return params.row.status.state === "completed" ?
                            <Link component="button" underline="always" onClick={(event) => {
                                event.stopPropagation();
                                handleReportDownload(params.row);
                            }}>{t(status !== DOWNLOAD_ERROR ? "reportScreen.view_report"
                                : "reportScreen.retry")}</Link> : <div/>;
                }
            },
            cellClassName: (params) => (
                params.row.status.state === "completed" || params.row.status.state === "error" ?
                    classes.deletable : classes.not_deletable)
        },
        {
            field: 'creationTime', headerName: t("reportScreen.last_generation"), width: 240,
            renderCell: (params) => <Typography>{
                params.value ? getFormattedDateTime(new Date(params.value)) : "--"
            }</Typography>
        },
        {
            field: 'actions', headerName: t("common.actions"), width: 120,
            renderCell: (params) =>
                <Tooltip title={t("reportScreen.reportConfig.view_detail")}>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            handleDetail(params.id);
                        }} size="small">
                        <DescriptionIcon fontSize="inherit"/>
                    </IconButton>
                </Tooltip>
        }
    ], [t, classes.deletable, classes.not_deletable, classes.error, reportProcessingProgressList, downloadStatus, handleReportDownload, handleDetail]);

    const autoColumns = useMemo(() => [
        ...columns,
        {
            field: 'nextReportTime', headerName: t("reportScreen.next_generation_date"), width: 240,
            renderCell: (params) => <Typography>{
                params.value ? getFormattedDateTime(new Date(params.value)) : "--"
            }</Typography>
        }
    ], [columns, t]);

    const onAutoReportSelectionModelChange = (newSelectionModel) => {
        updateState((state) => {
            const editableItems =
                !hasPermission(WRITE_REPORTS) ? [] : newSelectionModel.filter((reportId) => {
                    const report = reportList.find((report) => report.id === reportId);
                    return report.status.state === "scheduled" || report.status.state === "completed" || report.status.state === "error";
                });
            return {
                ...state,
                enableAutoReportDelete: editableItems.length !== 0,
                autoReportSelectionModel: editableItems
            };
        });
    };


    const onSelectionModelChange = (newSelectionModel) => {
        updateState((state) => {
            const editableItems =
                !hasPermission(WRITE_REPORTS) ? [] : newSelectionModel.filter((reportId) => {
                    const report = reportList.find((report) => report.id === reportId);
                    return report.status.state === "completed" || report.status.state === "error";
                });
            return {...state, enableDelete: editableItems.length !== 0, selectionModel: editableItems};
        });
    };

    const updateTable = useCallback(() => {
        updateState(state => ({...state, enableDelete: false}));
        setLoading(true);
        downloadReportListRequest((err, data) => {
            if (!isMounted.current) {
                return;
            }
            setLoading(false);
            if (!err && data) {
                if (data.length > 0) {
                    dispatch(setReportListAction(data));
                }
            }
            if (err) {
                handleErrorResponse(data, response => {
                    switch (response.status) {
                        case 500:
                            setError(ERROR_500, true);
                            break;
                        default:
                            setError(response,
                                false,
                                `${t("error")} ${response.status},
                                ${t("reportScreen.error_fetching")}`);
                    }
                });
            }
        });
    }, [t, isMounted, dispatch, setLoading, setError, handleErrorResponse]);

    const finishReportSetup = useCallback((body) => {
        updateState(state => ({...state, configPopUpState: popUpDialogOptions.closed}));
        setLoading(true);
        createReportRequest(body, (err, data) => {
            if (!isMounted.current) {
                return;
            }
            setLoading(false);
            if (!err) {
                dispatch(setReportListAction(data));
            } else {
                handleErrorResponse(data, response => {
                    switch (response.status) {
                        case 403:
                            updateState(state => ({...state, repeatedReportId: response.error}));
                            break;
                        case 500:
                            setError(ERROR_500, true);
                            break;
                        default:
                            setError(response,
                                false,
                                "reportScreen.could_not_create_report");
                    }
                });
            }
        });

    }, [dispatch, isMounted, updateState, setLoading, setError, handleErrorResponse]);

    const cancelReportSetup = useCallback(() => {
        updateState(state => ({...state, configPopUpState: popUpDialogOptions.closed}));
    }, [updateState]);

    const handleConfigureNewReport = () => {
        updateState((state => ({...state, configPopUpState: popUpDialogOptions.openRegularReport})));
    };

    const handleConfigureNewAutoReport = () => {
        updateState((state => ({...state, configPopUpState: popUpDialogOptions.openAutoReport})));
    };

    const confirmDelete = useCallback(() => {

        const reportsToDelete = deleteConfirmeDialogState === popUpDialogOptions.openRegularReport ? selectionModel : autoReportSelectionModel;

        hideDialog();
        setLoading(true);
        Promise.all(deleteReportRequest(reportsToDelete)).then(() => {
            if (!isMounted.current) {
                return;
            }
            updateTable();
        }).catch(err => {
            if (!isMounted.current) {
                return;
            }
            setLoading(false);
            if (err.response) {
                handleErrorResponse(err.response, response => {
                    switch (response.status) {
                        case 403:
                        case 404:
                            updateTable();
                            setError("reportScreen.error_deleting");
                            break;
                        case 500:
                            setError(ERROR_500, true);
                            break;
                        default:
                            setError(response,
                                false,
                                `${t("error")} ${response.status},
                                ${t("reportScreen.error_deleting")}`);
                    }
                });
            } else {
                setError("reportScreen.error_deleting_unknown");
            }
        });
    }, [deleteConfirmeDialogState, selectionModel, autoReportSelectionModel, hideDialog, setLoading, isMounted,
        updateTable, handleErrorResponse, setError, t]);

    const handleClose = () => {
        updateState(state => ({...state, repeatedReportId: ""}));
    };

    const handleCloseReportDetail = useCallback(() => {
        setReportDetail(null);
    }, []);

    const manualReports = reportList.filter(report => !report.autoReport).reverse();
    const autoReports = reportList.filter(report => report.autoReport).reverse();

    return <>
        <Grid className={classes.root} container direction={"row"}
              alignItems={"center"} justifyContent="space-around" spacing={2}>
            <Grid item xs={12}>
                <Card className={clsx(classes.card, classes.autoReportCard)}>
                    <CardContent>
                        <Grid container direction={"row"} spacing={2} justifyContent={"space-between"}
                              alignItems={"center"}>
                            <Grid container item xs={12} sm={12} md={3} lg={5} xl={6} alignItems={"flex-start"}
                                  alignContent={"flex-start"}>
                                <Typography className={classes.cardTitle}
                                            variant={"h5"}>{t("reportScreen.automatic_reports")}</Typography>
                            </Grid>
                            {hasPermission(WRITE_REPORTS) && <>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                    <Button
                                        data-testid={"report-screen-add-auto-report"}
                                        fullWidth
                                        disabled={loading}
                                        color={"primary"}
                                        variant="contained"
                                        startIcon={<AddIcon/>}
                                        onClick={handleConfigureNewAutoReport}
                                    >
                                        {t("reportScreen.configure_auto_report")}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <Button
                                        data-testid={"report-screen-delete-auto-report"}
                                        fullWidth
                                        disabled={loading || !enableAutoReportDelete}
                                        variant="contained"
                                        className={classes.deleteButton}
                                        startIcon={<DeleteIcon/>}
                                        onClick={handleDeleteAutoReportButtonPressed}
                                    >
                                        {t("delete")}
                                    </Button>
                                </Grid>
                            </>}
                            {!hasPermission(READ_ORGANIZATION_REPORTS) ? (
                                <Grid item xs={12}>
                                    <DataNotFound/>
                                </Grid>
                            ) : (
                                <Grid item xs={12} className={classes.rowTable}>
                                    <DataGrid
                                        className={clsx(classes.table, classes.autoReportTable)}
                                        rows={autoReports}
                                        columns={autoColumns.map((column) => {
                                                return {
                                                    ...column, disableClickEventBubbling: column.field === "id"
                                                };
                                            }
                                        )}
                                        columnBuffer={8}
                                        pageSize={AUTO_REPORT_LIST_PAGE_SIZE}
                                        rowsPerPageOptions={[AUTO_REPORT_LIST_PAGE_SIZE]}
                                        Name="dataGrid1"
                                        selectionModel={autoReportSelectionModel}
                                        onSelectionModelChange={onAutoReportSelectionModelChange}
                                        checkboxSelection={hasPermission(WRITE_REPORTS)}
                                        disableSelectionOnClick={true}
                                        density={"standard"}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={clsx(classes.card, classes.manualReportCard)}>
                    <CardContent>
                        <Grid container direction={"row"} spacing={2} justifyContent={"space-between"}
                              alignItems={"center"}>
                            <Grid container item xs={12} sm={12} md={4} lg={6} alignItems={"flex-start"}
                                  alignContent={"flex-start"}>
                                <Typography className={classes.cardTitle}
                                            variant={"h5"}>{t("reportScreen.reports")}</Typography>
                            </Grid>
                            {hasPermission(WRITE_REPORTS) && <>
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <Button
                                        data-testid={"report-screen-add"}
                                        fullWidth
                                        disabled={loading}
                                        color={"primary"}
                                        variant="contained"
                                        startIcon={<AddIcon/>}
                                        onClick={handleConfigureNewReport}
                                    >
                                        {t("reportScreen.configure_report")}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <Button
                                        data-testid={"report-screen-delete"}
                                        fullWidth
                                        disabled={loading || !enableDelete}
                                        variant="contained"
                                        className={classes.deleteButton}
                                        startIcon={<DeleteIcon/>}
                                        onClick={handleDeleteButtonPressed}
                                    >
                                        {t("delete")}
                                    </Button>
                                </Grid>
                            </>}
                            {!hasPermission(READ_ORGANIZATION_REPORTS) ? (
                                <Grid item xs={12}>
                                    <DataNotFound/>
                                </Grid>
                            ) : (
                                <Grid item xs={12} className={classes.rowTable}>
                                    <DataGrid
                                        className={clsx(classes.table, classes.manualReportTable)}
                                        rows={manualReports}
                                        columns={columns.map((column) => {
                                                return {
                                                    ...column, disableClickEventBubbling: column.field === "id"
                                                };
                                            }
                                        )}
                                        columnBuffer={8}
                                        pageSize={LIST_PAGE_SIZE}
                                        rowsPerPageOptions={[LIST_PAGE_SIZE]}
                                        Name="dataGrid1"
                                        selectionModel={selectionModel}
                                        onSelectionModelChange={onSelectionModelChange}
                                        checkboxSelection={hasPermission(WRITE_REPORTS)}
                                        density={"standard"}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        {reportDetail !== null &&
            <ReportDetailPopUp open={reportDetail} handleCloseDetailCallback={handleCloseReportDetail}
                               reportDetail={reportList.find(item => item.id === reportDetail)}/>}
        {configPopUpState !== popUpDialogOptions.closed &&
            < ReportConfigPopUp open={configPopUpState !== popUpDialogOptions.closed}
                                isAutoReport={configPopUpState === popUpDialogOptions.openAutoReport}
                                finishReportSetup={finishReportSetup}
                                cancelReportSetup={cancelReportSetup}/>}
        {deleteConfirmeDialogState !== popUpDialogOptions.closed &&
            <ConfirmDialog hideDialog={hideDialog} title={t("reportScreen.delete_report")}
                           question={t("reportScreen.are_you_sure_to_delete")}
                           confirm={confirmDelete}/>
        }
        {repeatedReportId !== "" && <CommonDialog title={t("warning")} description={t("reportScreen.there_is_already") +
            reportList.find(item => item.id === repeatedReportId).name + t("reportScreen.with_this_config")}
                                                  handleClose={handleClose}/>}
    </>;
};

export default ReportScreen;
