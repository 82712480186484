import React, {useCallback, useEffect, useState} from 'react';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import HelpButton from "../../../../common/buttons/HelpButton";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {periodTypeColor} from "../../../../../constants";
import {shallowEqual, useSelector} from "react-redux";
import AutoCompleteWithCheckBox from "../../../../common/AutoCompleteWithCheckBox";
import {getAvailableSensors, getStationOptions} from "../../../../../utils/stationUtil";
import {eachOf} from "async";
import {postInvalidPeriodsRequest} from "../../../../../requests/validation/postInvalidPeriodsRequest";
import useMessage from "../../../../../hooks/useMessage";
import LoadingButton from "../../../../common/buttons/LoadingButton";
import DateTimePickerField from "../../../../common/DateTimePickerField";
import moment from 'moment-timezone';
import {periodTypes} from "bettairplaformutil/src/validationUtil";
import {getVariableName} from "../../pollutantNames";
import usePeriodData from "../usePeriodData";
import {usePeriodContext} from "../common/PeriodContext";


const useStyles = makeStyles({
    cardTitle: {
        marginLeft: 20,
        color: "#000000",
        fontWeight: "bold"
    },
    dateTimePicker: {
        "& .MuiInputBase-root": {
            minHeight: 60
        }
    }
});

const getInitialDate = () => {
    const date = moment().subtract(7, 'days').startOf('day');
    const browserTimeZone = moment.tz.guess();
    const offset = moment.tz(date, browserTimeZone).utcOffset();
    date.add(offset, 'minutes');
    return date.toDate();
};

const getFinalDate = () => {
    const date = moment().endOf('day');
    const browserTimeZone = moment.tz.guess();
    const offset = moment.tz(date, browserTimeZone).utcOffset();
    date.add(offset, 'minutes');
    date.subtract(4, 'minutes');
    return date.toDate();
};

const QuickValidation = ({}) => {

    const {setError, setSuccess} = useMessage();

    const classes = useStyles();
    const {t} = useTranslation();
    const stations = useSelector(state => state.dashboardUI.stations);
    const dataTimeZone = useSelector(state => state.auth.timeZone);
    const stationSensors = useSelector(state => (
        getAvailableSensors(state.dashboardUI.stations).map(sensor => sensor.id)
    ), shallowEqual);
    const {selectedStation: detailSelectedStation} = usePeriodContext();
    const {updateValidationCalendar, updateValidationHistory} = usePeriodData();

    const [selectedFlag, setSelectedFlag] = useState(periodTypes.generated);
    const [selectedStations, setSelectedStations] = useState([]);
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [finalDate, updateFinalDate] = useState(getFinalDate());
    const [initialDate, updateInitialDate] = useState(getInitialDate());
    const [datesError, setDatesError] = useState(false);
    const [saving, setSaving] = useState(false);

    const setSensorCallback = useCallback((values) => {
        setSelectedSensors(values);
    }, []);

    const setStationCallback = useCallback((values) => {
        setSelectedStations(values);
    }, []);

    const handleFlagChange = useCallback((value) => {
        setSelectedFlag(value);
    }, []);

    const updateInitialDateCallback = useCallback((value) => {
        updateInitialDate(value);
    }, []);

    const updateFinalDateCallback = useCallback((value) => {
        updateFinalDate(value);
    }, []);

    const clearForm = useCallback(() => {
        setSelectedStations([]);
        setSelectedSensors([]);
        setSelectedFlag(periodTypes.generated);
        updateInitialDate(getInitialDate());
        updateFinalDate(getFinalDate());
        setDatesError(false);
    }, []);

    const saveValidPeriods = useCallback(async() => {

        setSaving(true);

        try {
            await eachOf(selectedStations.map(item => item.value), async (station) => {

                const stationSensors = stations.find((item) => item.id === station).sensors;

                const sensors = selectedSensors.map(item => item.value).filter((sensor) => stationSensors.includes(sensor));

                const periods = {};

                sensors.forEach((sensor) => {
                    periods[sensor] = [{
                        type: selectedFlag,
                        time: initialDate.toISOString(),
                        endtime: finalDate.toISOString()
                    }];
                });

                await postInvalidPeriodsRequest({
                    station,
                    time: initialDate.toISOString(),
                    endtime: finalDate.toISOString(),
                    periods
                });
            });
            setSuccess("validation.quick_validation_success");
            return true;
        } catch (err) {
            setError(err, false, "validation.quick_validation_error_saving");
            return false;
        } finally {
            setSaving(false);
        }
    }, [finalDate, initialDate, selectedFlag, selectedSensors, selectedStations, setError, setSuccess, stations]);

    const handleSave = useCallback(async () => {
        const result = await saveValidPeriods();
        if (!result) return;
        clearForm();
        if (selectedStations.find(item => item.value === detailSelectedStation)) {
            updateValidationCalendar();
            updateValidationHistory();
        }
    }, [saveValidPeriods, clearForm, selectedStations, detailSelectedStation, updateValidationCalendar,
        updateValidationHistory]);

    useEffect(() => {
        setDatesError(initialDate > finalDate);
    }, [finalDate, initialDate]);

    return (
        <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Grid container columns={48} alignItems={"flex-start"} alignContent={"center"} justifyContent={"flex-end"}
                  spacing={2}>
                <Grid item xs={48} display="flex" alignItems="center" alignContent="flex-start"
                      style={{minHeight: 100}}>
                    <Typography className={classes.cardTitle}
                                variant={"h5"}>{t("validation.quick_validation")}</Typography>
                    <HelpButton helpText={t("validation.quick_validation_help")}/>
                </Grid>

                <Grid item xl={14} lg={16} md={24} sm={48} xs={48}>
                    <AutoCompleteWithCheckBox value={selectedStations}
                                              setValue={setStationCallback}
                                              label={t("validation.stations")}
                                              items={getStationOptions(stations).map(item => {
                                                  return {value: item.id, ...item};
                                              })}
                                              placeholder={t("validation.select")}
                                              disabled={saving}
                                              isStation={true}/>
                </Grid>
                <Grid item xl={10} lg={16} md={24} sm={48} xs={48}>
                    <AutoCompleteWithCheckBox value={selectedSensors} setValue={setSensorCallback}
                                              label={t("validation.sensors")}
                                              shipSize={160}
                                              items={stationSensors.map(item => {
                                                  return {value: item, label: getVariableName(item)};
                                              })}
                                              placeholder={t("validation.select")}
                                              disabled={saving}/>
                </Grid>
                <Grid item xl={7} lg={8} md={14} sm={48} xs={48}>
                    <FormControl fullWidth margin={"none"}>
                        <DateTimePickerField label={t("common.start_date")}
                                             className={classes.dateTimePicker}
                                             disabled={saving}
                                             error={datesError}
                                             variant="filled"
                                             value={initialDate}
                                             onChange={updateInitialDateCallback}
                                             maxDate={finalDate}
                        />
                    </FormControl>

                </Grid>
                <Grid item xl={7} lg={8} md={14} sm={48} xs={48}>
                    <FormControl fullWidth margin={"none"}>
                        <DateTimePickerField label={t("common.end_date")}
                                             className={classes.dateTimePicker}
                                             disabled={saving}
                                             variant="filled"
                                             value={finalDate}
                                             error={datesError}
                                             onChange={updateFinalDateCallback}
                                             minDate={initialDate}
                        />
                    </FormControl>
                </Grid>
                <Grid item xl={6} lg={8} md={11} sm={48} xs={48}>
                    <FormControl variant="filled" fullWidth margin={"none"}>
                        <InputLabel>{t("validation.flags")}</InputLabel>
                        <Select
                            disabled={saving}
                            style={{height: 60}}
                            onChange={(event) => handleFlagChange(event.target.value)}
                            label={t("validation.flags")}
                            value={selectedFlag}
                        >
                            {Object.values(periodTypes).map(item =>
                                <MenuItem key={item} value={item}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <div style={{
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            backgroundColor: periodTypeColor[item],
                                            marginRight: '5px',
                                            border: item === periodTypes.generated ? '1px solid #808080' : `1px solid ${periodTypeColor[item]}`,
                                        }}/>
                                        {`${t(`validation.validationFlags.${item}`)}`}
                                    </div>
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xl={4} lg={8} md={9} sm={48} xs={48}>
                    <LoadingButton
                        onClick={handleSave}
                        loading={saving}
                        customLoadingText={`${t("action.saving")}...`}
                        disabled={selectedStations.length === 0 || selectedSensors.length === 0}
                        fullWidth variant={"contained"} style={{height: '60px'}}>
                        {t("action.validate")}
                    </LoadingButton>
                </Grid>
                <Grid item xs={48} container alignItems="center" justifyContent="center">
                    <Typography
                        variant={"caption"}>{dataTimeZone === "UTC" ? t("validation.utc_time_zone") : t("validation.station_time_zone")}</Typography>
                </Grid>

            </Grid>
        </Grid>
    );
};

export default QuickValidation;
