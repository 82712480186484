import React from 'react';
import {Typography} from "@mui/material";

const LegendItem = ({key, id, label, color, borderColor, isHidden, onItemSelected}) => {

    const handleMouseDown = (event) => {
        // Prevent selection on double click
        if (event.detail > 1) {
            event.preventDefault();
        }
    };

    const handleClick = () => {
        if (onItemSelected) {
            onItemSelected(id);
        }
    };

    return (
        <div key={key} onClick={handleClick} onMouseDown={handleMouseDown} style={{
            marginTop: 5,
            marginBottom: 5,
            display: "flex",
            alignItems: "center",
            cursor: onItemSelected ? "pointer" : "default"
        }}>
            <Dot color={color} borderColor={borderColor} isHidden={isHidden}/>
            <Typography color={isHidden ? "#7b7b7b" : "#1f1f1f"} style={{marginLeft: 10}}>{label}</Typography>
        </div>
    );
};

const Dot = ({color, borderColor, isHidden}) => {
    return (<div style={{
        borderRadius: "50%",
        flexShrink: 0,
        ...(isHidden ?
            {backgroundColor: "transparent", border: `2px solid ${color}`, width: 10, height: 10} : (borderColor ?
                {backgroundColor: color, border: `2px solid ${borderColor}`, width: 10, height: 10} :
                {backgroundColor: color, width: 14, height: 14})
        )
    }}/>);
};

export default LegendItem;
