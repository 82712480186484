import React, {useMemo} from 'react';
import MultipleSelector from "../../../../common/multipleSelector/MultipleSelector";
import {getAvailableSensors} from "../../../../../utils/stationUtil";

const Step3 = ({selectedStations,initialSelection,callback}) => {

    const availableSensorsOptions = useMemo(() => getAvailableSensors(selectedStations).map(item => (
        {id: item.id, label: item.alias}
    )), [selectedStations]);

    return (
        <MultipleSelector initialSelected={initialSelection}
                          items={availableSensorsOptions}
                          selectedCallback={callback}
                          min={1} />
    );
};

export default Step3;
