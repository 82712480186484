import moment from "moment-timezone";
import {getLocale, getTzUtcOffset} from "./dateUtil";
import {timeZoneOptions} from "../constants";

export const getFormattedDate = (date, timeZone, format = undefined) => {
    const momentDate = moment(date).tz(timeZone);
    const locale = getLocale();
    return momentDate.locale(locale).format(format ?? "L");
};

export const getFormattedTime = (date, timeZone, includeSeconds = true) => {
    const localTime = moment(date).tz(timeZone);
    return localTime.format(includeSeconds ? 'HH:mm:ss' : 'HH:mm');
};

export const getFormattedUtcDate = (date)=>{
    return getFormattedDate(date, "UTC");
};

export const getDataTableFormattedTime = (monthNames, date, timeZone) => {
    if(!timeZone)
        timeZone = moment.tz.guess();
    const localTime = moment(date).tz(timeZone);
    return `${monthNames[localTime.get('month')]}, ${localTime.get('date')}, ${localTime.get('year')}, ${getFormattedTime(date, timeZone, false)}`;
};

export const getFormattedTimeZone = (date, timeZone, timeZoneConfig) => {
    if (timeZoneConfig === timeZoneOptions.StationLocalTime) {
        const utcOffset = getTzUtcOffset(timeZone, date);
        return utcOffset === 0 ? "UTC" :
            `UTC${utcOffset > 0 ? "+" : "-"}${Math.abs(utcOffset) / 60}`;
    }
    return `UTC`;
};

export const getFormattedDateTime = (date, {timeZone = "UTC", includeTimeZone = false, timeZoneConfig, includeSeconds} = {})=> {
    const formattedDateTime = `${getFormattedDate(date, timeZone)} ${getFormattedTime(date, timeZone, includeSeconds)}`;
    if (!date) return "";
    if (includeTimeZone) {
        const utcString = timeZoneConfig === timeZoneOptions.StationLocalTime
            ? getFormattedTimeZone(date, timeZone, timeZoneConfig)
            : "UTC";
        return `${formattedDateTime} (${utcString})`;
    }
    return formattedDateTime;
};

export const getFormattedDateTimeWithNavigatorTimeZone = date => {
    const timeZone = moment.tz.guess();
    const timeZoneConfig = timeZoneOptions.StationLocalTime;
    return getFormattedDateTime(date, {timeZone, includeTimeZone: true, timeZoneConfig});
};

/**
 * display duration in hours, minutes and seconds
 * @param ms
 */
export const msToHrMinSec = (ms)=> {
    if(!ms) return "";
    let seconds = ms / 1000;
    const hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
};
