import React, {useCallback, useState} from 'react';
import {keepAliveRequest} from "../../../../requests/auth/authRequests";
import useMessage from "../../../../hooks/useMessage";
import useRequest from "../../../../hooks/useRequest";
import {useTranslation} from "react-i18next";
import {Button, CircularProgress, Typography,} from "@mui/material";


const style = {
    display: "flex",
    width: "100vw",
    height: "100vh",
    backgroundColor: "#d2d2d2",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2000
};

const dialogSimulator = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    border: "1px solid #ccc",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    zIndex: "2001",
    maxWidth: "500px",
};

const buttonContainer = {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px"
};

const buttonStyle = {
    backgroundColor: "grey",
    color: "white"
};

const KeepAlive = ({onCloseKeepAlive}) => {

    const {setError} = useMessage();
    const [loading, setLoading] = useState(false);
    const {handleErrorResponse} = useRequest();
    const {t} = useTranslation();

    const onKeepAlive = useCallback(async () => {
        try {
            setLoading(true);
            const result = await keepAliveRequest();
            setLoading(false);
            onCloseKeepAlive(result.data);
        } catch (err) {
            setLoading(false);
            handleErrorResponse(err?.response, response => {
                setError(response,
                    false,
                    `${t("error")} ${response.status},
                            ${t("loginScreen.keepAlive.error")}`);
            });
            onCloseKeepAlive();
        }
    }, [handleErrorResponse, onCloseKeepAlive, setError, t]);


    return (
        <div style={style}>
            <div style={dialogSimulator}>
                <Typography style={{marginBottom:32}} variant="h5" id="alert-dialog-title">
                    {t("loginScreen.keepAlive.title")}
                </Typography>
                <Typography style={{marginBottom:32}}  variant="body1" id="alert-dialog-description">
                    {t("loginScreen.keepAlive.message")}
                </Typography>
                <div style={buttonContainer}>
                    <Button
                        disabled={loading}
                         style={buttonStyle} onClick={_ => onCloseKeepAlive()}>
                        {t("no")}
                    </Button>
                    <Button
                        disabled={loading}
                        variant="contained" onClick={onKeepAlive} autoFocus>
                        {t("yes")}
                    </Button>
                </div>
                {loading &&
                    <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                        <CircularProgress/>
                    </div>
                }
            </div>
        </div>
    );
};

export default KeepAlive;
