import {useCallback, useEffect, useMemo, useState} from "react";
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormControlLabel, FormLabel,
    Grid, ToggleButton, ToggleButtonGroup
} from "@mui/material";
import {pollutantNames} from "../../pollutantNames";
import IconDns from "@mui/icons-material/Dns";
import IconWbSunny from "@mui/icons-material/WbSunny";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {meteoDataSource, sensor, temperatureHumidityDataSourceMap} from "../../../../../constants";

const useStyle = makeStyles({
    toggleButtonFormControl: {
        width: "100%"
    },
    toggleButton: {
        width: "50%"
    },
    toggleButtonText: {
        display: "inline-block",
        marginLeft: 20,
        marginRight: 20
    }
});

const SensorDataConfigDialog = ({open, onClose, legendItems, activeItems, updateActiveItems, hasMultipleMeteoData,
                                    meteoDataOrigin, updateMeteoDataOrigin}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedMeteoDataOrigin, setSelectedMeteoDataOrigin] = useState();
    const {t} = useTranslation();
    const classes = useStyle();

    useEffect(() => {
        if (open) {
            setSelectedItems(activeItems);
            setSelectedMeteoDataOrigin(meteoDataOrigin);
        }
    }, [activeItems, meteoDataOrigin, open]);

    const setVariablesSelected = useCallback((variableValues) => {
        const newSelectedItems = [...selectedItems];
        Object.keys(variableValues).forEach((variable) => {
            if (variableValues[variable]) {
                if (!newSelectedItems.includes(variable)) {
                    newSelectedItems.push(variable);
                }
            } else if (newSelectedItems.includes(variable)) {
                newSelectedItems.splice(newSelectedItems.indexOf(variable), 1);
            }
        });
        setSelectedItems(newSelectedItems);
    }, [selectedItems]);

    const handleCheckboxChange = (event) => {
        const {name} = event.target;
        setVariablesSelected({[name]: !selectedItems.includes(name)});
    };

    const handleMeteoDataOriginChange = useCallback((event, newOrigin) => {
        if (newOrigin !== null) {
            setSelectedMeteoDataOrigin(newOrigin);

            if (newOrigin === meteoDataSource.internal) {
                const temperatureValue = selectedItems.includes(sensor.temperature_meteo);
                const humidityValue = selectedItems.includes(sensor.rh_meteo);
                const variableValues = {
                    [sensor.temperature]: temperatureValue,
                    [sensor.rh]: humidityValue,
                    [sensor.temperature_meteo]: false,
                    [sensor.rh_meteo]: false
                };
                setVariablesSelected(variableValues);
            }
            if (newOrigin === meteoDataSource.external) {
                const temperatureValue = selectedItems.includes(sensor.temperature);
                const humidityValue = selectedItems.includes(sensor.rh);
                const variableValues = {
                    [sensor.temperature]: false,
                    [sensor.rh]: false,
                    [sensor.temperature_meteo]: temperatureValue,
                    [sensor.rh_meteo]: humidityValue
                };
                setVariablesSelected(variableValues);
            }
        }
    }, [selectedItems, setVariablesSelected]);

    const handleUpdate = () => {
        updateActiveItems(selectedItems);
        updateMeteoDataOrigin(selectedMeteoDataOrigin);
        onClose();
    };

    const variables = useMemo(() => legendItems.filter(({id}) => {
        if (selectedMeteoDataOrigin === meteoDataSource.external) {
            return !temperatureHumidityDataSourceMap.internal.includes(id);
        }
        return !temperatureHumidityDataSourceMap.external.includes(id);
    }), [legendItems, selectedMeteoDataOrigin]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t("analyticScreen.sensorData.configure_chart")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DialogContentText>
                            {t("downloadScreen.detailPopUp.variables")}
                        </DialogContentText>
                    </Grid>
                    <Grid item container xs={12}>
                        {variables.map((item) => (
                            <Grid item xs={4} key={item.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedItems.includes(item.id)}
                                            onChange={handleCheckboxChange}
                                            name={item.id}
                                        />
                                    }
                                    label={pollutantNames.get(item.id)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {hasMultipleMeteoData && (
                        <Grid item xs={12}>
                            <FormControl className={classes.toggleButtonFormControl}>
                                <FormLabel component="legend" style={{marginBottom: 10}}>
                                    {t("analyticScreen.sensorData.temperature_and_humidity_data_source")}
                                </FormLabel>
                                <ToggleButtonGroup color="primary"
                                                   value={selectedMeteoDataOrigin}
                                                   onChange={handleMeteoDataOriginChange}
                                                   exclusive>
                                    <ToggleButton value={meteoDataSource.internal} className={classes.toggleButton}>
                                        <span className={classes.toggleButtonText}>
                                            {t("analyticScreen.sensorData.internal_sensors")}
                                        </span>
                                        <IconDns/>
                                    </ToggleButton>
                                    <ToggleButton value={meteoDataSource.external} className={classes.toggleButton}>
                                        <IconWbSunny/>
                                        <span className={classes.toggleButtonText}>
                                            {t("analyticScreen.sensorData.meteo_sensors")}
                                        </span>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t("cancel")}</Button>
                <Button onClick={handleUpdate}>{t("update")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SensorDataConfigDialog;