import {useCallback, useMemo, useState} from "react";
import {styled} from "@mui/styles";
import {FormControlLabel, Typography} from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import VisibilityCheckbox from "./VisibilityCheckbox";
import {useMapContext} from "../MapContext";
import StationDataPanel from "./StationDataPanel";
import BaseMapPanel from "./BaseMapPanel";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const STATION_DATA_PANEL = "station-data";
const BASE_MAP_PANEL = "base-map";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreSharpIcon sx={{ fontSize: '1.6rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    '& .MuiAccordionSummary-content': {
        margin: 0
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const MainPanel = ({handleNoPositionStationsClick}) => {
    const {t} = useTranslation();
    const {pollutantRosesLayerActive, mapType} = useMapContext();
    const [expanded, setExpanded] = useState(false);
    const index = useSelector(state => state.auth.units.index);

    const handleExpandedChange = useCallback((panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }, []);

    const panelLayers = useMemo(() => [
        {
            id: STATION_DATA_PANEL,
            label: expanded === STATION_DATA_PANEL ? t(`panel.station_data`) : (
                <>
                    {t(`alarmScreen.stations`)} {
                    (
                        <span style={{fontSize: 13, color: "gray"}}>
                            -{" "}
                            {pollutantRosesLayerActive ? (
                                t(`panel.station_data_layer.pollutant_roses`)
                            ) : (
                                t(`panel.station_data_layer.index_data`, {index})
                            )}
                        </span>
                    )
                }
                </>
            ),
            hideable: false,
            expandable: true,
            expandContent: <StationDataPanel handleNoPositionStationsClick={handleNoPositionStationsClick} />
        },
        {
            id: BASE_MAP_PANEL,
            label: expanded === BASE_MAP_PANEL ? t(`panel.base_map`) : (
                <>
                    {t(`panel.base`)} {
                    (
                        <span style={{fontSize: 13, color: "gray"}}>- {t(`panel.mapType.${mapType}`)}</span>
                    )
                }
                </>
            ),
            hideable: false,
            expandable: true,
            expandContent: <BaseMapPanel />
        }
    ], [expanded, t, pollutantRosesLayerActive, index, handleNoPositionStationsClick, mapType]);

    return (
        <div style={{
            position: "absolute",
            top: 73,
            width: 300,
            right: 55,
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            backdropFilter: "blur(10px)",
            borderRadius: 8,
            overflow: "visible",
            boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
            zIndex: 2
        }}>
            {
                panelLayers.map(({id, label, hideable, visible, onChange, expandable, expandContent}) => (
                    <Accordion
                        key={id}
                        expanded={(!hideable || visible) && expanded === id}
                        onChange={expandable && (!hideable || visible) ? handleExpandedChange(id) : null}
                        style={{backgroundColor: "transparent"}}
                    >
                        <AccordionSummary
                            id={`${id}-header`}
                            aria-controls={`${id}-content`}
                            expandIcon={expandable && (!hideable || visible) ? <ExpandMoreSharpIcon /> : null}
                            style={{paddingRight: 12}}
                        >
                            {
                                hideable ? (
                                    <FormControlLabel control={
                                        <VisibilityCheckbox checked={visible} onChange={onChange} />
                                    } label={label} onClick={(event) => {
                                        event.stopPropagation();
                                    }} />
                                ) : (
                                    <Typography>
                                        {label}
                                    </Typography>
                                )
                            }
                        </AccordionSummary>
                        {
                            expandContent && (
                                <AccordionDetails style={{paddingTop: 8, paddingBottom: 8, backgroundColor: "white"}}>
                                    {expandContent}
                                </AccordionDetails>
                            )
                        }
                    </Accordion>
                ))
            }
        </div>
    );
};

export default MainPanel;
