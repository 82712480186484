import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import clsx from "clsx";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {pollutantNames} from "../../pollutantNames";
import {useTranslation} from "react-i18next";
import {unitsMap} from "../../unitsNames";
import useDimensions from "../../../../../hooks/useDimensions";
import TableHeaderTitle from "../../analytics/rd_threshold_card/TableHeaderTitle";
import {rdConstants, sensor} from "../../../../../constants";
import {useSelector} from "react-redux";

const pollutantSorting = [sensor.no2, sensor.pm10, sensor.pm2p5, sensor.so2, sensor.co, sensor.o3, sensor.hcl, sensor.voc];
const pollutantKeySorting = Object.values(rdConstants);

const useStyles = makeStyles((_) => {
    return {
        container: {
            maxHeight: 450,
            position: "relative",
        },
        tableZbe: {
            marginTop: 50
        },
        zbeCell: {
            backgroundColor: "rgba(0, 187, 201, 0.2)",
            border: "0 solid #00BBC9"
        },
        zbeLeftCell: {
            borderLeftWidth: 1
        },
        zbeRightCell: {
            borderRightWidth: 1
        },
        zbeTopCell: {
            borderTopWidth: 1,
            borderBottom: "1px solid rgba(224, 224, 224, 1)"
        },
        zbeMiddleCell: {
            borderBottom: "1px solid rgba(224, 224, 224, 1)"
        },
        zbeBottomCell: {
            borderBottomWidth: 1
        },
        zbeTopRightCell: {
            borderTopRightRadius: 8
        },
        zbeBottomLeftCell: {
            borderBottomLeftRadius: 8
        },
        zbeBottomRightCell: {
            borderBottomRightRadius: 8
        },
        zbeTag: {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 50,
            backgroundColor: "#00BBC9",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8
        },
        zbeTagText: {
            color: "#FFFFFF",
            fontSize: 21,
            fontWeight: 600
        }
    };
});

const RdThresholdTable = ({rdData}) => {

    const {t} = useTranslation();
    const classes = useStyles();

    const legalThreshold = useSelector(state => state.auth.legalThreshold);

    const thresholdDataPollutants = useMemo(() => {
        const newThresholdDataPollutants = {};
        rdData.forEach((stationRdData) => {
            const thresholdData = {...stationRdData.lowEmissionZones, ...stationRdData.notRestrictedZones};
            const lowEmissionZoneKeys = Object.keys(stationRdData.lowEmissionZones);

            Object.keys(thresholdData).forEach(key => {
                const isLowEmissionZone = lowEmissionZoneKeys.includes(key);
                const pollutantKeys = {};
                Object.keys(thresholdData[key]).forEach(pollutantKey => {
                    const pollutantData = thresholdData[key][pollutantKey];
                    const {units} = pollutantData;
                    pollutantKeys[pollutantKey] = {
                        threshold: pollutantKey === rdConstants.vla ? pollutantData.concentrationThreshold : pollutantData.countThreshold,
                        units
                    };
                });
                newThresholdDataPollutants[key] = {
                    isLowEmissionZone,
                    keys: pollutantKeys
                };
            });
        });
        return newThresholdDataPollutants;
    }, [rdData]);

    const thresholdDataKeys = useMemo(() => {
        const newThresholdDataKeys = [];
        Object.keys(thresholdDataPollutants).forEach(key => {
            const {isLowEmissionZone, keys} = thresholdDataPollutants[key];
            Object.keys(keys).forEach(pollutantKey => {
                newThresholdDataKeys.push({
                    pollutant: key,
                    param: pollutantKey,
                    key: `${key}-${pollutantKey}`,
                    isLowEmissionZone,
                    ...keys[pollutantKey]
                });
            });
        });
        newThresholdDataKeys.sort((a, b) => {
            let sort = !!b.isLowEmissionZone - !!a.isLowEmissionZone;
            if (sort === 0) {
                sort = pollutantSorting.indexOf(a.pollutant) - pollutantSorting.indexOf(b.pollutant);
                if (sort === 0) {
                    sort = pollutantKeySorting.indexOf(a.param) - pollutantKeySorting.indexOf(b.param);
                }
            }
            return sort;
        });
        let firstLowEmissionZoneIndex = -1;
        let lastLowEmissionZoneIndex = -1;
        newThresholdDataKeys.forEach((item, index) => {
            if (item.isLowEmissionZone) {
                if (firstLowEmissionZoneIndex === -1) {
                    firstLowEmissionZoneIndex = index;
                }
                lastLowEmissionZoneIndex = index;
            }
        });
        if (firstLowEmissionZoneIndex !== -1) {
            newThresholdDataKeys[firstLowEmissionZoneIndex].isFirstLowEmissionZone = true;
            newThresholdDataKeys[lastLowEmissionZoneIndex].isLastLowEmissionZone = true;
        }
        return newThresholdDataKeys;
    }, [thresholdDataPollutants]);

    const thresholdValues = useMemo(() => rdData.map(stationRdData => {
        const thresholdData = {...stationRdData.lowEmissionZones, ...stationRdData.notRestrictedZones};
        const thresholdValues = {
            station: stationRdData.station
        };

        Object.keys(thresholdData).forEach(key => {
            Object.keys(thresholdData[key]).forEach(pollutantKey => {
                const pollutantData = thresholdData[key][pollutantKey];
                const itemKey = `${key}-${pollutantKey}`;
                const {units} = pollutantData;
                thresholdValues[itemKey] = {
                    value: pollutantKey === rdConstants.vla ? pollutantData.concentration : pollutantData.count,
                    units
                };
            });
        });

        return thresholdValues;
    }), [rdData]);

    const lowEmissionZoneKeys = useMemo(() => (
        thresholdDataKeys.filter(item => item.isLowEmissionZone).map(item => item.key)
    ), [thresholdDataKeys]);

    const tableClassName = lowEmissionZoneKeys.length > 0 ? classes.tableZbe : "";

    const {dimensionRef, width} = useDimensions();
    const [verticalTagStyle, setVerticalTagStyle] = useState({});
    const tableRef = useRef(null);

    const getLowEmissionZoneHeaderCellClassName = useCallback((column) => {
        const {isLowEmissionZone, isFirstLowEmissionZone, isLastLowEmissionZone} = column;

        return clsx(
            isLowEmissionZone && classes.zbeCell,
            isLowEmissionZone && classes.zbeMiddleCell,
            isFirstLowEmissionZone && classes.zbeLeftCell,
            isLastLowEmissionZone && classes.zbeRightCell
        );
    }, [classes]);

    const getLowEmissionZoneCellClassName = useCallback((rowIndex, column) => {
        const isLastRow = rowIndex === thresholdValues.length - 1;
        const isMiddleRow = !isLastRow;
        const {isLowEmissionZone, isFirstLowEmissionZone, isLastLowEmissionZone} = column;

        return clsx(
            isLowEmissionZone && classes.zbeCell,
            isFirstLowEmissionZone && classes.zbeLeftCell,
            isLastLowEmissionZone && classes.zbeRightCell,
            isMiddleRow && classes.zbeMiddleCell,
            isLastRow && classes.zbeBottomCell,
            isFirstLowEmissionZone && isLastRow && classes.zbeBottomLeftCell,
            isLastLowEmissionZone && isLastRow && classes.zbeBottomRightCell
        );
    }, [classes, thresholdValues]);

    useEffect(() => {
        if (tableRef.current) {
            const rows = tableRef.current.querySelectorAll("td");
            let firstZbeRowRect;
            let lastZbeRowRect;
            rows.forEach(row => {
                if (row.classList.contains(classes.zbeLeftCell)) {
                    firstZbeRowRect = row.getBoundingClientRect();
                }
                if (row.classList.contains(classes.zbeRightCell)) {
                    lastZbeRowRect = row.getBoundingClientRect();
                }
            });
            if (firstZbeRowRect && lastZbeRowRect) {
                // Calculate relative X position and width of ZBE rows
                const firstZbeRowRelativeX = firstZbeRowRect.left - tableRef.current.getBoundingClientRect().left;
                const zbeRowsWidth = lastZbeRowRect.right - firstZbeRowRect.left;
                setVerticalTagStyle({
                    left: firstZbeRowRelativeX,
                    width: zbeRowsWidth
                });
            }
        }
    }, [classes, width]);

    const tableRows = useMemo(() => (
        thresholdValues.map((row, rowIndex) => (
            <TableRow key={row.station.id}>
                <TableCell align="left">
                    {row.station.alias}
                </TableCell>
                {thresholdDataKeys.map((column) => {
                    const {threshold} = column;
                    const thresholdItemData = row[column.key];
                    const {value} = thresholdItemData;
                    return (
                        <TableCell key={column.key} align="right"
                                   className={getLowEmissionZoneCellClassName(rowIndex, column)}>
                            {(value !== null && value !== undefined) && (
                                <>
                                    <Box fontWeight="fontWeightBold" display="inline" style={{
                                        color: value > threshold ? "#d30000" : "inherit"
                                    }}>
                                        {value}
                                    </Box>
                                    /{threshold}
                                </>
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        ))
    ), [thresholdValues, thresholdDataKeys, getLowEmissionZoneCellClassName]);

    return (
        <TableContainer className={classes.container} ref={tableRef}>
            <Tooltip title={t(`analyticScreen.rdThreshold.legalThresholdsTooltip.${legalThreshold.toLowerCase()}`)}
                     arrow PopperProps={{
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}>
                <div className={classes.zbeTag} style={verticalTagStyle}>
                    <Typography className={classes.zbeTagText}>
                        {t(`analyticScreen.rdThreshold.legalThresholds.${legalThreshold.toLowerCase()}`)}
                    </Typography>
                </div>
            </Tooltip>
            <Table stickyHeader aria-label="event table" className={tableClassName} ref={dimensionRef}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            {t("alarmScreen.station")}
                        </TableCell>
                        {thresholdDataKeys.map(item => {
                            const helperText = item.param === rdConstants.mdmm
                                ? t("analyticScreen.rdThreshold.vlHelp")
                                : t(`analyticScreen.rdThreshold.${item.param}Help`);
                            const param = item.param === rdConstants.mdmm
                                ? t("analyticScreen.rdThreshold.vl")
                                : t(`analyticScreen.rdThreshold.${item.param}`);
                            return (
                                <TableCell key={item.key} align="right"
                                           className={getLowEmissionZoneHeaderCellClassName(item)}>
                                    {pollutantNames.get(item.pollutant)}{' '}
                                    <TableHeaderTitle helperText={helperText}>
                                        {param}
                                    </TableHeaderTitle>
                                    <br/>
                                    ({item.threshold}
                                    {item.param === rdConstants.vla && ` ${unitsMap.get(item.units)}`}
                                    )
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RdThresholdTable;
