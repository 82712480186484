import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useMapContext} from "../MapContext";
import {pollutantRoseRequest} from "../../../../../requests/analytics/pollutantRoseRequest";
import {sensor, stationState} from "../../../../../constants";
import useMessage from "../../../../../hooks/useMessage";
import useRequest from "../../../../../hooks/useRequest";
import {shallowEqual, useSelector} from "react-redux";
import {isEqual} from "lodash";
import ReactDOM from "react-dom";
import PollutantRoseChart, {
    ROSE_CHART_MAX_ZOOM,
    ROSE_CHART_MIN_ZOOM
} from "../../analytics/pollutant_rose/chart/PollutantRoseChart";
import mapBoxGL from "mapbox-gl";
import {bboxPolygon, booleanPointInPolygon} from "@turf/turf";
import processPollutantRoseData from "../../../../../utils/processPollutantRoseData";
import {getFinishOfDayInUTC, getInitOfDayInUTC} from "../../../../../utils/dateUtil";
import {dateToUTCServerFormatString} from "../../../../../utils/dateToUtcStringConverter";
import {forEachOf} from "async";

const getRoundedZoom = (zoom) => {
    return Math.round(zoom * 2) / 2;
};

const getRoundedZoomWithLimits = (zoom) => {
    const roundedZoom = getRoundedZoom(zoom);
    if (roundedZoom > ROSE_CHART_MAX_ZOOM) return ROSE_CHART_MAX_ZOOM;
    return roundedZoom;
};

const usePollutantRoses = (onPollutantRoseClick) => {
    const {
        currentMap,
        selectedPollutant,
        pollutantRosesLayerActive,
        roseLegendData,
        setRoseLegendData
    } = useMapContext();
    const {setError} = useMessage();
    const [loading, setLoading] = useState(false);
    const {units} = useSelector(state => state.auth);
    const {handleErrorResponse} = useRequest();
    const stations = useSelector(state => state.dashboardUI.stations, isEqual);
    const [pollutantRoses, setPollutantRoses] = useState([]);
    const [rosesLayerStations, setRosesLayerStations] = useState([]);

    const zoomRef = useRef(0);
    const selectedPollutantRef = useRef(null);
    const onScreenStationsRef = useRef(null);

    const [redrawIfRequireIndex, setRedrawIfRequireIndex] = useState(0);

    const cachedData = useRef({});

    const visiblePollutantRoses = useMemo(() => (
        pollutantRosesLayerActive ? (
            stations.filter(({sensors, state, position}) => (
                sensors?.includes(sensor.wind) &&
                sensors.includes(selectedPollutant) &&
                state === stationState.active &&
                position !== undefined
            ))
        ) : []
    ), [stations, pollutantRosesLayerActive, selectedPollutant]);


    useEffect(() => {
        redrawPollutantRosesIfRequired();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redrawIfRequireIndex]);

    const getStationsInBounds = useCallback(() => {
        if (currentMap) {
            const bounds = currentMap.getBounds();
            const bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
            const bPolygon = bboxPolygon(bbox);
            return visiblePollutantRoses.filter(station => {
                return booleanPointInPolygon([station.position.long, station.position.lat], bPolygon);
            });
        }
        return [];
    }, [currentMap, visiblePollutantRoses]);

    const redrawPollutantRoses = useCallback(() => {

        const onScreenStations = getStationsInBounds();

        const pollutantRosesData = onScreenStations.map(station => {
            const roseData = cachedData.current[selectedPollutant]?.[station.id];
            return roseData ? {station, roseData} : undefined;
        });

        pollutantRoses.forEach(value => {
            value?.remove();
        });

        const newRosesLayerStations = [];
        const newPollutantRoses = pollutantRosesData.map(stationRoseData => {
            if (stationRoseData === undefined) return undefined;
            const {station, roseData} = stationRoseData;

            if (currentMap !== null && currentMap !== undefined) {
                const container = document.createElement('div');
                container.style.borderRadius = "50%";
                container.style.pointerEvents = "none";
                container.onclick = () => onPollutantRoseClick?.(station.id, station.position);
                if (zoomRef.current >= ROSE_CHART_MIN_ZOOM) {
                    ReactDOM.render(
                        <PollutantRoseChart data={roseData} zoom={zoomRef.current}/>,
                        container
                    );
                    newRosesLayerStations.push(station);
                }
                const marker = new mapBoxGL.Marker(container);
                marker.setLngLat({lng: station.position.long, lat: station.position.lat});
                marker.addTo(currentMap);
                return marker;
            }
        });
        setPollutantRoses(newPollutantRoses);
        setRosesLayerStations(newRosesLayerStations);

    }, [getStationsInBounds, pollutantRoses, selectedPollutant, currentMap, onPollutantRoseClick]);


    const redrawPollutantRosesIfRequired = useCallback(() => {

        if (loading || cachedData.current[selectedPollutant] === undefined) return;

        let redraw = true;

        if (selectedPollutantRef.current !== selectedPollutant) selectedPollutantRef.current = selectedPollutant;
        else {
            const currentZoom = currentMap.getZoom();
            const roundedZoom = getRoundedZoomWithLimits(currentZoom);
            if (zoomRef.current !== roundedZoom) zoomRef.current = roundedZoom;
            else {
                const stationsInBounds = getStationsInBounds();
                if (shallowEqual(onScreenStationsRef.current, stationsInBounds)) redraw = false;
                else onScreenStationsRef.current = stationsInBounds;
            }
        }

        if (onScreenStationsRef.current !== null && redraw) redrawPollutantRoses();

    }, [loading, selectedPollutant, currentMap, getStationsInBounds, redrawPollutantRoses]);


    useEffect(() => {

        if (currentMap) {
            zoomRef.current = currentMap.getZoom();
            onScreenStationsRef.current = getStationsInBounds();

            currentMap.on("moveend", () => setRedrawIfRequireIndex(prev => prev + 1));
            setRedrawIfRequireIndex(prev => prev + 1);
            return () => {
                currentMap.off("moveend", () => setRedrawIfRequireIndex(prev => prev + 1));
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMap]);


    useEffect(() => {

        const downloadRoseData = async () => {

            if (cachedData.current[selectedPollutant] === undefined && visiblePollutantRoses.length > 0) {

                const updateCache = (selectedStation, data) => {

                    const copy = {...cachedData.current};
                    if (!copy[selectedPollutant]) copy[selectedPollutant] = {};
                    if (!copy[selectedPollutant][selectedStation]) copy[selectedPollutant][selectedStation] = data;
                    cachedData.current = copy;
                };

                const stationIds = visiblePollutantRoses.map(item => item.id);

                let finishTime = new Date();
                finishTime = getFinishOfDayInUTC(finishTime);
                finishTime = dateToUTCServerFormatString(finishTime);

                let initTime = new Date();
                initTime.setDate(initTime.getDate() - 7);
                initTime = getInitOfDayInUTC(initTime);
                initTime = dateToUTCServerFormatString(initTime);

                setLoading(true);
                let errorDownloading = false;
                let configuredLegendData = false;
                await forEachOf(stationIds, (stationId, key, cb) => {

                    pollutantRoseRequest(units.temperature, units.pollutants, stationId,
                        selectedPollutant, initTime, finishTime).then(result => {
                        const processedData = processPollutantRoseData(result);
                        if (!configuredLegendData) {
                            configuredLegendData = true;
                            setRoseLegendData(processedData);
                        }
                        updateCache(stationId, processedData);
                        cb(null);
                    }).catch(error => {
                        updateCache(stationId, null);
                        handleErrorResponse(error, _ => {
                            if (error.response?.status !== 404)
                                errorDownloading = true;
                            cb(null);
                        });
                    });
                }, err => {
                    setLoading(false);
                    if (errorDownloading)
                        setError(err, false,
                            "analyticScreen.sensorData.could_not_update_station_data");
                    setRedrawIfRequireIndex(prev => prev + 1);
                });
            } else if (visiblePollutantRoses.length > 0){
                const data = Object.entries(cachedData.current[selectedPollutant]).find(([_, value]) => value !== null);
                if(data)
                    setRoseLegendData(data[1]);
                setRedrawIfRequireIndex(prev => prev + 1);
            }
        };
        downloadRoseData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visiblePollutantRoses, units.temperature, units.pollutants]);


    return {loading, roseLegendData, rosesLayerStations};
};

export default usePollutantRoses;
