import React, {useEffect} from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import NodeDetail from "../NodeDetail";

const NoLocationDialog = ({handleClose, dataTimeZone, index, device, deviceSelectedCallback, offlineHiddenClue }) => {

    const {t} = useTranslation();

    useEffect(() => {
        console.log("offlineHiddenClue", offlineHiddenClue);
    }, [offlineHiddenClue]);

    return (
        <Dialog
            open={true}
            onClose={handleClose}
        >
            <DialogTitle id="common-dialog-tittle">{ offlineHiddenClue ? t("home.offLineStationsHidden") : t("home.unknownLocation")}</DialogTitle>
            <DialogContent>
                <NodeDetail dataTimeZone={dataTimeZone} index={index} deviceSelectedCallback={deviceSelectedCallback}
                            device={device}/>
            </DialogContent>
        </Dialog>
    );
};

export default NoLocationDialog;
