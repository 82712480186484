import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Button,
    Card,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select, Switch,
    TextField,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import validator from "validator";
import ChangePassword from "./ChangePassword";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getLastLoggedIn, updateProfileRequest} from "../../../../../requests/profile/profileRequest";
import {updateProfileAction} from "../../../../../reducers/authReducer";
import {appModules, indexTypes, legalThresholdsOptions, timeZoneOptions} from "../../../../../constants";
import useMessage from "../../../../../hooks/useMessage";
import {useTranslation} from "react-i18next";
import SaveButton from "../../../../common/buttons/SaveButton";
import {getDateFnsLocale} from "../../../../../utils/dateUtil";
import {handleSentryError} from "../../../../../handleSentryError";
import useRequest from "../../../../../hooks/useRequest";
import useModules from "../../../../../hooks/useModules";


const useStyles = makeStyles({
    saveButton: {
        color: '#ffffff'
    },
    changePasswordButton: {},
    form: {
        width: "100%",
    },
    email: {
        color: "#8a8a8a"
    },
    emailTittle: {
        fontWeight: "bold"
    },

    grip: {
        marginTop: 12,
    },
    rowMargin: {},
    lastSeenText: {
        alignItems: "center"
    }
});


const UserDataCardView = ({className}) => {

    const {t} = useTranslation();
    const {setError, setSuccess} = useMessage();
    const {handleErrorResponse} = useRequest();
    const dispatch = useDispatch();
    const classes = useStyles();
    const {name, lastName, email, units, timeZone, legalThreshold, useInvalidFilter} = useSelector(state => state.auth);

    const initialFormValues = {
        profileName: name,
        profileLastName: lastName,
        profileUnits: units,
        profileLegalThreshold: legalThreshold,
        profileTimeZone: timeZone,
        profileUseInvalidFilter: useInvalidFilter,
        validForm: validateForm(name, lastName),
        openChangePassword: false,
        loading: false
    };
    const [{
        profileName,
        profileLastName,
        validForm,
        openChangePassword,
        profileUnits,
        profileLegalThreshold,
        profileTimeZone,
        profileUseInvalidFilter,
        loading
    }, updateState] = useState(initialFormValues);
    const [saving, setSaving] = useState(false);
    const [lastLoggedIn, setLastLoggedIn] = useState(null);
    const {hasModule} = useModules();

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getLastLoggedIn();
                if (data) {
                    setLastLoggedIn(data.lastSuccessfulLog);
                }
            } catch (e) {
                handleSentryError(`Error in getLastLoggedIn request: ${e}`);
            }
        }

        fetchData();
    }, []);

    const closeDialog = useCallback(() => {
        updateState(state => {
            return {...state, openChangePassword: false};
        });
    }, [updateState]);

    const handleName = (event) => {
        const value = event.target.value;
        const isFormValid = validateForm(value, null);
        updateState(state => {
            return {...state, profileName: value, validForm: isFormValid};
        });
    };

    const handleLastName = (event) => {
        const value = event.target.value;
        const isFormValid = validateForm(null, value);
        updateState(state => {
            return {...state, profileLastName: value, validForm: isFormValid};
        });
    };

    const handleSave = () => {
        updateState(state => ({...state, loading: true, error: ""}));
        setSaving(true);
        updateProfileRequest({
            name: profileName,
            lastName: profileLastName,
            units: profileUnits,
            timeZone: profileTimeZone,
            legalThreshold: profileLegalThreshold,
            useInvalidFilter: profileUseInvalidFilter
        }, (err, data) => {
            setSaving(false);
            if (!err) {
                updateState(state => ({...state, loading: false, error: ""}));
                setSuccess("profile.success_updated");
                dispatch(updateProfileAction(profileName, profileLastName, profileUnits, profileTimeZone, profileLegalThreshold, profileUseInvalidFilter));
            } else {
                handleErrorResponse(data, response => {
                    switch (response.status) {
                        case 400:
                            setError("profile.error_updating");
                            break;
                        default:
                            updateState(state => {
                                return {...state, loading: false, error: t("profile.could_not_updated")};
                            });
                            setError(response,
                                false,
                                "profile.could_not_updated");
                    }
                });
            }
        });
    };


    const handleChangePassword = () => {
        updateState(state => {
            return {...state, openChangePassword: true};
        });
    };

    function validateForm(forName, formLastName) {
        let nameField = (forName !== undefined && forName !== null) ? forName : profileName;
        let lastNameField = (formLastName !== undefined && formLastName !== null) ? formLastName : profileLastName;
        return !(validator.isEmpty(nameField) || validator.isEmpty(lastNameField));
    }

    const handleIndexChange = (event) => {
        updateState(state => (
            {...state, profileUnits: {...state.profileUnits, index: event.target.value}}));
    };

    const handleLegalThresholdChange = (event) => {
        updateState(state => (
            {...state, profileLegalThreshold: event.target.value}));
    };

    const handleTimeZoneChange = (event) => {
        updateState(state => (
            {...state, profileTimeZone: event.target.value}));
    };

    const handleTempChange = (event) => {
        updateState(state => (
            {...state, profileUnits: {...state.profileUnits, temperature: event.target.value}}));
    };

    const handleWindSpeedChange = (event) => {
        updateState(state => (
            {...state, profileUnits: {...state.profileUnits, windSpeed: event.target.value}}));
    };

    const handlePollutantsChange = (event) => {
        updateState(state => (
            {...state, profileUnits: {...state.profileUnits, pollutants: event.target.value}}));
    };

    const handleUseInvalidFilterChange = (event) => {
        updateState(state => (
            {...state, profileUseInvalidFilter: event.target.checked}));
    };

    const hasChanges = useMemo(() => {
        return !(name === profileName && lastName === profileLastName && shallowEqual(units, profileUnits)
            && timeZone === profileTimeZone && legalThreshold === profileLegalThreshold && useInvalidFilter === profileUseInvalidFilter);
    }, [name, profileName, lastName, profileLastName, units, profileUnits, timeZone, profileTimeZone, legalThreshold, profileLegalThreshold, useInvalidFilter, profileUseInvalidFilter]);

    return (<>
        <Card className={className}>
            <form noValidate className={classes.form}>
                <Typography className={classes.emailTittle} variant={"h4"}>{t("profile.user_profile")}</Typography>
                <Grid className={classes.grip} container direction={"row"} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            inputProps={{"data-testid": "profile-name"}}
                            disabled={loading}
                            autoFocus label={t("profile.name")} variant="outlined"
                            required fullWidth margin="normal"
                            onChange={handleName} value={profileName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label={t("profile.last_name")} variant="outlined"
                                   inputProps={{"data-testid": "profile-lastname"}}
                                   required
                                   disabled={loading}
                                   fullWidth margin="normal"
                                   onChange={handleLastName}
                                   value={profileLastName}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label={t("profile.email")} variant="outlined"
                                   inputProps={{"data-testid": "profile-email"}}
                                   required
                                   disabled
                                   fullWidth margin="normal"
                                   value={email}/>
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.rowMargin}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel>{t("units.timeZone")}</InputLabel>
                            <Select
                                disabled={loading}
                                onChange={handleTimeZoneChange}
                                label={t("units.timeZone")}
                                value={profileTimeZone}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                data-testid="profile-timezone"
                            >
                                {Object.values(timeZoneOptions).map(item =>
                                    <MenuItem key={item} value={item}>{t(`units.timeZoneOptions.${item}`)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.rowMargin}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel>{t("units.pollutant_index")}</InputLabel>
                            <Select
                                disabled={loading}
                                onChange={handleIndexChange}
                                label={t("units.pollutant_index")}
                                value={profileUnits.index}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                data-testid="profile-pollutant-index"
                            >
                                {Object.values(indexTypes).map(item =>
                                    <MenuItem key={item}
                                              value={item}>{`${item === "AQHI-HK" ? "AQHI": item} - ${t(`legend.region.${item}`)}`}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.rowMargin}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel>{t("organizationLegalThreshold.legal_threshold")}</InputLabel>
                            <Select
                                disabled={loading}
                                label={t("organizationLegalThreshold.legal_threshold")}
                                value={profileLegalThreshold}
                                onChange={handleLegalThresholdChange}
                                data-testid="profile-legal-threshold"
                            >
                                {Object.keys(legalThresholdsOptions).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {t(`analyticScreen.rdThreshold.legalThresholdsName.${key.toLowerCase()}`)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.rowMargin}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel>{t("units.concentration_units")}</InputLabel>
                            <Select
                                disabled={loading}
                                label={t("units.concentration_units")}
                                onChange={handlePollutantsChange}
                                value={profileUnits.pollutants}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                data-testid="profile-concentration"
                            >
                                <MenuItem value={"eu"}>µg/m³ - mg/m³</MenuItem>
                                <MenuItem value={"usa"}>{t("units.ppm_ppb")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.rowMargin}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel>{t("units.temperature_units")}</InputLabel>
                            <Select
                                disabled={loading}
                                label={t("units.temperature_units")}
                                onChange={handleTempChange}
                                value={profileUnits.temperature}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                data-testid="profile-temperature"
                            >
                                <MenuItem value={"celsius"}>Celsius (ºC)</MenuItem>
                                <MenuItem value={"fahrenheit"}>Fahrenheit (ºF)</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel>{t("units.wind_speed_unit")}</InputLabel>
                            <Select
                                disabled={loading}
                                label={t("units.wind_speed_unit")}
                                onChange={handleWindSpeedChange}
                                value={profileUnits.windSpeed}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                data-testid="profile-wind"
                            >
                                <MenuItem value={"mps"}>{t("units.meter_per_second")}</MenuItem>
                                <MenuItem value={"mph"}>{t("units.mile_per_hour")}</MenuItem>
                                <MenuItem value={"knots"}>{t("units.knots")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {hasModule(appModules.dataValidation) &&
                        <Grid item xs={12}>
                            <FormControlLabel control={<Switch defaultChecked/>}
                                              label={t("profile.only_show_validated_data")}
                                              checked={profileUseInvalidFilter}
                                              onChange={handleUseInvalidFilterChange}/>
                        </Grid>
                    }
                    <Grid item container xs={12} className={classes.lastSeenText}>
                        {lastLoggedIn && (
                            <Typography variant={"body1"}>
                                {t("profile.last_login")}: {new Date(lastLoggedIn).toLocaleString(getDateFnsLocale())}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            disabled={loading} className={classes.changePasswordButton} fullWidth color="primary"
                            onClick={handleChangePassword} size="large" variant="outlined">
                            {t("profile.change_password")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SaveButton className={classes.saveButton} data-testid="profile-save-button" fullWidth
                                    disabled={!validForm || loading || !hasChanges} saving={saving}
                                    onSave={handleSave}/>
                    </Grid>
                </Grid>
            </form>
            <ChangePassword open={openChangePassword} closeDialog={closeDialog}/>
        </Card>
    </>);
};

export default UserDataCardView;
