import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {
    DEFAULT_PERIOD,
    meteoDataSource,
    temperatureHumidityDataSourceMap, timeZoneOptions
} from "../../../../../constants";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {unitsMap} from "../../unitsNames";
import {pollutantNames} from "../../pollutantNames";
import {getPollutantColor, getPollutantBackgroundColor} from "bettairplaformutil/src/colorUtil";
import {getPollutantRange} from "../../../../../utils/chartAxeRangeUtil";
import {useTranslation} from "react-i18next";
import {sortBySensorType} from "../../../../../utils/stationUtil";
import ChartLayout from "../../../../common/chart/ChartLayout";
import ChartView from "../../../../common/chart/ChartView";
import {Grid, IconButton, Typography} from "@mui/material";
import IconAdd from "@mui/icons-material/Add";
import SensorDataConfigDialog from "./SensorDataConfigDialog";
import LegendList from "../../../../common/LegendList";
import {initialPlotLayout, initialState, plotConfig, presetVariables} from "./initialConfig";
import {getAxeVisibilityConfig, getSelectedPollutants, getVisibleVariables} from "./utils";
import {isEqual} from "lodash";
import moment from "moment-timezone";

const SensorDataChart = ({height, loading, rawData, dataTimeZone, setDataTimeZone, selectedPeriod, setSelectedPeriod,
                             stationSensors, selectedSensors, setSelectedSensors, updateData, onClick, onHover,
                             onUnHover, onSelect, cursorInterval, config, selectedResolution, setSelectedResolution}) => {

    const {t} = useTranslation();
    const [{
        data, y1Visible, y2Visible, y3Side, y3Visible,
        y4Side, y4Visible, y5Side, y7Side, y8Side, y5Visible, y5Range, y6Visible,
        y7Visible, y8Visible, y1Domain, y2Domain, y3Domain, y4Domain, y5Domain,
        y6Domain, y7Domain, y8Domain
    }, setState] = useState(initialState);
    const isMounted = useMountComponent();
    const {units} = useSelector(state => state.auth);
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [dateRange, setDateRange] = useState(null);
    const [visibleVariables, setVisibleVariables] = useState([]);
    const [meteoDataOrigin, setMeteoDataOrigin] = useState(meteoDataSource.internal);
    const [configDialogOpen, setConfigDialogOpen] = useState(false);
    const selectedStationData = useSelector(state => (
        state.dashboardUI.stations.find(({id}) => state.dashboardUI.selectedStation === id)
    ));
    const timeZone =
        (dataTimeZone === timeZoneOptions.StationLocalTime && selectedStationData?.position?.locationInfo?.timeZone)
        || timeZoneOptions.UTC;

    const updateState = (updateStateFn) => (
        setState(state => {
            const newState = updateStateFn(state);
            return isEqual(state, newState) ? state : newState;
        })
    );

    useEffect(() => {
        if (setSelectedPeriod) {
            setSelectedPeriod(getDateRange(period, dateRange));
        }
    }, [period, dateRange, setSelectedPeriod]);

    useEffect(() => {
        if (updateData) updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTimeZone, selectedPeriod, selectedResolution]);

    const plotLayout = useMemo(() => {
        const locationInfo = selectedStationData.position?.locationInfo;
        return {
            ...initialPlotLayout,
            yaxis4: units.pollutants === "eu" ? {...initialPlotLayout.yaxis4} : {
                title: 'ppb - µg/m³'
            },
            yaxis5: units.pollutants === "eu" ? {...initialPlotLayout.yaxis5} : {
                side: "right",
                overlaying: 'y4',
                title: 'ppm'
            },
            yaxis2: units.temperature === "celsius" ? {...initialPlotLayout.yaxis2} : {
                title: "ºF"
            },
            xaxis: {
                ...initialPlotLayout.xaxis,
                hoverformat: `%-d %b, %Y %H:%M:%S${locationInfo ? '' : ` (${timeZoneOptions.UTC})`}`
            }
        };
    }, [units.pollutants, units.temperature, selectedStationData]);

    useEffect(() => {
        if (data.length === 0) return;
        const newVisibleVariables = getVisibleVariables(data, selectedSensors);
        setVisibleVariables(oldVisibleVariables => {
            if (isEqual(oldVisibleVariables, newVisibleVariables)) return oldVisibleVariables;
            return newVisibleVariables;
        });
    }, [data, selectedSensors]);

    const updateVisibleVariables = useCallback((newVisibleVariables) => {
        const newSelectedSensors = [];
        newVisibleVariables.forEach(item => {
            if (data[item]) newSelectedSensors.push(data[item].id);
        });
        setSelectedSensors(oldSelectedSensors => {
            if (isEqual(oldSelectedSensors, newSelectedSensors)) return oldSelectedSensors;
            return newSelectedSensors;
        });
    }, [data, setSelectedSensors]);

    useEffect(() => {
        if (!rawData?.length && loading === true) {
            updateState(state => {
                return {...state, data: [], y5Range: []};
            });
        }
        if (!isMounted.current) {
            return;
        }
        const dataArray = sortBySensorType(
            Object.entries(rawData ?? []).filter(item => (
                stationSensors
                    ? stationSensors.includes(item[0])
                    : item[0] !== "VOC_IAQ"
            )),
            (item) => item[0]
        );
        const newData = dataArray.map((value, index) => {
            const id = value[0];
            const units = unitsMap.get(value[1].units);
            const y = value[1].y;
            const adaptedData = {...value[1], y};
            const defaultVisible = index === 0 || presetVariables.includes(id);
            const borderColor = getPollutantColor(id);
            const backgroundColor = getPollutantBackgroundColor(id) ?? borderColor;
            return {
                id,
                visible: (visibleVariables.length ? visibleVariables.includes(index) : defaultVisible)
                    ? true : "legendonly",
                name: ` ${pollutantNames.get(id)} - ${units}`,
                ...adaptedData,
                type: ["mm/h"].includes(value[1].units) ? "bar" : "scatter",
                hoverlabel: {namelength: 0},
                hovertemplate: `<b>${pollutantNames.get(id)}</b>: %{y} ${units}`,
                ...(!["W/m²"].includes(value[1].units) ? {
                    marker: {color: borderColor}
                } : {
                    mode: "lines",
                    line: {color: borderColor},
                    fill: "tozeroy",
                    fillcolor: backgroundColor
                }),
                yaxis: ['celsius', 'fahrenheit'].includes(value[1].units) ? 'y2' :
                    value[1].units === "percentage" ? 'y3' :
                        ['ppb', 'ug-m3'].includes(value[1].units) ? 'y4' :
                            ['mg-m3', 'ppm'].includes(value[1].units) ? 'y5' :
                                value[1].units === "hPa" ? 'y1' :
                                    value[1].units === "W/m²" ? 'y6' :
                                        value[1].units === "UV" ? 'y7' :
                                            value[1].units === "mm/h" ? 'y8' : "error"
            };
        });
        if (newData.length > 0) {
            updateState(state => {
                return {
                    ...state, data: newData,
                    y5Range: getPollutantRange(newData, units),
                    ...getAxeVisibilityConfig(getSelectedPollutants(visibleVariables, newData))
                };
            });
        } else {
            updateState(state => {
                return {...state, data: []};
            });
        }
    }, [rawData, units, isMounted, visibleVariables, loading, stationSensors]);

    useEffect(() => {
        // On first load, set visible variables to the first pollutant and the preset variables
        if (data.length > 0 && !visibleVariables.length) {
            updateVisibleVariables([0, ...getVisibleVariables(data, presetVariables)]);
        }
    }, [data, visibleVariables, updateVisibleVariables]);

    const handleTimeZoneChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setDataTimeZone(newAlignment);
        }
    };

    const updateMeteoDataOrigin = (newOrigin) => {
        if (newOrigin !== null) {
            setMeteoDataOrigin(newOrigin);
        }
    };

    const activeVariables = useMemo(() => {
        const newActiveVariables = [];
        visibleVariables.forEach(item => {
            if (data[item]) {
                newActiveVariables.push(data[item].id);
            }
        });
        return newActiveVariables;
    }, [data, visibleVariables]);

    const updateActiveVariables = useCallback((newActiveVariables) => {
        const newVisibleVariables = getVisibleVariables(data, newActiveVariables);
        updateVisibleVariables(newVisibleVariables);
    }, [data, updateVisibleVariables]);

    const legendItems = useMemo(() => (
        activeVariables.map((item) => {
            const itemIndex = data.findIndex(value => value.id === item);
            const backgroundColor = getPollutantBackgroundColor(item);
            const legendItem = {
                key: item,
                id: item,
                label: `${pollutantNames.get(item)} - ${unitsMap.get(data[itemIndex].units)}`,
                color: backgroundColor ?? getPollutantColor(item)
            };
            if (backgroundColor) {
                legendItem.borderColor = getPollutantColor(item);
            }
            return legendItem;
        })
    ), [activeVariables, data]);

    const handleItemDelete = useCallback((id) => {
        const newActiveVariables = activeVariables.filter(item => item !== id);
        updateActiveVariables(newActiveVariables);
    }, [activeVariables, updateActiveVariables]);

    const hasMultipleMeteoData = useMemo(() => {
        const meteoVariablesList = temperatureHumidityDataSourceMap[meteoDataSource.external];
        const meteoVariables = getVisibleVariables(data, meteoVariablesList);
        const hasExternalMeteoData = meteoVariables.length > 0;

        const internalVariablesList = temperatureHumidityDataSourceMap[meteoDataSource.internal];
        const internalVariables = getVisibleVariables(data, internalVariablesList);
        const hasInternalMeteoData = internalVariables.length > 0;

        if (hasExternalMeteoData && hasInternalMeteoData) {
            return true;
        } else if (hasExternalMeteoData) {
            setMeteoDataOrigin(meteoDataSource.external);
        }
        return false;
    }, [data]);

    const cursorShape = useMemo(() => {
        if (cursorInterval) {
            const x0 = moment(cursorInterval.start).tz(timeZone).format("YYYY-MM-DD HH:mm");
            const x1 = moment(cursorInterval.end).tz(timeZone).format("YYYY-MM-DD HH:mm");
                return {
                    type: 'rect',
                    xref: 'x',
                    yref: 'paper',
                    x0,
                    y0: 0,
                    x1,
                    y1: 1,
                    fillcolor: 'rgba(255, 0, 0, 0.1)',
                    layer: 'below',
                    line: {
                        width: 2,
                        color: 'rgba(255, 0, 0, 0.2)',
                    }
                };
        }
    }, [cursorInterval, timeZone]);

    const onDataSelected = useCallback((event) => {
        if (event === undefined) {
            return;
        }
        if (onSelect) {
            const startDateString = event.range.x[0].length === 10 ? `${event.range.x[0]} 00:00` : event.range.x[0];
            const endDateString = event.range.x[1].length === 10 ? `${event.range.x[1]} 00:00` : event.range.x[1];
            const selectedStartDate = moment.tz(startDateString, timeZone);
            const selectedEndDate = moment.tz(endDateString, timeZone);
            let startDate;
            let endDate;
            data[0].x.forEach((item) => {
                const dateString = moment(item).tz(timeZoneOptions.UTC).format("YYYY-MM-DD HH:mm");
                const date = moment.tz(dateString, timeZone);
                if (!startDate || Math.abs(selectedStartDate.diff(date)) < Math.abs(selectedStartDate.diff(startDate))) {
                    startDate = date;
                }
                if (!endDate || Math.abs(selectedEndDate.diff(date)) < Math.abs(selectedEndDate.diff(endDate))) {
                    endDate = date;
                }
            });
            const selectedPeriod = {
                start: startDate.toDate(),
                end: endDate.toDate()
            };
            const lastXPosition = event.selections[0].x1;
            if (lastXPosition) {
                onSelect(selectedPeriod, event.selections[0].x1);
            }
        }
    }, [data, onSelect, timeZone]);

    const emptyDataErrorMessage = useMemo(() => {
        if (rawData && Object.keys(rawData).length === 0) {
            return t("analyticScreen.temporal_variation.sensor_data_not_found");
        }
    }, [rawData, t]);

    return (
        <>
            <ChartLayout loading={loading} emptyData={!data?.length} error={emptyDataErrorMessage} height={height}
                         chartStyles={{"& .modebar": {left: "40%"}}} timeZone={dataTimeZone}
                         onTimeZoneChange={setDataTimeZone && handleTimeZoneChange} period={period}
                         onPeriodChange={setSelectedPeriod && setPeriod} dateRange={dateRange}
                         onResolutionChange={setSelectedResolution}
                         resolution={selectedResolution}
                         onDateRangeChange={setDateRange} sideContent={(
                !!data?.length && (
                    <Grid container spacing={2} style={{marginTop: 20, alignItems: "center"}}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                                {t("downloadScreen.detailPopUp.variables")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LegendList items={legendItems} onItemDelete={handleItemDelete}/>
                        </Grid>
                        <Grid item xs={12}>
                            <IconButton style={{marginLeft: -8}} aria-label="delete" size="small"
                                        onClick={() => setConfigDialogOpen(true)}>
                                <IconAdd fontSize="small"/>
                            </IconButton>
                        </Grid>
                    </Grid>
                )
            )}>
                <ChartView
                    layout={{
                        ...plotLayout,
                        yaxis: {...plotLayout.yaxis, visible: y1Visible, domain: y1Domain},
                        yaxis2: {...plotLayout.yaxis2, visible: y2Visible, domain: y2Domain},
                        yaxis3: {...plotLayout.yaxis3, visible: y3Visible, side: y3Side, domain: y3Domain},
                        yaxis4: {...plotLayout.yaxis4, visible: y4Visible, side: y4Side, domain: y4Domain},
                        yaxis5: y5Range.length === 0 ? {
                                ...plotLayout.yaxis5,
                                visible: y5Visible,
                                side: y5Side,
                                domain: y5Domain
                            } :
                            {...plotLayout.yaxis5, visible: y5Visible, side: y5Side, domain: y5Domain, range: y5Range},
                        yaxis6: {...plotLayout.yaxis6, visible: y6Visible, domain: y6Domain},
                        yaxis7: {...plotLayout.yaxis7, visible: y7Visible, side: y7Side, domain: y7Domain},
                        yaxis8: {...plotLayout.yaxis8, visible: y8Visible, side: y8Side, domain: y8Domain},
                        shapes: [cursorShape],
                        dragmode: onSelect ? "select" : "zoom"
                    }}
                    data={data}
                    config={config ?? plotConfig}
                    onClick={onClick}
                    onHover={onHover}
                    onUnHover={onUnHover}
                    onSelected={onDataSelected}
                />
            </ChartLayout>
            <SensorDataConfigDialog open={configDialogOpen} onClose={() => setConfigDialogOpen(false)}
                                    legendItems={data} activeItems={activeVariables}
                                    updateActiveItems={updateActiveVariables}
                                    hasMultipleMeteoData={hasMultipleMeteoData}
                                    meteoDataOrigin={meteoDataOrigin} updateMeteoDataOrigin={updateMeteoDataOrigin}/>
        </>
    );
};

export default SensorDataChart;
