import React, {useCallback, useEffect, useState} from 'react';
import {Grid, ImageList, ImageListItem, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {pollutantNames} from "../../pollutantNames";
import {CATEGORICAL_INDEXES} from "../../../../../utils/airQualityIndexUtil";
import {indexColors} from "bettairplaformutil/src/indexUtil";

export const DAYS_IN_MS = 86400000;

const useStyles = makeStyles({
    dayWithingSelectedMonth:{
        fontSize: "x-small",
        marginRight: 2,
        color: "#3e3e3e"
    },
    dayOutOfSelectedMonth:{
        fontSize: "x-small",
        marginRight: 2,
        color: "#cdcdcd"
    },
    pollutant:{
        margin:"auto"
    },
    calendar:{
        marginTop:0,
        marginBottom:0
    }
});

const CalendarComponent = ({date,data,indexType}) => {

    const [{state,overElement},updateState] = useState({state:[],overElement:-1});

    useEffect(
        ()=>{
            populateMonthFromPreviousMonth(data,date);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[date,data]);

    const getFirstDateOfSelectedMonth = useCallback((numberOfDaysBefore,lastDateOfPreviousMonth,date) =>{
        return numberOfDaysBefore === 0 ? getFirstDayStartingOnMonday(date) :
            getFirstDayStartingNotMonday(lastDateOfPreviousMonth,numberOfDaysBefore);
    },[]);

    const populateMonthFromPreviousMonth = useCallback((data,currentSelectedDate)=>{

        let currentMonthStartDayOfWeek = getDayOfWeek(currentSelectedDate);

        let numberOfDaysBefore =  currentMonthStartDayOfWeek === 1 ? 0 :
            currentMonthStartDayOfWeek !== 0 ?  currentMonthStartDayOfWeek - 1 : 6;


        let lastDateOfPreviousMonth = new Date(new Date(currentSelectedDate.getFullYear(), currentSelectedDate.getMonth(), 1).getTime() - (DAYS_IN_MS));

        let firstDatOfCalendar = getFirstDateOfSelectedMonth(numberOfDaysBefore,lastDateOfPreviousMonth,currentSelectedDate);

        let arrayData = Object.entries(data).map(value =>{
            return {...value,date : new Date(currentSelectedDate.getFullYear(), currentSelectedDate.getMonth(), Number(value[0]))};
        });


        let newState = Array.apply(null, Array(42)).map(function (x, i) {
            let currentDay = new Date(firstDatOfCalendar.getTime());
            currentDay.setDate(currentDay.getDate() + i);
            let dayData = arrayData.find(value => {
                    return value.date.getFullYear() === currentDay.getFullYear() &&
                    value.date.getMonth() === currentDay.getMonth() &&
                    value.date.getDate() === currentDay.getDate();
            }
            );
            return {id:i,
                day:currentDay.getDate(),
                value: dayData !== undefined ? dayData[1]?.value : null,
                index: dayData !== undefined ? dayData[1].index : null,
                pollutant: dayData !== undefined ? dayData[1].pollutant : null,
                belongToCurrentMonth: belongToCurrentMonth(currentDay,currentSelectedDate)
            };
        });
        updateState(oldState => ({...oldState,state:newState}));
    },[getFirstDateOfSelectedMonth]);

    function getFirstDayStartingOnMonday(date){
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    function getFirstDayStartingNotMonday(lastDateOfPreviousMonth,numberOfDaysBefore){
       return new Date(lastDateOfPreviousMonth.getTime() - ((numberOfDaysBefore - 1)*DAYS_IN_MS));
    }

    function belongToCurrentMonth (date, currentSelectedDate){
        let firstDay = new Date(currentSelectedDate.getFullYear(), currentSelectedDate.getMonth(), 1);
        let lastDay = new Date(currentSelectedDate.getFullYear(), currentSelectedDate.getMonth() + 1, 0);
        return date>= firstDay && date <= lastDay;
    }

    function getDayOfWeek(date){
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return firstDay.getDay(); //start from 0 sunday
    }

    const classes = useStyles();

    const handleMouseOver= (id, index) => {
        if (index !== null && CATEGORICAL_INDEXES.includes(indexType) === false) {
            updateState(state =>({...state,overElement:id}));
        } else {
            updateState(state =>({...state,overElement:-1}));
        }
    };

    const handleMouseLeave= (index) =>{
        if(index === null){
            updateState(state =>({...state,overElement:  -1 }));
        }
    };

    const getCellColor = function (index) {
        const colorIndex = index >= 0 ? index : null;
        return indexColors[indexType]?.[colorIndex] || "#EDEDED";
    };

    return (
        <ImageList rowHeight={50} cols={7} gap={2} className={classes.calendar} onMouseLeave={ _ => handleMouseLeave(null)}>
            {state.map(value =>{
                return (
                    <ImageListItem key={value.id} style={{backgroundColor: overElement !== value.id
                            ? getCellColor(value.index) : "#fff"}}>
                        <Grid container
                              onMouseOver={_ => handleMouseOver(value.id, value.index)}
                              onMouseLeave={_ => handleMouseLeave(value.index)}
                        >
                            <Grid item xs={12}>
                                <Typography variant={"subtitle2"} align={"right"}
                                            className={value.belongToCurrentMonth === true ?
                                                classes.dayWithingSelectedMonth : classes.dayOutOfSelectedMonth }
                                >{value.day}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align={"center"} className={classes.pollutant}
                                            variant={"subtitle2"} >
                                    {overElement !== value.id ? pollutantNames.get(value.pollutant) : value.value}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}/>
                        </Grid>
                    </ImageListItem>
                );
            })}
        </ImageList>
    );
};

export default CalendarComponent;
