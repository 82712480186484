import React, {useCallback, useEffect, useState} from 'react';
import {Backdrop, Button, Grid, Skeleton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {downloadOrganizationLogoRequest} from "../../../../../requests/organization/organizationRequests";
import useRequest from "../../../../../hooks/useRequest";
import useMessage from "../../../../../hooks/useMessage";

const ConflictLogoDialog = ({onCancel, onReplace, }) => {

    const {t} = useTranslation();
    const [loading, updateLoading] = useState(false);
    const [localImageURL, updateLocalImageURl] = useState(null);
    const {handleErrorResponse} = useRequest();
    const {setError} = useMessage();

    const fetchLogoFunction = useCallback(() => {
        return async () => {
            updateLoading(true);
            try {
                const url = await downloadOrganizationLogoRequest();
                updateLocalImageURl(url);
                updateLoading(false);
            } catch (e) {
                handleErrorResponse(e, response => {
                    updateLoading(false);
                    setError(response,
                        false,
                        `${t("error")} ${response.status},
                         ${t("organizationPreferences.downloading_organization_logo")}`);
                    onCancel();
                });
            }
        };
    }, [handleErrorResponse, onCancel, setError, t]);

    useEffect(() => {
        fetchLogoFunction()();
        // eslint-disable-next-line
    }, []);

    return (
        <Backdrop data-testid={"conflict-logo-pop-up"} open={true} style={{zIndex: 1999}}>
            <Grid container style={{backgroundColor: "#fff", maxWidth: 680, padding: 30}}>
                <Grid item xs={12}>
                    <Typography variant={"h5"}>{t("organizationPreferences.conflict")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{marginTop:32, marginBottom:32}}>{t("organizationPreferences.conflict_description")}</Typography>
                </Grid>
                <Grid item container xs={12} style={{marginTop: 40, marginBottom: 40}} justifyContent={"center"} alignContent={"center"}>
                    {loading && <div style={{display: "flex",}}>
                        <Skeleton width="400px" height={200} animation="wave"
                                  sx={{transform: 'scale(1)'}}/>
                    </div>}
                    {localImageURL && !loading && <img src={localImageURL} alt="Organization logo"
                         style={{width: 400, height: 200, objectFit: 'contain'}}/>}
                </Grid>
                <Grid item container xs={12} style={{justifyContent: "flex-end"}}>
                    <Button data-testid={"cancel-replace-logo-button"}
                            onClick={onCancel}
                            color="primary">
                        {t("cancel")}
                    </Button>
                    <Button data-testid={"replace-logo-button"}
                            onClick={onReplace} color="primary">
                        {t("replace")}
                    </Button>
                </Grid>
            </Grid>
        </Backdrop>
    );
};

export default ConflictLogoDialog;
