import React from 'react';
import {Grid, Typography} from "@mui/material";
import MultipleSelector from "../../../../../common/multipleSelector/MultipleSelector";
import {useSelector} from "react-redux";
import {isEqual} from "lodash";
import {getStationOptions, STATION_SEARCHABLE_FIELDS} from "../../../../../../utils/stationUtil";
import {useTranslation} from "react-i18next";

const AlarmConfigStep3 = ({selectedVariable, showTimeZoneError, callback}) => {

    const {t} = useTranslation();

    const stationsByPollutant = useSelector(state => (
        getStationOptions(selectedVariable
            ? state.dashboardUI.stations
                .filter(station => selectedVariable === "battery" || station.sensors.includes(selectedVariable))
            : state.dashboardUI.stations
        )
    ), isEqual);

    return (
        <Grid container>
            <Grid item xs={12}>
                <MultipleSelector initialSelected={[]}
                                  items={stationsByPollutant}
                                  isStationList
                                  selectedCallback={callback}
                                  min={1}
                                  searchableFields={STATION_SEARCHABLE_FIELDS}
                                  isStation={true}

                />
            </Grid>
            {showTimeZoneError && <Grid item xs={12}><Typography
                style={{color: '#FFA500', marginTop:8}}>{t("alarmScreen.popUp.timeZoneWarning")}</Typography></Grid>}
        </Grid>
    );
};

export default AlarmConfigStep3;
