import { useState, createContext, useContext, useCallback, useEffect } from "react";
import { defaultMapType } from "./panel/BaseMapPanel";
import { useSelector } from "react-redux";
import {getSavedLayerState} from "../../../../utils/localStorageUtil";

const MapContext = createContext({});
const STORAGE_NAME = "mapLayerState";

export const MapContextProvider = ({ children }) => {
    const [currentMap, setCurrentMap] = useState();
    const [mapLoading, setMapLoading] = useState(false);
    const userId = useSelector(state => state.auth.id);
    const [layerState, setLayerState] = useState({
        stationLayerActive: true,
        showOfflineStationsOn: true,
        pollutantRosesLayerActive: false,
        roseLegendData: null,
        selectedPollutant: undefined,
        mapType: defaultMapType,
        ...getSavedLayerState(STORAGE_NAME, userId)
    });
    const { stationLayerActive, showOfflineStationsOn, pollutantRosesLayerActive, roseLegendData,
        selectedPollutant, mapType } = layerState;
    const setStationLayerActive = useCallback(value => (
        setLayerState(prevState => ({ ...prevState, stationLayerActive: value }))
    ), []);
    const setShowOfflineStationsOn = useCallback(value => (
        setLayerState(prevState => ({ ...prevState, showOfflineStationsOn: value }))
    ), []);
    const setPollutantRosesLayerActive = useCallback(value => (
        setLayerState(prevState => ({ ...prevState, pollutantRosesLayerActive: value }))
    ), []);
    const setRoseLegendData = useCallback(value => (
        setLayerState(prevState => ({ ...prevState, roseLegendData: value }))
    ), []);
    const setSelectedPollutant = useCallback(value => (
        setLayerState(prevState => ({ ...prevState, selectedPollutant: value }))
    ), []);
    const setMapType = useCallback(value => (
        setLayerState(prevState => ({ ...prevState, mapType: value }))
    ), []);

    useEffect(() => {
        localStorage.setItem(`${STORAGE_NAME}-${userId}`, btoa(JSON.stringify(layerState)));
    }, [layerState, userId]);

    return (
        <MapContext.Provider value={{ currentMap, setCurrentMap, mapLoading, setMapLoading, selectedPollutant,
            setSelectedPollutant, stationLayerActive, setStationLayerActive, showOfflineStationsOn,
            setShowOfflineStationsOn, pollutantRosesLayerActive, setPollutantRosesLayerActive, roseLegendData,
            setRoseLegendData, mapType, setMapType }}>
            {children}
        </MapContext.Provider>
    );
};

export const useMapContext = () => useContext(MapContext);
