import React from 'react';
import {
    Backdrop,
    Card,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import {getFormattedUtcDate} from "../../../../utils/getFormattedDateTime";
import {shallowEqual, useSelector} from "react-redux";
import {getVariableName} from "../pollutantNames";
import {useTranslation} from "react-i18next";
import {DECIMAL_SEPARATOR} from "./DownloadConfigPopUp";
import {getStationAliasesMap} from "../../../../utils/stationUtil";

const useStyles = makeStyles({
    card: {
        paddingTop: 16,
        paddingBottom: 10,
    },
    tittle: {
        marginLeft: 30,
        color: "#434343",
        marginBottom: 16
    },
    subTittle: {
        fontSize: 20,
        color: "#434343",
        marginBottom: 4
    },
    value: {
        fontSize: 18,
        color: "#a0a0a0",
        marginBottom: 32
    },
    buttons: {
        marginRight: 30,
        display: "flex",
        marginTop: 10,
        justifyContent: "flex-end"
    },
    divider: {
        marginBottom: 32
    },
    divider2: {
        marginTop: 8
    },
    popUpContainer: {
        width: 620
    },
    detailContainer: {
        paddingLeft: 20,
        maxWidth: 500,
        paddingBottom: 20
    },
    tableContainer: {
        marginTop: 20,
        height: 240,
        width: 220,
        borderRadius: 6,
        background: "#eaeaea",
    }
});

const DownloadDetailPopUp = ({open, handleCloseDetailCallback, downloadDetail}) => {

    const {t} = useTranslation();
    const stationAliases = useSelector(state => getStationAliasesMap(state.dashboardUI.stations), shallowEqual);
    const classes = useStyles();

    const downloadInterval = downloadDetail.timeInterval && (
        downloadDetail.timeInterval === 1440 ? `1 ${t("units.day")}` :
            downloadDetail.timeInterval === 60 ? `1 ${t("units.hour")}` :
                `${downloadDetail.timeInterval} ${t("units.minutes")}`
    );

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={open}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"h6"}
                                    className={classes.tittle}>{t("downloadScreen.detailPopUp.download_detail")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid container item xs={12} className={classes.detailContainer}>
                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("downloadScreen.name")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{downloadDetail.name}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("downloadScreen.detailPopUp.date_range")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{`${getFormattedUtcDate(new Date(downloadDetail.time))} 
                                        - ${getFormattedUtcDate(new Date(downloadDetail.endtime))}`}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("units.concentration_units")}</Typography>

                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{downloadDetail.pollutants === "eu" ?
                                "µg/m³ - mg/m³" : "ppb - ppm"}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("units.temperature_units")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{downloadDetail.temperature === "celsius" ?
                                "Celsius (ºC)" : "Fahrenheit (ºF)"}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("downloadScreen.popUp.group_interval")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{downloadInterval}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("downloadScreen.popUp.decimal_separator")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{downloadDetail.separator === DECIMAL_SEPARATOR.comma ?
                                t("downloadScreen.popUp.comma_separator") :
                                t("downloadScreen.popUp.dot_separator")}
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("downloadScreen.detailPopUp.stations")}</Typography>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader aria-label="event table">
                                    <TableBody className={classes.table}>
                                        {downloadDetail.stations.map((id) => (
                                            <TableRow key={id}>
                                                <TableCell
                                                    align="left">{stationAliases[id]}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("downloadScreen.detailPopUp.variables")}</Typography>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader aria-label="event table">
                                    <TableBody>
                                        {downloadDetail.variables.map((item) => (
                                            <TableRow key={item}>
                                                <TableCell align="left">{
                                                    getVariableName(item)
                                                }</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={handleCloseDetailCallback}>{t("ok")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default DownloadDetailPopUp;
