import {Grid, Link, TextField, Typography} from "@mui/material";
import logo from "../../../../assets/bettair_logo.png";
import {MINIMUN_PASSWORD_LENGHT} from "../../../../constants";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import LoadingButton from "../../../common/buttons/LoadingButton";

const useStyles = makeStyles({
    button: {
        color: '#ffffff',
        marginBottom: 12,
        marginTop: 12
    },
    img: {
        width: 220,
        paddingBottom: 30
    },
    forgot: {
        cursor:"pointer",
        display: 'flex',
        justifyContent: "center"
    },
    loginContainer: {
        minWidth: 400,
        maxWidth: 600,
        background: 'white',
        padding: 40,
        borderRadius: 6
    },
    form: {
        width: '100%'
    }
});

const Credentials = ({
                         validForm,
                         loading,
                         logging,
                         email,
                         handleEmail,
                         handleEnter,
                         password,
                         handlePassword,
                         handleLogin,
                         handlerForgot
                     }) => {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Grid container item xs={6} md={4} justifyContent={"center"} alignItems={"center"} direction={"column"}
              className={classes.loginContainer} alignContent={"center"}
        >
            <Grid item>
                <img className={classes.img} src={logo} alt="logo"/>
            </Grid>
            <Grid container item>
                <Typography className={classes.form} align={"left"}
                            variant={"h5"}>{t('loginScreen.sign_in')}</Typography>
            </Grid>
            <Grid container item>
                <form className={classes.form} noValidate>
                    <TextField label={t('loginScreen.email')} variant="outlined"
                               required autoComplete="email" autoFocus fullWidth margin="normal"
                               onChange={handleEmail} value={email}
                               onKeyPress={handleEnter}
                               inputProps={{"data-testid": "login-email"}}
                    />
                    <TextField label={t('loginScreen.password')} variant="outlined"
                               required autoComplete="current-password"
                               type="password" fullWidth margin="none"
                               onChange={handlePassword}
                               value={password}
                               onKeyPress={handleEnter}
                               inputProps={{"data-testid": "login-password"}}
                               helperText={`${t('loginScreen.minimun_password')} ${MINIMUN_PASSWORD_LENGHT} ${t('loginScreen.characters')}`}
                    />
                    <LoadingButton
                        data-testid="login-sign-in" loading={logging} disabled={!validForm || loading || logging}
                        className={classes.button} color={"primary"} fullWidth onClick={handleLogin} size={"large"}
                        variant="contained">{t('loginScreen.sign_in')}</LoadingButton>
                    <Link className={classes.forgot} onClick={handlerForgot} variant="body2">
                        {t('loginScreen.forgot_password')}
                    </Link>
                </form>
            </Grid>
        </Grid>
    );
};
export default Credentials;
