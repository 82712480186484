import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Button, Card, CardContent, Grid, Link, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import CardLoading from "../../../common/card_views/CardLoading";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import {DataGrid} from "@mui/x-data-grid";
import AlarmConfigPopUp from "./configure_alarms/AlarmConfigPopUp";
import {pollutantNames} from "../pollutantNames";
import ConfirmDialog from "../../../common/ConfirmDialog";
import {useTranslation} from "react-i18next";
import useRequest from "../../../../hooks/useRequest";
import {getVariableUnitName} from "../unitsNames";
import AlarmStationListPopUp from "./AlarmStationListPopUp";
import {getTriggerName} from "./triggerNames";
import {useMountComponent} from "../../../../hooks/useMountComponent";
import useMessage from "../../../../hooks/useMessage";
import {deleteAlarmRequest} from "../../../../requests/alarms/schedule/deleteAlarmRequest";
import {createAlarmRequest} from "../../../../requests/alarms/schedule/createAlarmRequest";
import useAuth from "../../../../hooks/useAuth";
import {WRITE_ALARMS} from "../organization/members/UserPermissions";
import useUnits from "../../../../hooks/useUnits";
import AlarmStatePopUp from "./configure_alarms/AlarmStatePopUp";
import _ from "lodash";
import LastUpdate from "../../../common/LastUpdate";
import {alarmOwner, alarmType} from "./alarmConfigs";
import useUserPrefTimeZone from "../../../../hooks/useUserPrefTimeZone";
import {timeZoneOptions} from "../../../../constants";

const useStyles = makeStyles({
    cardTittle: {
        marginLeft: 20,
        color: "#000000",
        fontWeight: "bold"
    },
    deleteButton: {
        color: "#ffffff",
        background: "#f6343d",
        '&:hover': {
            background: "#81191c"
        },
    },
    rowTable: {
        display: "flex",
        width: "100%vw",
        justifyContent: "center",
        marginTop: 32
    },
    table: {
        maxWidth: 1600,
        height: 390,
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {},
    }
});



const ScheduleAlarmCardView = ({className, alarms, updateAlarmCallback, loading, loadingCallback}) => {

    const {hasPermission} = useAuth();
    const {units, convertToUserUnit} = useUnits();
    const {t} = useTranslation();
    const isMounted = useMountComponent();
    const {handleErrorResponse} = useRequest();
    const {setError, setSuccess} = useMessage();
    const {convertDateToUserPrefTz, userTimeZone} = useUserPrefTimeZone();


    const [selectedAlarmType, updateSelectedAlarmType] = useState(alarmOwner.organization);
    const [data, updateData] = useState([]);
    const [selectedAlarms, updateSelectedAlarms] = useState([]);
    const [enableDelete, updateEnableDelete] = useState(false);
    const [alarmStationList, updateAlarmStationList] = useState([]);
    const [openConfirmDeleteDialog, updateOpenConfirmDeleteDialog] = useState(false);
    const [alarmStatePopUp, updateAlarmStatePopup] = useState(null);
    const [currentAlarms, updateCurrentAlarms] = useState([]);
    const [updateAlarmRequired, updateAlarmRequiredStatus] = useState(false);
    const [currentLastUpdate, updateCurrentLastUpdate] = useState(null);
    const [displayNewAlarmUpdateAvailable, updateDisplayNewAlarmAvailable] = useState(false);


    const [{openAddAlarm}, updateState] =
        useState({
            openAddAlarm: false,
        });

    useEffect(() => {
        updateSelectedAlarms([]);
        let filteredAlarms = currentAlarms.filter(item => item.isOrganizationAlarm === (selectedAlarmType === alarmOwner.organization));
        filteredAlarms = filteredAlarms.map(item => {
            const events = Object.values(item.state);
            const alarmStationsLenght = item.stations.length;
            const triggeredStations = events.length === 0 ? `0/${alarmStationsLenght}` :
                `${events.filter(eventItem => eventItem.triggered).length}/${alarmStationsLenght}`;
            return {...item, triggeredStations};
        });
        updateData(filteredAlarms);
    }, [currentAlarms, selectedAlarmType]);


    useEffect(() => {
        if (currentAlarms.length === 0 || updateAlarmRequired) {
            updateAlarmRequiredStatus(false);
            updateCurrentAlarms(alarms);
            updateCurrentLastUpdate(new Date());
        } else {
            const areAlarmsEqual = _(alarms).xorWith(currentAlarms, _.isEqual).isEmpty();
            if (!areAlarmsEqual) {
                updateDisplayNewAlarmAvailable(true);
            }else{
                updateCurrentLastUpdate(new Date());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alarms, currentAlarms]);

    useEffect(() => {
        updateEnableDelete(selectedAlarms.length !== 0);
    }, [selectedAlarms]);


    const handleDisplayAlarmStationList = (stationList) => {
        updateAlarmStationList(stationList);
    };

    const handleDisplayAlarmState = (alarm) => {
        updateAlarmStatePopup(alarm);
    };

    const handleCloseAlarmStatePopUp = useCallback(() => {
        updateAlarmStatePopup(null);
    }, []);

    const handleCloseAlarmStationList = useCallback(() => {
        updateAlarmStationList([]);
    }, []);

    const handleCloseConfirmeDialog = useCallback(() => {
        updateOpenConfirmDeleteDialog(false);
    }, []);

    const onSelectionModelChange = (newSelectionModel) => {
        const editableItems = !hasPermission(WRITE_ALARMS) ? [] : newSelectionModel;
        updateSelectedAlarms(editableItems);
    };

    const handleDeleteButtonPressed = () => {
        updateOpenConfirmDeleteDialog(true);
    };

    const handleAddAlarm = () => {
        updateState(state => ({...state, openAddAlarm: true}));
    };

    const handleConfirmDelete = useCallback(() => {
        loadingCallback(true);
        updateOpenConfirmDeleteDialog(false);
        updateAlarmRequiredStatus(true);
        Promise.all(deleteAlarmRequest(selectedAlarms)).then(() => {
            if (!isMounted.current) {
                return;
            }
            updateAlarmCallback(true, false, true);
            setSuccess("alarmScreen.alarm_deleted");
        }).catch(err => {
            if (!isMounted.current) {
                return;
            }
            handleErrorResponse(err?.response, () => {
                updateAlarmCallback(true, false, true);
                setError(err.response,
                    false,
                    `${t("error")} ${err.response.status},
                            ${t("alarmScreen.deleting_alarm")}`);
            });
        }).finally(() => {
            updateEnableDelete(false);
            updateSelectedAlarms([]);
        });
    }, [loadingCallback, selectedAlarms, isMounted, updateAlarmCallback, setSuccess, handleErrorResponse, setError, t]);

    const finishAlarmSetup = useCallback((body) => {
        loadingCallback(true);
        updateState(state => ({...state, openAddAlarm: false}));
        createAlarmRequest(body, (err, data) => {
            if (!isMounted.current) {
                return;
            }
            if (!err) {
                updateSelectedAlarmType(body.isOrganizationAlarm ? alarmOwner.organization : alarmOwner.userAlarm);
                updateAlarmRequiredStatus(true);
                updateAlarmCallback(true, false, true);
                setSuccess("alarmScreen.alarm_created");
            } else {
                handleErrorResponse(err?.response, () => {
                    loadingCallback(false);
                    setError(data,
                        false,
                        `${t("error")} ${data.status},
                        ${t("alarmScreen.creating_alarm")}`);
                });
            }
        });
    }, [loadingCallback, isMounted, updateAlarmCallback, setSuccess, handleErrorResponse, setError, t]);

    const cancelAlarmSetup = useCallback(() => {
        updateState(state => ({...state, openAddAlarm: false}));
    }, [updateState]);

    const handleAlarmTypeChange = (_, newAlarmType) => {
        if (newAlarmType) {
            updateSelectedAlarms([]);
            updateSelectedAlarmType(newAlarmType);
        }
    };

    const handleRefreshAlarms = () => {
        updateDisplayNewAlarmAvailable(false);
        updateCurrentAlarms(alarms);
        updateCurrentLastUpdate(new Date());
    };

    const columns = useMemo(() => {
        return [
            {field: 'id', headerName: 'ID', hide: true},
            {field: 'name', headerName: t("alarmScreen.popUp.name"), width: 220},
            {
                field: 'variable', headerName: t("alarmScreen.variable"), width: 140,
                renderCell: (params) => (
                    <Typography>{params.row.type === alarmType.threshold ? pollutantNames.get(params.row.config.variable) ?? "Battery" : t("alarmScreen.type_state")}</Typography>
                )
            },
            {
                field: 'stations', headerName: t("alarmScreen.stations"), width: 140,
                renderCell: (params) => (
                    <Link component="button" underline="always" onClick={(event) => {
                        event.stopPropagation();
                        handleDisplayAlarmStationList(params.value);
                    }}>{t("alarmScreen.view")}</Link>
                )
            },
            {
                field: 'thresholdType', headerName: t("alarmScreen.triggered_stations"), width: 190,
                renderCell: (params) => (
                    <Typography>{params.row.type === alarmType.threshold  ? getTriggerName(params.row.config.thresholdType, t) : "--"}</Typography>
                )
            },
            {
                field: 'value', headerName: t("alarmScreen.threshold"), width: 130,
                renderCell: (params) => (
                    <Typography>{params.row.type === alarmType.threshold  ? `${
                        convertToUserUnit(params.row.config.variable, params.row.config.thresholdValue)
                    }  ${getVariableUnitName(params.row.config.variable, units)}` : "--"}
                    </Typography>
                )
            },
            {
                field: 'triggeredStations', headerName: t("alarmScreen.triggered_plural"), width: 140,
                renderCell: (params) => (
                    <Link component="button" underline="always" onClick={(event) => {
                        event.stopPropagation();
                        handleDisplayAlarmState(params.row);
                    }}>{params.value}</Link>
                )
            },
            {
                field: 'date', headerName: `${t("common.creation_date")} ${userTimeZone === timeZoneOptions.UTC ? "(UTC)": t("common.localTimeZone")}`, width: 230,
                renderCell: (params) => (
                    <Typography>{convertDateToUserPrefTz(new Date(params.value), false, false)}</Typography>
                )
            }
        ];
    }, [convertDateToUserPrefTz, convertToUserUnit, t, units, userTimeZone]);


    const classes = useStyles();

    return (
        <>
            {openConfirmDeleteDialog &&
                <ConfirmDialog hideDialog={handleCloseConfirmeDialog} title={t("alarmScreen.delete_alarm")}
                               question={t("alarmScreen.are_you_sure_to_delete")}
                               confirm={handleConfirmDelete}/>}
            {openAddAlarm && <AlarmConfigPopUp open={openAddAlarm} cancelAlarmSetup={cancelAlarmSetup}
                                               finishAlarmSetup={finishAlarmSetup}/>}
            <Card className={className}>
                {!loading &&
                    <CardContent>
                        <Grid container direction={"row"} spacing={2} justifyContent={"space-between"}
                              alignItems={"center"}>
                            <Grid container item xs={12} sm={12} md={4} lg={6} alignItems={"flex-start"}
                                  alignContent={"flex-start"}>
                                <Typography className={classes.cardTittle}
                                            variant={"h5"}>{t("alarmScreen.alarms")}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                {hasPermission(WRITE_ALARMS) && <Button
                                    data-testid={"add-alarm-button"}
                                    fullWidth
                                    onClick={_ => {
                                        handleAddAlarm();
                                    }}
                                    disabled={loading}
                                    color={"primary"}
                                    variant="contained"
                                    startIcon={<AddAlarmIcon/>}
                                >
                                    {t("alarmScreen.add_alarm")}
                                </Button>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                {hasPermission(WRITE_ALARMS) && <Button
                                    fullWidth
                                    data-testid={"delete-alarm-button"}
                                    onClick={_ => {
                                        handleDeleteButtonPressed();
                                    }}
                                    disabled={loading || !enableDelete}
                                    variant="contained"
                                    className={classes.deleteButton}
                                    startIcon={<DeleteIcon/>}
                                >
                                    {t("alarmScreen.delete")}
                                </Button>}
                            </Grid>
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={selectedAlarmType}
                                    exclusive
                                    onChange={handleAlarmTypeChange}
                                >
                                    <ToggleButton
                                        value={alarmOwner.organization}>{t("alarmScreen.organization_alarm")}</ToggleButton>
                                    <ToggleButton
                                        value={alarmOwner.userAlarm}>{t("alarmScreen.my_alarms")}</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12} className={classes.rowTable}>
                                <DataGrid
                                    className={classes.table}
                                    rows={data}
                                    columns={columns}
                                    columnBuffer={8}
                                    pageSize={7}
                                    rowsPerPageOptions={[7]}
                                    Name="dataGrid1"
                                    selectionModel={selectedAlarms}
                                    onSelectionModelChange={onSelectionModelChange}
                                    checkboxSelection={hasPermission(WRITE_ALARMS)}
                                    density={"standard"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LastUpdate date={currentLastUpdate}
                                            updateAvailable={displayNewAlarmUpdateAvailable}
                                            handleRefreshCallback={handleRefreshAlarms}/>
                            </Grid>
                        </Grid>

                    </CardContent>
                }
                {loading &&
                    <CardLoading/>
                }
                {alarmStationList.length > 0 && <AlarmStationListPopUp
                    handleCloseDetailCallback={handleCloseAlarmStationList}
                    stationIdList={alarmStationList}/>}
                {
                    alarmStatePopUp !== null &&
                    <AlarmStatePopUp alarm={alarmStatePopUp} handleClose={handleCloseAlarmStatePopUp}/>
                }
            </Card>
        </>
    );
};

export default ScheduleAlarmCardView;
