import React, {useCallback, useEffect, useMemo, useState} from 'react';
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";
import ChartView from "../../../../common/chart/ChartView";
import {DEFAULT_PERIOD} from "../../../../../constants";
import {getAvailableSensors} from "../../../../../utils/stationUtil";
import {useSelector} from "react-redux";
import _, {isEqual} from "lodash";
import {useTranslation} from "react-i18next";
import ScatterPlotSideContent from "./ScatterPlotSideContent";
import {requestCompareScatterData} from "../../../../../requests/compare/compareScatterRequest";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {unitsMap} from "../../unitsNames";
import {pollutantNames} from "../../pollutantNames";
import {compareStationsColors} from "../CompareScreen";
import useRequest from "../../../../../hooks/useRequest";
import useMessage from "../../../../../hooks/useMessage";
import {generateIncrementalArray} from "../../../../../utils/regresionLineUtil";
import {Typography} from "@mui/material";

const plotLayoutInitialState = {
    autosize: true,
    showlegend: false,
    hovermode: 'closest',
    xaxis: {showline: true, zeroline: false},
    yaxis: {showline: true, zeroline: false},
    trendline: "ols",
    margin: {t: 10, b: 40, l: 60, r: 40},
};

const plotConfig = {
    modeBarButtonsToRemove: [
        "select2d", "lasso2d", "toggleHover", "sendDataToCloud", "toggleSpikelines", "hoverCompareCartesian",
        "hoverClosestCartesian"
    ],
    displaylogo: false
};

const ScatterPlotCardView = ({className}) => {

    const {t} = useTranslation();
    const notFoundMessage = useMemo(() => {
        return t("dataNotFound");
    }, [t]);
    const {units} = useSelector(state => state.auth);
    const {setError} = useMessage();
    const {handleErrorResponse} = useRequest();
    const [loading, setLoading] = useState(false);
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [dateRange, setDateRange] = useState(null);
    const selectedRange = useMemo(() => getDateRange(period, dateRange), [period, dateRange]);
    const [plotLayout, setPlotLayout] = useState(plotLayoutInitialState);
    const selectCompareStations = useSelector(state => state.dashboardUI.selectCompareStations, isEqual);
    const [pollutantList, updatePollutantList] = useState([]);
    const [selectedPollutant, updateSelectedPollutant] = useState(null);
    const [selectedStation, updateSelectedStation] = useState(null);
    const [rawData, setRawData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [stationOptions, setStationOptions] = useState([]);
    const [compareStations, setCompareStations] = useState([]);

    const [error, setChartError] = useState(notFoundMessage);

    useEffect(() => {
        let pollutantList = getAvailableSensors(selectCompareStations).map(it => (it.id)).filter(item => !["noise","VOC_IAQ"].includes(item));
        updatePollutantList(pollutantList);
        updateSelectedStation(selectCompareStations?.at(0)?.id ?? null);
        updateSelectedPollutant(pollutantList[0]);

        if (selectCompareStations.length > 0) {
            const options = selectCompareStations.map((station) => {
                return {value: station.id, label: station.alias};
            });
            setStationOptions(options);
        }

    }, [selectCompareStations]);

    useEffect(() => {
        updateChartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPollutant, selectedStation, selectedRange]);

    useEffect(() => {
        const visibleStations = compareStations.filter(item => !item.isHidden).map(it => it.stationId);
        const filteredData = rawData.filter(item => visibleStations.includes(item.compareStation));
        if (compareStations.length > 0 && visibleStations.length === 0) {
            setChartError(t("compareScreen.noStationSelected"));
        } else if (filteredData.length === 0) {
            setChartError(t("compareScreen.stationData.comparable_data_not_found"));
        } else {
            setChartError(null);
        }
        setChartData(filteredData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawData, compareStations]);

    useEffect(() => {
        const compareStations = stationOptions.map((item, index) => ({
            id: index,
            stationId: item.value,
            label: item.label,
            isHidden: false,
            color: compareStationsColors[index]
        })).filter(item => item.stationId !== selectedStation);
        setCompareStations(compareStations);
    }, [selectedStation, stationOptions]);

    const handleStationChange = useCallback((refStation) => {
        updateSelectedStation(refStation);
    }, []);

    const onPollutantSelect = useCallback((newPollutant) => {
        updateSelectedPollutant(newPollutant);
    }, []);

    const onItemSelected = useCallback((stationIndex) => {
        setCompareStations(compareStations.map(item =>
            item.id === stationIndex ? {...item, isHidden: !item.isHidden} : item));
    }, [compareStations]);

    const updateChartData = useCallback(async () => {

        if (!selectedPollutant || !selectedStation || !selectedRange) return;

        try {
            setLoading(true);
            setRawData([]);
            const data = await requestCompareScatterData({
                sensor: selectedPollutant,
                refStation: selectedStation,
                stations: selectCompareStations.map(it => it.id),
                pollutants: units.pollutants,
                temperature: units.temperature,
                time: selectedRange[0],
                endtime: selectedRange[1]
            });

            let minAbsoluteX = Number.MAX_VALUE;
            let maxAbsoluteX = Number.MIN_VALUE;
            let minAbsoluteY = Number.MAX_VALUE;
            let maxAbsoluteY = Number.MIN_VALUE;
            let unitName;
            let pollutantName;
            const xStationName = selectCompareStations.find(it => it.id === selectedStation)?.alias;

            setRawData(_.flatten(data.map(series => {

                if (!series) return null;

                const xStation = Object.keys(series.x)[0];
                const yStation = Object.keys(series.y)[0];
                if (!unitName) unitName = unitsMap.get(series.x[xStation].units);
                if (!pollutantName) pollutantName = pollutantNames.get(selectedPollutant);

                const colorIndex = selectCompareStations.findIndex(it => it.id === yStation);

                const output = [];

                const {m, y} = series.linearRegression.equation;
                const {r2} = series.linearRegression;

                const minX = Math.min(...series.x[xStation][selectedPollutant]);
                const maxX = Math.max(...series.x[xStation][selectedPollutant]);
                if (minX < minAbsoluteX) minAbsoluteX = minX;
                if (maxX > maxAbsoluteX) maxAbsoluteX = maxX;

                const minY = Math.min(...series.y[yStation][selectedPollutant]);
                const maxY = Math.max(...series.y[yStation][selectedPollutant]);
                if (minY < minAbsoluteY) minAbsoluteY = minY;
                if (maxY > maxAbsoluteY) maxAbsoluteY = maxY;

                const xFit = generateIncrementalArray(minX, maxX, 200);
                const yFit = xFit.map(x => m * x + y);
                const formula = `y = ${m}x + ${y} <br> R² = ${r2}`;

                output.push({
                    compareStation: yStation,
                    name: "Línea de tendencia",
                    x: xFit,
                    y: yFit,
                    mode: "lines",
                    line: {color: compareStationsColors[colorIndex], width: 2, dash: "dot"},
                    text: formula,
                    hoverinfo: "text"
                });

                const yStationName = selectCompareStations.find(it => it.id === yStation)?.alias;

                output.push({
                    compareStation: yStation,
                    name: t("analyticScreen.scatterPlot.title"),
                    x: series.x[xStation][selectedPollutant],
                    y: series.y[yStation][selectedPollutant],
                    hovertemplate: `<b>${xStationName}</b>: %{x} ${unitName}<br><b>${yStationName}</b>: %{y} ${unitName}`,
                    hoverlabel: {namelength: 0},
                    type: 'scatter',
                    mode: 'markers',
                    marker: {color: compareStationsColors[colorIndex], size: 4}
                });
                return output;
            }).filter(item => item !== null)));

            setPlotLayout({
                ...plotLayoutInitialState,
                yaxis: {
                    title: `${unitName}`,
                    ...plotLayoutInitialState.yaxis,
                    range: [minAbsoluteY, maxAbsoluteY],
                },
                xaxis: {
                    title: `${xStationName} - ${unitName} `,
                    ...plotLayoutInitialState.xaxis,
                    range: [minAbsoluteX, maxAbsoluteX],
                }
            });
            setLoading(false);

        } catch (e) {
            setLoading(false);

            handleErrorResponse(e.response, response => {
                if (response.status === 404) {
                    setChartError(notFoundMessage);
                }else{
                    setChartError(response.status);
                    setError(response, false,
                        "analyticScreen.scatterPlot.could_not_update_station_data");
                }
            });
        }
    }, [handleErrorResponse, notFoundMessage, selectCompareStations, selectedPollutant, selectedRange, selectedStation, setError, t, units.pollutants, units.temperature]);

    const handleRefresh = useCallback(() => {
        setChartError(null);
        updateChartData();
    }, [updateChartData]);

    return (
        <CardLayout className={className} title={t("analyticScreen.scatterPlot.title")}
                    helpText={t("analyticScreen.scatterPlot.en_analytics_scatter_plot")}
                    refreshButtonEvent={handleRefresh} refreshButtonDisabled={loading}>
            <ChartLayout loading={loading} emptyData={chartData.length === 0} height={420}
                         chartStyles={{"& .modebar": {left: "42%"}}} period={period} onPeriodChange={setPeriod}
                         dateRange={dateRange} onDateRangeChange={setDateRange} legendItems={compareStations}
                         onLegendItemSelect={onItemSelected} error={error} sideContent={
                <ScatterPlotSideContent
                    loading={loading}
                    onPollutantSelect={onPollutantSelect}
                    selectedPollutant={selectedPollutant}
                    pollutantList={pollutantList}
                    handleStationChange={handleStationChange}
                    selectedStation={selectedStation}
                    stationOptions={stationOptions}
                    compareStations={compareStations}
                    onItemSelected={onItemSelected}
                />
            }>
                <ChartView
                    layout={plotLayout}
                    data={chartData}
                    config={plotConfig}
                />
            </ChartLayout>
            {chartData.length > 0 && !loading &&
                <div style={{display: "flex", marginLeft: 40, width: "80%", justifyContent: "center"}}>
                    <div style={{marginRight: 20, display: "flex"}}>
                        <div style={{
                            backgroundColor: "#949494",
                            borderRadius: "50%",
                            width: 6,
                            height: 6,
                            alignSelf: "center",
                            marginRight: 12
                        }}/>
                        <Typography variant="caption">{t("compareScreen.scatter.scatterChart")}</Typography>
                    </div>
                    <div style={{display: "flex"}}>
                        <div
                            style={{borderTop: "3px dotted #949494", width: 30, alignSelf: "center", marginRight: 12}}/>
                        <Typography variant="caption">{t("compareScreen.scatter.trendLine")}</Typography>
                    </div>
                </div>}
        </CardLayout>
    );
};


export default ScatterPlotCardView;
