import React, {useCallback, useEffect, useState} from 'react';
import {
    Backdrop,
    Card,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {getVariableName} from "../pollutantNames";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";
import {getStationAliasesMap} from "../../../../utils/stationUtil";
import {sections} from "./report_config_menu/SectionsStep";
import {periodTypeOptions} from "./autoReportControls/duration/AutoReportsDurationSelector";

const useStyles = makeStyles({
    card: {
        paddingTop: 16,
        paddingBottom: 10,
    },
    tittle: {
        marginLeft: 30,
        color: "#434343",
        marginBottom: 16
    },
    subTittle: {
        fontSize: 20,
        color: "#434343",
        marginBottom: 4
    },
    value: {
        fontSize: 18,
        color: "#a0a0a0",
        marginBottom: 32
    },
    buttons: {
        marginRight: 30,
        display: "flex",
        marginTop: 10,
        justifyContent: "flex-end"
    },
    divider: {
        marginBottom: 32
    },
    divider2: {
        marginTop: 8
    },
    popUpContainer: {
        width: 620
    },
    detailContainer: {
        paddingLeft: 20,
        maxWidth: 500,
        paddingBottom: 20
    },
    tableContainer: {
        marginTop: 20,
        height: 160,
        width: 220,
        borderRadius: 6,
        background: "#eaeaea",
    }
});

const ReportDetailPopUp = ({open, handleCloseDetailCallback, reportDetail}) => {

    const {t} = useTranslation();
    const stationAliases = useSelector(state => getStationAliasesMap(state.dashboardUI.stations), shallowEqual);
    const classes = useStyles();

    const [frequency, setFrequency] = useState("");
    const [period, setPeriod] = useState("");
    const [reportSections, setReportSections] = useState("");


    const getFrequencyDescription = useCallback(() => {

        if (!reportDetail) return "";

        const cronArray = reportDetail.cron.split(" ");

        if (cronArray[5] !== "*") {
            const days = {
                1: "monday",
                2: "tuesday",
                3: "wednesday",
                4: "thursday",
                5: "friday",
                6: "saturday",
                7: "sunday"
            };
            return `${t("reportScreen.reportConfig.every")} ${t(`reportScreen.reportConfig.${days[cronArray[5]]}`)}`;
        } else if (cronArray[4] !== "*")
            return `${t("reportScreen.reportConfig.every")} ${cronArray[4].split("/")[1]} ${t("reportScreen.reportConfig.months")}`;
        else
            return `${t("reportScreen.reportConfig.twiceAMonth")}`;

    }, [reportDetail, t]);

    const getReportSections = useCallback(() => {
        return reportDetail.reportSections.map(item => sections.find(item2 => item2.id === item).name).map(item => t(`reportScreen.reportConfig.${item}`)).join(", ");
    }, [reportDetail, t]);

    const getPeriod = useCallback(() => {
        const isDayPeriod = reportDetail.period.periodType === periodTypeOptions.dayPeriod;
        const periodType = isDayPeriod ? t("reportScreen.reportConfig.days") : t("reportScreen.reportConfig.months");
        return `${t("reportScreen.reportConfig.last")} ${reportDetail.period.value} ${periodType}`;
    }, [reportDetail, t]);

    useEffect(() => {
        if (reportDetail.autoReport) {
            setFrequency(getFrequencyDescription());
            setPeriod(getPeriod());
        }
        setReportSections(getReportSections());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportDetail]);

    console.log(reportDetail);

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={open}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center">

                    <Grid item xs={12}>
                        <Typography variant={"h6"}
                                    className={classes.tittle}>{t("reportScreen.popUp.title")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid container item xs={12} className={classes.detailContainer}>
                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("reportScreen.popUp.name")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{reportDetail.name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("reportScreen.popUp.reportTitle")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{reportDetail.title ?? "N/A"}</Typography>
                        </Grid>

                        {reportDetail.autoReport && <>
                            <Grid item xs={6}>
                                <Typography variant={"h5"}
                                            className={classes.subTittle}>{t("reportScreen.reportConfig.frequency")}</Typography>
                                <Typography variant={"subtitle1"} style={{paddingRight: 8}}
                                            className={classes.value}>{frequency}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h5"}
                                            className={classes.subTittle}>{t("reportScreen.reportConfig.period")}</Typography>
                                <Typography variant={"subtitle1"}
                                            className={classes.value}>{`${period} (${reportDetail.timeZone})`}</Typography>
                            </Grid>
                        </>
                        }
                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("units.concentration_units")}</Typography>

                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{reportDetail.units.pollutants === "eu" ?
                                "µg/m³ - mg/m³" : "ppb - ppm"}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("units.temperature_units")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{reportDetail.units.temperature === "celsius" ?
                                "Celsius (ºC)" : "Fahrenheit (ºF)"}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("reportScreen.reportConfig.sections")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{reportSections}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("downloadScreen.detailPopUp.stations")}</Typography>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader aria-label="event table">
                                    <TableBody className={classes.table}>
                                        {reportDetail.stations.map((id) => (
                                            <TableRow key={id}>
                                                <TableCell
                                                    align="left">{stationAliases[id]}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTittle}>{t("downloadScreen.detailPopUp.variables")}</Typography>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader aria-label="event table">
                                    <TableBody>
                                        {reportDetail.sensors.map((item) => (
                                            <TableRow key={item}>
                                                <TableCell align="left">{
                                                    getVariableName(item)
                                                }</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={handleCloseDetailCallback}>{t("ok")}</Button>
                        </div>
                    </Grid>

                </Grid>
            </Card>
        </Backdrop>
    );
};

export default ReportDetailPopUp;
