import React, {useCallback, useState} from 'react';
import {ADMIN_ROLE, ADMIN_ROLE_DISTRIBUTOR, VIEWER_ROLE} from "../UserRoles";
import {Backdrop, Grid, Typography, Button} from "@mui/material";
import UserRole from "../UserRole";
import {useTranslation} from "react-i18next";
import {editMembersRequest} from "../../../../../../requests/organization/organizationRequests";
import {DEFAULT_POPUP_WIDTH, ERROR_500} from "../../../../../../constants";
import useLoading from "../../../../../../hooks/useLoading";
import useMessage from "../../../../../../hooks/useMessage";
import useRequest from "../../../../../../hooks/useRequest";

const EditUserPopUp = ({user, closeCallback, sucessfullySent}) => {
    const {t} = useTranslation();
    const {setLoading} = useLoading();
    const {setError, setSuccess} = useMessage();
    const {handleErrorResponse} = useRequest();

    const initialState = {
        userType: user.role.find(item => item.id === ADMIN_ROLE_DISTRIBUTOR) ? ADMIN_ROLE_DISTRIBUTOR : user.role.find(item => item.id === ADMIN_ROLE) ? ADMIN_ROLE : VIEWER_ROLE,
        stations: user.stations || []
    };
    const [state, setState] = useState(initialState);
    const {userType, stations} = state;

    const handleUserChange =
        useCallback((event, newUserRole) => {
            if (newUserRole !== null) {
                setState(state => ({...state, userType: newUserRole}));
            }
        }, []);

    const handleSelectedStationChange = useCallback((newStations) => {
        setState((state) => ({...state, stations: newStations}));
    }, []);

    const handleUpdateUser = () => {
        const body = {userId: user.id, role: [userType], stations: userType !== VIEWER_ROLE ? [] : stations};

        setLoading(true);
        editMembersRequest(body, (error, data) => {
            setLoading(false);
            if (!error) {
                setSuccess("organizationMembersDialog.sucessfully_sent");
                sucessfullySent();
            } else {
                handleErrorResponse(data, response => {
                    switch (response.status) {
                        case 500:
                            closeCallback();
                            setError(ERROR_500);
                            break;
                        default:
                            closeCallback();
                            setError(response, false, t("organizationMembersDialog.unknown_error"));
                    }
                });
            }
        });

        closeCallback();
    };

    return (
        <Backdrop open={true} style={{zIndex: 1999}}>
            <Grid container style={{backgroundColor: "#fff", maxWidth: DEFAULT_POPUP_WIDTH, padding: 30}}>
                <Grid item xs={12}>
                    <Typography variant={"h5"}>{t("organizationEditPopUp.editMember")}</Typography>
                </Grid>
                <UserRole stations={stations} userType={userType}
                          handleSelectedStationChange={handleSelectedStationChange}
                          handleUserChange={handleUserChange}/>
                <Grid item container xs={12} style={{justifyContent: "flex-end"}}>
                    <Button
                        onClick={closeCallback} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button
                        disabled={(userType !== VIEWER_ROLE ? false : stations.length === 0)}
                        onClick={handleUpdateUser} color="primary">
                        {t("update")}
                    </Button>
                </Grid>
            </Grid>
        </Backdrop>
    );
};

export default EditUserPopUp;
