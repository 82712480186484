import React, {useCallback, useEffect, useState} from 'react';
import {CardContent, Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import HelpPopup from "../../../../common/HelpPopup";
import CalendarComponent from "./CalendarComponent";
import CalendarMonthSelector from "./CalendarMonthSelector";
import CalendarDaysTittle from "./CalendarDaysTittle";
import {predominantPollutantRequest} from "../../../../../requests/analytics/predominantPollutantRequest";
import {useSelector} from "react-redux";
import CardTitle from "../../../../common/card_views/CardTitle";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import CalendarLegend from "./CalendarLegend";
import {useTranslation} from "react-i18next";
import CardLayout from "../../../../common/card_views/CardLayout";
import useMessage from "../../../../../hooks/useMessage";
import useRequest from "../../../../../hooks/useRequest";

const useStyles = makeStyles({
    calendarTittle:{
        marginTop:8
    },
    title:{
        display:"flex",
        alignItems:"center"
    },
    dateSelector: {
        paddingRight: 20,
        justifyContent: "flex-end"
    }
});

const AQICalendarCardView = ({className}) => {

    const {t} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const { setError } = useMessage();
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const isMounted = useMountComponent();
    const { units } = useSelector( state => state.auth );
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const [{data,selectedDate,loading},updateState] =
        useState({data:[],loading:false,selectedDate: new Date(),error:false});


    const fetchAQIData = useCallback(() =>{
        updateState(state =>{
            return {...state,data:[],loading: true,error:false};
        });
        predominantPollutantRequest(selectedStation,selectedDate,units.index,(err,data) =>{
            if (!isMounted.current) {return;}
            if (!err){
               updateState(state =>{
                   return {...state,data,loading: false,error:false};
               });
            } else {
                updateState(state =>{
                    return {...state,data:[],loading: false,error:true};
                });
                handleErrorResponse(data, response => {
                    switch (response.status) {
                        case 404:
                            break;
                        case 400:
                            setError("analyticScreen.calendar.could_not_update_calendar_invalid_date");
                            break;
                        default:
                            setError(data, false, "analyticScreen.calendar.could_not_update");
                    }
                });
            }
        });
    },[units.index, isMounted, setError, handleErrorResponse, selectedStation, selectedDate]);

    useEffect(()=>{
        fetchAQIData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedDate,selectedStation]);

    const handleDateChange  = useCallback((value)=>{
        updateState( state =>{
            return {...state,selectedDate:value};
        });
    },[]);

    const classes = useStyles();

    return (
        <CardLayout className={className} loading={loading}>
            <Grid container item xs={12} spacing={4}>
                <Grid item xs={6} lg={7}>
                    <div className={classes.title}>
                        <CardTitle title={`${units.index === "AQHI-HK" ? "AQHI" : units.index} - ${t("analyticScreen.calendar.predominant_pollutant")}`}
                                   setAnchorEl={setAnchorEl}/>
                    </div>
                </Grid>
                <Grid item xs={6} lg={5} container className={classes.dateSelector}>
                    <CalendarMonthSelector
                        date={selectedDate}
                        handleDateChange={handleDateChange}/>
                </Grid>
            </Grid>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} className={classes.calendarTittle}>
                        <CalendarDaysTittle/>
                    </Grid>
                    <Grid item xs={12}>
                        <CalendarComponent data={data} date={selectedDate} indexType={units.index}/>
                    </Grid>
                    <Grid item xs={12}>
                        <CalendarLegend indexType={units.index}/>
                    </Grid>
                </Grid>
            </CardContent>
            <HelpPopup anchorEl={anchorEl}
                       handleHelpClose={handleHelpClose}
                       message={t("analyticScreen.calendar.en_analytics_calendar")}
                       halfCard={true}/>
        </CardLayout>
    );
};

export default AQICalendarCardView;
