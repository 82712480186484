import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, Grid, Link, Typography} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {DataGrid} from "@mui/x-data-grid";
import {
    deleteMemberRequest,
    organizationRequest,
    resendInvitationRequest, unblockUserRequest
} from "../../../../../requests/organization/organizationRequests";
import useRequest from "../../../../../hooks/useRequest";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import clsx from "clsx";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {makeStyles} from "@mui/styles";
import InviteDialog from "./InviteDialog";
import ConfirmDialog from "../../../../common/ConfirmDialog";
import CardViewLoading from "../../../../common/chart/ChartLoading";
import DataNotFound from "../../../../common/DataNotFound";
import useMessage from "../../../../../hooks/useMessage";
import {useTranslation} from "react-i18next";
import EditUserPopUp from "./edit/EditUserPopUp";
import useAuth from "../../../../../hooks/useAuth";
import {WRITE_ORGANIZATION_USERS} from "./UserPermissions";
import {useSelector} from "react-redux";
import {ADMIN_ROLE_DISTRIBUTOR} from "./UserRoles";

const initialState = {
    editUser:null,
    error:"",
    loading:true,
    runningAction:false,
    enableDelete:false,selectionModel:[],
    members: [],organization:'',
    showInviteDialog:false,
    openConfirmDialog:false
};

const useStyles = makeStyles({
    currentUser: {
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {
        },
    },
    deleteButton:{
        color:"#ffffff",
        background:"#f6343d",
        '&:hover': {
            background:"#81191c"
        },
    },
    subtitle:{
        color:"#8a8a8a"
    },
    organizationName:{
        fontWeight:"bold"
    },
    rowTable:{
        display:"flex",
        width:"100%vw",
        justifyContent:"center",
        margin:0,
    },
    table:{
        maxWidth:1600,
        height: 480,
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {
        },
    }
});

const OrganizationMembers = ({className}) => {

    const { t } = useTranslation();
    const { handleErrorResponse } = useRequest();
    const isMounted = useMountComponent();
    const { id, hasPermission } = useAuth();
    const {role} = useSelector((state) => state.auth);
    const { setError, setSuccess } = useMessage();
    const isDistributorOrganization = useSelector(state => state.auth.organization)?.isDistributor === true;
    const hasDistributionPermission = useMemo(() => {
        return role.find(item => item.id === ADMIN_ROLE_DISTRIBUTOR) !== undefined;
    }, [role]);


    const [{error, loading, runningAction, enableDelete, selectionModel, members, organization,
        showInviteDialog, editUser, openConfirmDialog}, updateState] = useState(initialState);

    const classes = useStyles();

    const updateData = useCallback(()=>{
        updateState(initialState);
        organizationRequest((error,data)=> {
            if (!isMounted.current) {return;}
            if (!error) {
                updateState(state => {
                    return {...state, loading:false,organization: data.name,
                        members: data.users,selectionModel:[],enableDelete: false };
                });
            } else {
                handleErrorResponse(data, response => {
                    updateState(state => {
                        return {...state, loading: false, error: t("organizationMembers.could_not_fetch_members")};
                    });
                    setError(response,
                        false,
                        "organizationMembers.could_not_fetch_members");
                });
            }
        });
    },[t, setError, handleErrorResponse, isMounted]);

    const closeCallback = useCallback(()=>{
        updateState(state =>{
            return{...state,editUser:null};
        });
    },[]);

    const setInviteDialogState = useCallback((showDialog)=>{
        updateState(state =>{
            return{...state,showInviteDialog:showDialog};
        });
    },[updateState]);

    const hideDialog = useCallback(()=>{
        updateState(state =>{
            return {...state,openConfirmDialog: false};
        });
    },[]);

    const onSelectionModelChange = (newSelectionModel) => {
        updateState((state) => {
            let editableItems =
                !hasPermission(WRITE_ORGANIZATION_USERS) ? [] : (newSelectionModel.filter((userId) => userId !== id));

            if(!hasDistributionPermission)
                editableItems = editableItems.filter((userId) => !members.find(user => user.id === userId).role.find(item => item.id === ADMIN_ROLE_DISTRIBUTOR));

            return { ...state, enableDelete: editableItems.length !== 0, selectionModel: editableItems };
        });
    };

    const handleInvitation = ()=>{
        setInviteDialogState(true);
    };


    const sucessfullySentCallback = useCallback(()=>{
        updateData();
    },[updateData]);


    useEffect(()=> {
        updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const confirmDelete = useCallback(()=>{
        updateState(state => ({...state, runningAction: true, openConfirmDialog: false}));
        Promise.all(deleteMemberRequest(selectionModel)).then(() => {
            if (!isMounted.current) {return;}
            updateData();
            setSuccess("organizationMembers.sucessfully_deleted");
        }).catch(err => {
            if (!isMounted.current) {return;}
            if(err.response){
                updateState(state => ({...state, runningAction: false}));
                handleErrorResponse(err.response, response => {
                    setError(response,
                        false,
                        "organizationMembers.error_deleting");
                });
            }else {
                setError("organizationMembers.error_deleting");
            }
        });
    },[isMounted, handleErrorResponse, setError, setSuccess, selectionModel, updateData]);

    const handleDelete = ()=>{
        updateState(state =>{
            return {...state,openConfirmDialog: true};
        });
    };

    const handleResend = useCallback((id)=>{
        updateState(state => ({...state, runningAction: true}));
        resendInvitationRequest({userId:id},(error,data)=>{
            if (!isMounted.current) {return;}
            updateState(state => ({...state, runningAction: false}));
            if (!error) {
                setSuccess("organizationMembers.invitation_sent");
            } else {
                handleErrorResponse(data, response => {
                    switch (response.status) {
                        case 403:
                            setError("organizationMembers.error_user_not_exist");
                            break;
                        default:
                            setError(response,
                                false,
                                "organizationMembers.error_sending_invitation");
                    }
                });
            }
        });
    },[handleErrorResponse, isMounted, setError, setSuccess]);

    const handleUnblockUser = useCallback(async (id)=>{
        updateState(state => ({...state, runningAction: true}));
        try {
            await unblockUserRequest(id);
            updateState(state => ({...state, runningAction: false}));
            updateData();
            setSuccess("organizationMembers.sucessfully_unblocked");
        } catch (e) {
            updateState(state => ({...state, runningAction: false}));
            handleErrorResponse(e.response, response => {
                switch (response?.status) {
                    case 404:
                        setError("organizationMembers.error_user_not_exist");
                        break;
                    default:
                        setError(response,
                            false,
                            "organizationMembers.error_unblocking_user");
                }
            });
        }
    },[handleErrorResponse, setError, setSuccess, updateData]);

    const handleUpdateUserConfig = useCallback((userId)=>{
        updateState(state => ({...state,editUser: members.find(user => user.id === userId) }));
    },[members]);

    const columns = useMemo(() => [
        { field: 'id', headerName: 'ID',  hide:true },
        { field: "name", headerName: t("organizationMembers.name") , width: 240,
            cellClassName: (params) =>{
                const includeDistributorPermission = params.row.role.find(item => item.id === ADMIN_ROLE_DISTRIBUTOR) !== undefined;
                return clsx('super-app', {
                    current_user: params.row.id === id || (includeDistributorPermission && !hasDistributionPermission),
                    other_user: params.row.id !== id && !(includeDistributorPermission && !hasDistributionPermission),
                });
            },
            renderCell: (params) => (
                <Typography>{ params.value ? params.value: "--"}</Typography>
            )
        },
        { field: 'lastName', headerName: t("organizationMembers.last_name"),width: 200,
            cellClassName: (params) =>{
                const includeDistributorPermission = params.row.role.find(item => item.id === ADMIN_ROLE_DISTRIBUTOR) !== undefined;
                return clsx('super-app', {
                    current_user: params.row.id === id || (includeDistributorPermission && !hasDistributionPermission),
                    other_user: params.row.id !== id && !(includeDistributorPermission && !hasDistributionPermission),
                });
            },
            renderCell: (params) => (
                <Typography>{ params.value ? params.value: "--"}</Typography>
            )
        },
        {
            field: 'mail',
            headerName: t("organizationMembers.email"),
            width: 280,
            cellClassName: (params) =>{
                const includeDistributorPermission = params.row.role.find(item => item.id === ADMIN_ROLE_DISTRIBUTOR) !== undefined;
                return clsx('super-app', {
                    current_user: params.row.id === id || (includeDistributorPermission && !hasDistributionPermission),
                    other_user: params.row.id !== id && !(includeDistributorPermission && !hasDistributionPermission),
                });
            }
        },
        {
            field: 'role',
            headerName: t("organizationMembers.role"),
            width: isDistributorOrganization ? 230 : 90,
            cellClassName: (params) =>{
                const includeDistributorPermission = params.row.role.find(item => item.id === ADMIN_ROLE_DISTRIBUTOR) !== undefined;
                return clsx('super-app', {
                    current_user: params.row.id === id || (includeDistributorPermission && !hasDistributionPermission),
                    other_user: params.row.id !== id && !(includeDistributorPermission && !hasDistributionPermission),
                });
            },
            renderCell: (params) => {
                const roleString = params.value.map((item) => t(`organizationMembersDialog.${item.id}`)).join(", ");
                const includeDistributorPermission = params.value.find(item => item.id === ADMIN_ROLE_DISTRIBUTOR) !== undefined;
                return ( params.row.id !== id && hasPermission(WRITE_ORGANIZATION_USERS) && !(includeDistributorPermission && !hasDistributionPermission)
                        ? <Link component="button" underline="always" onClick={(event) => {
                            event.stopPropagation();
                            handleUpdateUserConfig(params.row.id);
                        }}>{roleString}</Link>
                        : <Typography>{roleString}</Typography>
                );
            }
        },
        {
            field: "status",
            headerName: t("organizationMembers.status"),
            width: 200,
            cellClassName: (params) =>{
                const includeDistributorPermission = params.row.role.find(item => item.id === ADMIN_ROLE_DISTRIBUTOR) !== undefined;
                return clsx('super-app', {
                    current_user: params.row.id === id || (includeDistributorPermission && !hasDistributionPermission),
                    other_user: params.row.id !== id && !(includeDistributorPermission && !hasDistributionPermission),
                });
            },
            renderCell: (params) => (
                <Typography>
                    {
                        params.row.registered !== true ?
                            t("organizationMembers.pending_registration")
                            : params.row.blocked
                                ? t("organizationMembers.blocked")
                                : t("organizationMembers.active")
                    }
                </Typography>
            )
        },
        { field: 'registered', headerName: ' ', width: 200,
            sortable: false
            ,
            renderCell: (params) => {
                if (hasPermission(WRITE_ORGANIZATION_USERS)) {
                    if (params.row.blocked === true) {
                        return (
                            <Button
                                fullWidth
                                variant="text"
                                color="primary"
                                onClick={(event)=>{
                                    event.stopPropagation();
                                    handleUnblockUser(params.row.id);
                                }}
                                className={classes.button}
                                endIcon={<LockOpenIcon/>}
                            >
                                {t("organizationMembers.reactivate")}
                            </Button>
                        );
                    }
                    if (params.row.registered !== true) {
                        return (
                            <Button
                                fullWidth
                                variant="text"
                                color="primary"
                                onClick={(event)=>{
                                    event.stopPropagation();
                                    handleResend( params.row.id);
                                }}
                                className={classes.button}
                                endIcon={<SendRoundedIcon/>}
                            >
                                { t("organizationMembers.resend_invitation")}
                            </Button>
                        );
                    }
                }
                return <Typography/>;
            }
        }
    ], [classes.button, handleResend, handleUnblockUser, handleUpdateUserConfig, hasDistributionPermission, hasPermission, id, isDistributorOrganization, t]);



    return (
        <>
        <Card className={className}  >
            {loading && <CardViewLoading/>}
            {!loading && error === "" && <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={4} lg={6} >
                    <Typography className={classes.organizationName} variant={"h4"}>{organization}</Typography>
                    <Typography className={classes.subtitle} variant={"h6"}>{t("organizationMembers.memberList")}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3} >
                    {hasPermission(WRITE_ORGANIZATION_USERS) && <Button
                        data-testid={"organization-member-add"}
                        fullWidth
                        disabled={runningAction}
                        onClick={handleInvitation}
                        color={"primary"}
                        variant="contained"
                        startIcon={<SendIcon />}
                    >
                        { t("organizationMembers.invite_new_member")}
                    </Button>}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    {hasPermission(WRITE_ORGANIZATION_USERS) && <Button
                        data-testid={"organization-member-delete"}
                        fullWidth
                        disabled={!enableDelete || runningAction}
                        onClick={handleDelete}
                        variant="contained"
                        className={classes.deleteButton}
                        startIcon={<DeleteIcon />}
                    >
                        { t("delete")}
                    </Button>}
                </Grid>

                <Grid item xs={12} className={classes.rowTable} >
                    <DataGrid
                        loading={runningAction}
                        className={classes.table}
                        rows={members}
                        columns={columns.map((column) => {
                                return {
                                    ...column, disableClickEventBubbling:column.field === "registered" || column.field === "role"
                                };
                            }
                        )}
                        checkboxSelection={hasPermission(WRITE_ORGANIZATION_USERS)}
                        columnBuffer = {8}
                        pageSize={7}
                        rowsPerPageOptions={[7]}
                        Name="dataGrid1"
                        selectionModel={selectionModel}
                        onSelectionModelChange={onSelectionModelChange}
                        density={"standard"}
                    />
                </Grid>
            </Grid>}
            {!loading && error !== "" && <DataNotFound message={error}/>}
        </Card>
            {showInviteDialog && <InviteDialog sucessfullySent={sucessfullySentCallback}
                                               showInviteDialog = {showInviteDialog}
                                               setInviteDialogState = {setInviteDialogState}/>}
            { openConfirmDialog && <ConfirmDialog hideDialog={hideDialog} title={t("organizationMembers.delete_member")}
                                                  question={t("organizationMembers.are_you_sure_to_delete")}
                                                  confirm={confirmDelete} />}
            {editUser !== null && <EditUserPopUp user={editUser} closeCallback={closeCallback}
                                                 sucessfullySent={sucessfullySentCallback}/>}
        </>
    );
};

export default OrganizationMembers;
