import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getVariableName} from "../../pollutantNames";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableContainer: {
        maxHeight: 400
    }
});

const OmitSensors = ({selectedStations, handleSensorChange}) => {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("organizationPublic.station")}</TableCell>
                            <TableCell>{t("organizationPublic.sensors")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedStations.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={row.sensors.filter(item => !row.omitSensors.includes(item))}
                                    onChange={ event => {
                                        handleSensorChange(row.id, event.target.value);
                                    }
                                    }>
                                    {row.sensors.map((sensor) =>
                                        (<ToggleButton key={sensor} value={sensor}>
                                            {getVariableName(sensor)}
                                        </ToggleButton>))
                                    };
                                </ToggleButtonGroup>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    );
};

export default OmitSensors;
