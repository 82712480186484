import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useMessage from "../../../../../hooks/useMessage";
import {useSelector} from "react-redux";
import {DEFAULT_PERIOD} from "../../../../../constants";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {compareAmbientNoiseRequest} from "../../../../../requests/compare/compareAmbienNoiseRequest";
import {compareStationsColors} from "../CompareScreen";
import {sortBySelection} from "../../../../../utils/sortComparedPollutants";
import {useTranslation} from "react-i18next";
import {isEqual} from "lodash";
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";
import ChartView from "../../../../common/chart/ChartView";
import {areAllSameTimezone, getCommonTimezone, getStationTimezones} from "../timezoneUtil";
import useRequest from "../../../../../hooks/useRequest";
import {AUTO_RESOLUTION} from "../../analytics/common/ResolutionSelector";


const initialPlotLayout = {
    autosize: true,
    showlegend: false,
    legend:{x: -0.25, y: 0.9},
    yaxis: {
        title: 'dBA',
        range:[0,120]
    },
    xaxis:{showgrid:false,domain: [0.01, 1]},
    hovermode: "x unified",
    margin: {l: 60, r: 50, b: 20, t: 20}
};

const plotConfig = {
    modeBarButtonsToRemove:[ "select2d", "lasso2d",
        "toggleHover", "sendDataToCloud", "toggleSpikelines","hoverCompareCartesian",
        "hoverClosestCartesian"
    ],
    displaylogo : false
};

const CompareAmbientNoiseCardView = ({className}) => {

    const {t} = useTranslation();
    const notFoundMessage = useMemo(() => {
        return t("dataNotFound");
    }, [t]);
    const {handleErrorResponse} = useRequest();
    const initialState = {data: [], hiddenVariables: [], loading: true, error: notFoundMessage};
    const [{data, hiddenVariables, loading, error}, updateState] = useState(initialState);
    const [plotLayout, setPlotLayout] = useState(initialPlotLayout);
    const {setError} = useMessage();
    const isMounted = useMountComponent();
    const selectCompareStations = useSelector(state => state.dashboardUI.selectCompareStations, isEqual);
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [dateRange, setDateRange] = useState(null);
    const selectedRange = useMemo(() => getDateRange(period, dateRange), [period, dateRange]);
    const dataTimeZone = useSelector(state => state.auth.timeZone);
    const [timeZone, setTimeZone] = useState(dataTimeZone);
    const [selectedResolution, updateSelectedResolution] = useState(AUTO_RESOLUTION);

    const resolutionOptions = useMemo(() => [
        {value: AUTO_RESOLUTION, label: t("resolutionSelector.auto")},
        {value: 5, label: t("resolutionSelector.5min")},
        {value: 10, label: t("resolutionSelector.10min")},
        {value: 60, label: t("resolutionSelector.1h")}
    ], [t]);

    const commonTimezone = useMemo(() => (
        getCommonTimezone(selectCompareStations, selectedRange[0], timeZone)
    ), [selectedRange, timeZone, selectCompareStations]);

    useEffect(()=>{
            updateData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[selectCompareStations, selectedRange, timeZone, selectedResolution]);

    const handleRefresh = ()=>{
        updateData(null);
    };

    const getChartData = useCallback( (serverData, hiddenStations)=>{

            let currentHiddenStations = hiddenStations !== null ? hiddenStations : hiddenVariables !== null ? hiddenVariables :[];
            let dataArray = Object.entries(serverData);
            let output = [];
            let sortedDataArray = sortBySelection(dataArray, selectCompareStations);
            const timezones = getStationTimezones(selectCompareStations, selectedRange[0], timeZone);
            const sameTimezone = areAllSameTimezone(timezones);

            sortedDataArray.forEach((station,stationIndex) => {
                let stationName = selectCompareStations.find(item => item.id === station[0])?.alias;
                const timezone = timezones[stationIndex];
                const timezoneString = sameTimezone ? "" : ` (UTC${timezone > 0 ? `+${timezone}` : timezone || ""})`;
                output.push( {
                    visible:currentHiddenStations.includes(stationIndex)? "legendonly":true,
                    marker: {color:compareStationsColors[stationIndex]},
                    name:stationName ,
                    type: 'scatter',
                    y: station[1].data.y !== undefined ? station[1].data.y.map(yValue =>{
                        return yValue;
                    }):[],
                    x:station[1].data.x,
                    hovertemplate: `<b>${stationName}${timezoneString}</b> - %{y} dBA`,
                    hoverlabel:{namelength:0}
                });
            });

        return output;
    },[selectCompareStations, hiddenVariables, timeZone, selectedRange]);

    const updateData = useCallback((hiddenStations)=>{

        updateState(state =>{
            return {...state,
                data:[],
                rawData: {},
                loading: true,
                error: "",
                hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables
                };
        });

        compareAmbientNoiseRequest(
            {
                stations: selectCompareStations.map(item=> item.id),
                time: selectedRange[0],
                endtime: selectedRange[1],
                dataTimeZone: timeZone,
                resolution: selectedResolution
            }, (err,data)=>{

                if (!isMounted.current) {return;}
                if(!err){
                    if(Object.values(data).every(item => item.length === 0)){
                        updateState(state => {
                            return {...state, loading: false, error: notFoundMessage};
                        });
                        return;
                    }

                    let chartData = getChartData(data,hiddenStations);
                    updateState(state =>{
                        return {...state,data:chartData,rawData:data, loading: false, hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables};
                    });
                }
                else {
                    if(data.status === 404){
                        updateState(state =>{
                            return {...state,loading: false};
                        });
                    }
                    else {
                        updateState(state =>{
                            return {...state,loading: false,error:data.status};
                        });
                        handleErrorResponse(data, response => {
                            setError(response, false,
                                "compareScreen.ambientNoise.could_not_update");
                        });
                    }
                }
            });
    },[selectCompareStations, selectedRange, timeZone, selectedResolution, isMounted, getChartData, notFoundMessage, handleErrorResponse, setError]);

    useEffect(() => {
        setPlotLayout({
            ...initialPlotLayout,
            xaxis: {
                ...initialPlotLayout.xaxis,
                hoverformat: `%-d %b, %Y %H:%M:%S${commonTimezone}`
            }
        });
    }, [commonTimezone]);

    const legendItems = data.map((item, index) => ({
        id: index,
        label: item.name,
        color: item.marker.color,
        isHidden: item.visible === "legendonly"
    }));

    const onLegendItemSelect = useCallback((index) => {
        const newHiddenVariables = [...hiddenVariables];
        const position = newHiddenVariables.indexOf(index);
        if (position === -1) {
            newHiddenVariables.push(index);
        } else {
            newHiddenVariables.splice(position, 1);
        }
        const newData = data.map((item, itemIndex) => ({
            ...item,
            visible: newHiddenVariables.includes(itemIndex) ? "legendonly" : true
        }));
        const error = newData.length === newHiddenVariables.length
            ? t("compareScreen.noStationSelected")
            : "";
        updateState(state => ({...state, data: newData, hiddenVariables: newHiddenVariables, error}));
    }, [data, hiddenVariables, t]);

    const handleTimeZoneChange = (event, newValue) => {
        setTimeZone(newValue);
    };

    const setSelectedResolution = useCallback((event) => {
        updateSelectedResolution(event.target.value);
    }, [updateSelectedResolution]);

    return (
        <CardLayout className={className} title={t("compareScreen.ambientNoise.title")}
                    helpText={t("compareScreen.ambientNoise.en_compare_ambient_noise")}
                    refreshButtonEvent={handleRefresh} refreshButtonDisabled={loading}>
            <ChartLayout loading={loading} error={error} emptyData={data.length === hiddenVariables.length} height={510}
                         chartStyles={{ "& .modebar": { left: "47%" } }} timeZone={timeZone}
                         onTimeZoneChange={handleTimeZoneChange} period={period} onPeriodChange={setPeriod}
                         dateRange={dateRange} onDateRangeChange={setDateRange} legendItems={legendItems}
                         onResolutionChange={setSelectedResolution} resolution={selectedResolution}
                         resolutionOptions={resolutionOptions}
                         onLegendItemSelect={onLegendItemSelect} position="end">
                <ChartView
                    layout={plotLayout}
                    data={data}
                    config={plotConfig}
                />
            </ChartLayout>
        </CardLayout>
    );
};

export default CompareAmbientNoiseCardView;
