import {getVariableName} from "../components/routes/private/pollutantNames";
import {sensor} from "../constants";
import getItemSelectorFilter from "./getItemSelectorFilter";

const sensorTypes = Object.values(sensor);

export const sortBySensorType = (dataArray, typeGetter) => {
    return dataArray.sort((a, b) => sensorTypes.indexOf(typeGetter(a)) - sensorTypes.indexOf(typeGetter(b)));
};

export const getAvailableSensors = (stationList) => {
    const output = [];
    stationList.forEach(station => {
        station.sensors?.forEach((sensorId) => {
            const sensorEntry = output.find((item) => item.id === sensorId);
            if (sensorEntry === undefined && sensorTypes.includes(sensorId)) {
                output.push({id: sensorId, alias: getVariableName(sensorId)});
            }
        });
    });

    return sortBySensorType(output, (sensor) => sensor.id);
};

export const getStationAliasesMap = (stationList) => {
    const aliasesMap = {};
    stationList?.forEach(station => {
        aliasesMap[station.id] = station.alias;
    });
    return aliasesMap;
};

export const mapStationToIdAlias = (station) => {
    return {
        id: station.id,
        alias: station.alias
    };
};

export const mapStationsToIdAlias = (stationList) => {
    return stationList?.map(mapStationToIdAlias);
};

export const getStationOptions = (stationList, extraOptions = undefined) => {
    return stationList?.map(station => {
        const output = {
            ...mapStationToIdAlias(station),
            label: station.alias,
            serial: station.serial,
            state: station.state,
            isStation: true,
            position: station.position
        };
        if(extraOptions)
            extraOptions.forEach((option) => {
                output[option] = station[option];
            });

        return output;
    });
};

export const STATION_SEARCHABLE_FIELDS = ["id", "label", "serial"];

export const stationSelectorFilter = getItemSelectorFilter(STATION_SEARCHABLE_FIELDS);
