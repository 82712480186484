import SensorDataChart from "./SensorDataChart";
import {useCallback, useState} from "react";
import {initialState} from "./initialConfig";
import {sensorDataRequest} from "../../../../../requests/analytics/sensorDataRequest";
import useRequest from "../../../../../hooks/useRequest";
import useMessage from "../../../../../hooks/useMessage";
import {useSelector} from "react-redux";
import CardLayout from "../../../../common/card_views/CardLayout";
import {useTranslation} from "react-i18next";
import {timeZoneOptions} from "../../../../../constants";
import {AUTO_RESOLUTION} from "../common/ResolutionSelector";

const SensorDataCardView = ({className}) => {
    const {t} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const {setError, setInfo} = useMessage();
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const selectedStationData = useSelector(state => {
        return state.dashboardUI.stations.find(station => station.id === selectedStation);
    });
    const {units, timeZone: dataTimeZone} = useSelector(state => state.auth);
    const [selectedPeriod, setSelectedPeriod] = useState();
    const [selectedResolution, updateSelectedResolution] = useState(AUTO_RESOLUTION);

    const [selectedTimeZone, setSelectedTimeZone] =
        useState(selectedStationData.position?.locationInfo ? dataTimeZone : timeZoneOptions.UTC);
    const [rawData, setRawData] = useState(initialState.rawData);
    const [loading, setLoading] = useState(initialState.loading);
    const [requestError, setRequestError] = useState(initialState.error);
    const [selectedSensors, setSelectedSensors] = useState([]);

    const updateData = useCallback(() => {

        if (!selectedPeriod) return;

        setLoading(true);
        setRequestError(initialState.error);
        setRawData(initialState.rawData);

        sensorDataRequest({
            temp: units.temperature,
            pollutantUnit: units.pollutants,
            stationId: selectedStation,
            init: selectedPeriod[0],
            end: selectedPeriod[1],
            dataTimeZone: selectedTimeZone,
            resolution:selectedResolution
        }, (err, data) => {
            setLoading(false);
            if (!err) {
                setRawData(data);
            } else {
                if (data.status === 404) {
                    setInfo("analyticScreen.sensorData.sensor_data_not_found");
                } else {
                    setRequestError(data.status);
                    handleErrorResponse(data, response => {
                        setError(response,
                            false,
                            "analyticScreen.sensorData.could_not_update_station_data");
                    });
                }
                setRawData(null);
            }
        });
    }, [selectedPeriod, units.temperature, units.pollutants, selectedStation, selectedTimeZone, selectedResolution, setInfo, handleErrorResponse, setError]);

    const setSelectedResolution = useCallback((event) => {
        updateSelectedResolution(event.target.value);
    }, [updateSelectedResolution]);

    return (
        <CardLayout className={className} title={t("analyticScreen.sensorData.station_data")}
                    helpText={t("analyticScreen.sensorData.en_analytics_stationData")}
                    refreshButtonEvent={updateData} refreshButtonDisabled={loading}>
            <SensorDataChart height={710} loading={loading} rawData={rawData} dataTimeZone={selectedTimeZone}
                             setDataTimeZone={selectedStationData.position?.locationInfo && setSelectedTimeZone}
                             selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod}
                             selectedSensors={selectedSensors} setSelectedSensors={setSelectedSensors}
                             requestError={requestError} updateData={updateData}
                             selectedResolution={selectedResolution}
                             setSelectedResolution={setSelectedResolution}

            />
        </CardLayout>
    );
};

export default SensorDataCardView;
