import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {ERROR_500} from "../constants";
import useMessage from "./useMessage";
import useRequest from "./useRequest";

const useTableQuery = (requestFn, {pageSize = 10, translationBaseRoute}) => {
    const {handleErrorResponse} = useRequest();
    const {t} = useTranslation();
    const {setError} = useMessage();
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({items: []});
    const [sortModel, setSortModel] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const returnEmpty = () => {
        setRows([]);
        setRowCount(0);
        setPage(0);
        setLoadingData(false);
    };

    const reloadData = useCallback(() => {
        setLoadingData(true);
        const skip = page * pageSize;
        const filterParams = {};
        for (const item of filterModel.items) {
            if (item.value !== undefined) {
                switch (item.operatorValue) {
                    case "equals":
                        if (item.value === null) {
                            return returnEmpty();
                        }
                        if (item.value !== "") {
                            filterParams[item.columnField] = item.value;
                        }
                        break;
                    case "isAnyOf":
                        filterParams[item.columnField] = item.value?.split(",");
                        break;
                }
            }
        }
        const sortParams = {};
        if (sortModel[0]) {
            sortParams.order = sortModel[0].sort;
            sortParams.sortBy = sortModel[0].field;
        }
        requestFn({limit: pageSize, skip, ...filterParams, ...sortParams}).then((data) => {
            if (data?.count !== undefined) {
                setRowCount(data.count);
                // If the selected page exceeds the number of pages, go to the last page
                if (page * pageSize >= data.count) {
                    const lastPage = Math.max(Math.ceil(data.count / pageSize) - 1, 0);
                    setPage(lastPage);
                }
                // If the selected page is less or equal than the number of pages, update the rows
                if (page * pageSize < data.count || data.count === 0) {
                    setRows(data.data);
                }
            }
        }).catch((err) => {
            handleErrorResponse(err.request, response => {
                switch (response.status) {
                    case 500:
                        setError(ERROR_500, true);
                        break;
                    default:
                        const errorTranslationRoute = `${translationBaseRoute ?? 'common'}.could_not_update_data`;
                        setError(response, false, t(errorTranslationRoute));
                }
            });
        }).finally(() => {
            setLoadingData(false);
        });
    },[requestFn, t, handleErrorResponse, setError, page, pageSize, filterModel, sortModel, translationBaseRoute]);

    return {
        rows, rowCount, page, setPage, filterModel, setFilterModel, sortModel, setSortModel, loadingData, reloadData
    };
};

export default useTableQuery;