import React, {useCallback, useEffect, useState} from 'react';
import {Card, Grid, Link, Tab, Tabs, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import useRequest from "../../../../../hooks/useRequest";
import {appModules, LOGS_ROUTE, MODEL_EXTERNAL_PREFIX} from "../../../../../constants";
import {shallowEqual, useSelector} from "react-redux";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import CardLoading from "../../../../common/card_views/CardLoading";
import {useTranslation} from "react-i18next";
import useMessage from "../../../../../hooks/useMessage";
import getLogRequest from "../../../../../requests/logs/getLogRequest";
import {useHistory} from "react-router-dom";
import LogTabView from "./LogTabView";
import BatteryTabView from "./BatteryTabView";
import useModules from "../../../../../hooks/useModules";

const useStyles = makeStyles({
    cardTitle: {
        marginTop: 8,
        marginLeft: 20,
        color: "gray"
    },
    cardLink: {
        marginRight: 20,
        fontSize: "1rem"
    },
    table: {
        paddingTop: 10,
        paddingRight: 15,
        paddingLeft: 15,
        paddingBottom: 0
    }
});

const TAB_EVENTS = "events";
const TAB_BATTERY = "battery";

const DetailCardView = ({className}) => {
    const classes = useStyles();
    const {hasModule} = useModules();
    const {t} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const history = useHistory();
    const isMounted = useMountComponent();
    const {selectedStation, isExternal} = useSelector(state => {
        const selectedStation = state.dashboardUI.selectedStation;
        const station = state.dashboardUI.stations.find(({id}) => selectedStation === id);
        const isExternal = station?.model?.startsWith(MODEL_EXTERNAL_PREFIX);
        return {selectedStation, isExternal};
    }, shallowEqual);
    const {setError} = useMessage();

    const [{log, loading}, updateState] = useState({log: [], loading: false});
    const [tab, setTab] = useState(hasModule(appModules.cmms) ? TAB_EVENTS : TAB_BATTERY);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        updateLog();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStation]);

    const updateLog = useCallback(() => {
        if(!hasModule(appModules.cmms)) return;

        updateState(state => {
            return {...state, loading: true};
        });
        getLogRequest({station: selectedStation, limit: 6}).then((data) => {
            if (!isMounted.current) {
                return;
            }
            const list = data?.data.map((item, index) => {
                return {date: new Date(item.date), value: item.description, id: index};
            });
            updateState(state => {
                return {...state, log: list, loading: false};
            });
        }).catch((err) => {
            updateState(state => {
                return {...state, loading: false};
            });
            handleErrorResponse(err.request, response => {
                setError(response, false, t("analyticScreen.deviceLog.could_not_update_log"));
            });
        });
    }, [hasModule, selectedStation, isMounted, handleErrorResponse, setError, t]);

    const onClickDetail = () => {
        history.push(`${LOGS_ROUTE}/${selectedStation}`);
    };

    return (
        <Card className={className}>
            {!loading &&
                <Grid container>
                    <Grid container item xs={12} alignItems={"center"} justifyContent={"space-between"}>
                        <Tabs value={tab} onChange={handleTabChange} className={classes.cardTitle}
                              aria-label="device detail tabs">
                            {hasModule(appModules.cmms) &&
                                <Tab label={t("analyticScreen.deviceLog.sensor_log")} value={TAB_EVENTS}/>}
                            {(!isExternal || (isExternal && !hasModule(appModules.cmms))) && <Tab label={t("analyticScreen.deviceDescription.battery_level")} value={TAB_BATTERY}/>}
                        </Tabs>
                        {tab === TAB_EVENTS && (
                            <Typography variant="h6">
                                <Link className={classes.cardLink} component="button" underline="hover"
                                      onClick={onClickDetail}>
                                    {t("downloadScreen.view_detail")}
                                </Link>
                            </Typography>
                        )}
                    </Grid>
                    {tab === TAB_EVENTS && (
                        <LogTabView className={classes.table} log={log}/>
                    )}
                    {tab === TAB_BATTERY && isExternal === false && (
                        <BatteryTabView station={selectedStation}/>
                    )}
                </Grid>}
            {loading && <CardLoading/>}
        </Card>
    );
};

export default DetailCardView;
