import React, {useMemo} from 'react';
import MultipleSelector from "../../../../common/multipleSelector/MultipleSelector";
import {getAvailableSensors} from "../../../../../utils/stationUtil";
import {useTranslation} from "react-i18next";

const VariableStep = ({selectedStations,initialSelection,callback}) => {

    const {t} = useTranslation();

    const availableSensorsOptions = useMemo(() => {
        const aqiItem = {id: "aqi", label: t("legend.AQI")};
        return [...getAvailableSensors(selectedStations).map(item => ({id: item.id, label: item.alias})), aqiItem];
    }, [t, selectedStations]);

    return (
        <MultipleSelector initialSelected={initialSelection}
                          items={availableSensorsOptions}
                          selectedCallback={callback}
                          min={1} />
    );
};

export default VariableStep;
