import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {Button, Card, CardContent, Grid, Link, Typography} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {makeStyles} from "@mui/styles";
import {DataGrid} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getVariableUnitName} from "../unitsNames";
import {useMountComponent} from "../../../../hooks/useMountComponent";
import useMessage from "../../../../hooks/useMessage";
import {deleteNotificationRequest} from "../../../../requests/alarms/notifications/deleteNotificationRequest";
import {getStationAliasesMap} from "../../../../utils/stationUtil";
import useUnits from "../../../../hooks/useUnits";
import {notificationRequest} from "../../../../requests/alarms/notifications/notificationRequest";
import AlarmStatePopUp from "./configure_alarms/AlarmStatePopUp";
import {setNotificationCountAction} from "../../../../reducers/notificationReducer";
import {
    notificationReducer,
    notificationInitialState,
    removeNotificationByIdsAction,
    addNotificationAction, setNotificationDataAction, resetNotificationDataAction
} from "./notificationReducer";
import usePrevious from "../../../../hooks/usePrevious";
import LastUpdate from "../../../common/LastUpdate";
import useRequest from "../../../../hooks/useRequest";
import useUserPrefTimeZone from "../../../../hooks/useUserPrefTimeZone";
import {timeZoneOptions} from "../../../../constants";

const pageSize = 7;

const useStyles = makeStyles({
    cardTittle:{
        marginLeft:20,
        color:"#000000",
        fontWeight:"bold"
    },
    rowTable:{
        display:"flex",
        width:"100%vw",
        justifyContent:"center",
        marginTop:32
    },
    table:{
        maxWidth:1600,
        height: 480,
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {
        },
    }
});

const getNotificationsWithoutAlarmCount = (notifications, alarms) => {
    return notifications.filter(notification => !alarms.find(alarm => alarm.id === notification.alarmId)).length;
};

const NotificationsCardView = ({className, alarms, updateAlarmCallback}) => {

    const{t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useMountComponent();
    const { setError, setSuccess } = useMessage();
    const { handleErrorResponse } = useRequest();
    const { units, convertToUserUnit } = useUnits();
    const { lastNotification } = useSelector(state => state.notification);
    const [{ notifications, notificationCount }, notificationDispatch] = useReducer(notificationReducer, notificationInitialState);
    const [page, setPage] = useState(0);
    const [loadedNotifications, setLoadedNotifications] = useState(false);
    const [alarmStatePopUp, setAlarmStatePopup] = useState(null);
    const [notificationsWithoutAlarmCount, setNotificationsWithoutAlarmCount] = useState(0);
    const stationAliases = useSelector(state => getStationAliasesMap(state.dashboardUI.stations), shallowEqual);
    const [{enableDelete,selectionModel,loading},updateState] =
        useState({selectionModel:[],
            enableDelete:false,data:[],loading:false,selectedDate:new Date(),error:false});
    const [currentLastUpdate, setCurrentLastUpdate] = useState(null);
    const [updateAvailable, setUpdateAvailable] = useState(false);
    const {convertStationDateToUserPrefTz, userTimeZone} = useUserPrefTimeZone();

    const handleDeleteButtonPressed = () => {
        confirmDelete();
    };

    const handleDisplayAlarmState = (alarm) => {
        setAlarmStatePopup(alarm);
    };

    const handleCloseAlarmStatePopUp = () => {
        setAlarmStatePopup(null);
    };

    const onSelectionModelChange = (newSelectionModel)=>{
        updateState(state =>{
            return {...state,enableDelete:(newSelectionModel.length !== 0),
                selectionModel:newSelectionModel};
        });
    };

    const confirmDelete = useCallback(()=>{
        updateState(state => ({...state,loading: true}));
        deleteNotificationRequest(selectionModel).then((response) => {
            if (!isMounted.current) {return;}
            updateState(state => ({...state,loading: false,selectionModel:[],enableDelete:false }));
            if (response.status === 207) {
                setError("requestErrors.savingData");
                setUpdateAvailable(true);
            } else {
                setSuccess("alarmScreen.notifications.marked_as_read");
                notificationDispatch(removeNotificationByIdsAction(selectionModel));
                dispatch(setNotificationCountAction(notificationCount - selectionModel.length));
            }
        }).catch(err => {
            if (!isMounted.current) {return;}
            updateState(state => ({...state,loading: false,selectionModel:[],enableDelete:false}));
            handleErrorResponse(err?.response, response => {
                setError(response,
                    false,
                    `${t("error")} ${response.status},
                            ${t("alarmScreen.deleting_notification")}`);
            });
        });
    },[t, dispatch, selectionModel, setError, setSuccess, isMounted, notificationCount, handleErrorResponse]);

    const columns = useMemo(() => {
        return [
            { field: 'alarm', headerName: t("alarmScreen.alarm") , width: 140, sortable: false,
                renderCell: (params) => {
                    const alarm = alarms.find(alarm => alarm.id === params.row.alarmId);
                    return <Typography>{alarm ? <Link component="button" underline="always" onClick={(event) => {
                        event.stopPropagation();
                        handleDisplayAlarmState(alarm);
                    }}>{params.row.alarmName}</Link> : params.row.alarmName}</Typography>;
                }
            },
            { field: 'station', headerName: t("alarmScreen.station") , width: 140, sortable: false,
                renderCell: (params) => <Typography>{stationAliases[params.value]}</Typography>
            },
            { field: 'triggered', headerName: t("alarmScreen.state") , width: 140, sortable: false,
                renderCell: (params) => (
                    <Typography>{params.value ? t("alarmScreen.triggered") : t("alarmScreen.not_triggered")}</Typography>
                )
            },
            { field: 'value', headerName: t("alarmScreen.notifications.value"), width: 180, sortable: false,
                renderCell: (params) => (
                    <Typography>{ isNaN(params.value) ? t(`alarmScreen.notifications.${params.value}`) : params.value ? `${convertToUserUnit(params.row.variable, params.value)}
                ${getVariableUnitName(params.row.variable, units)}` : "--"}</Typography>
                )
            },
            { field: 'date', headerName: `${t("alarmScreen.notifications.date")} ${userTimeZone === timeZoneOptions.UTC ? "(UTC)":""}`, width: 300, sortable: false,
                renderCell: (params) => <Typography>{ convertStationDateToUserPrefTz(params.row.station, new Date(params.value), userTimeZone !== timeZoneOptions.UTC, false)}</Typography>
            }
        ];

    }, [t, userTimeZone, alarms, stationAliases, convertToUserUnit, units, convertStationDateToUserPrefTz]);


    const sortedNotifications = useMemo(() => {
        return [...notifications].sort((a, b) => a.date > b.date ? -1 : 1);
    }, [notifications]);

    const rows = useMemo(() => {
        return sortedNotifications.slice(page * pageSize, (page + 1) * pageSize);
    }, [page, sortedNotifications]);

    const loadNotifications = useCallback((limit, nextIndex, offsetId) => {
        updateState(state => ({...state,loading: true}));
        notificationRequest(limit, offsetId, (err, data) => {
            updateState(state => ({...state,loading: false}));
            if (!err) {
                notificationDispatch(setNotificationDataAction(data));
                setCurrentLastUpdate(new Date());
                setUpdateAvailable(false);
                dispatch(setNotificationCountAction(data.count));
            } else {
                handleErrorResponse(data, response => {
                    setError(response, false, `Error ${response.status} downloading notifications`);
                });
            }
        });
    }, [notificationDispatch, dispatch, handleErrorResponse, setError]);

    useEffect(() => {
        if (!loadedNotifications) {
            const limit = pageSize * 3;
            loadNotifications(limit, limit, null);
            setLoadedNotifications(true);
        }
    }, [loadNotifications, loadedNotifications, setLoadedNotifications]);

    useEffect(() => {
        if (lastNotification) {
            notificationDispatch(addNotificationAction(lastNotification));
        }
    }, [lastNotification, notificationDispatch]);

    useEffect(() => {
        const offsetItem = notifications[notifications.length - 1];
        if (offsetItem) {
            // load next page data
            const pageCache = 1;
            const limit = pageSize * 3;
            const nextPage = page + 1 + pageCache;
            const nextIndex = Math.min(nextPage * pageSize, notificationCount);
            const itemsCount = notifications.length;

            if (nextIndex > itemsCount) {
                loadNotifications(limit, nextIndex, offsetItem.eventId);
            }
        }
    }, [page, notifications, notificationCount, loadNotifications]);

    const resetNotificationData = useCallback(() => {
        notificationDispatch(resetNotificationDataAction());
        setLoadedNotifications(false);
        setNotificationsWithoutAlarmCount(0);
    }, [notificationDispatch, setLoadedNotifications]);

    const handleRefreshNotifications = useCallback(() => {
        resetNotificationData();
    }, [resetNotificationData]);

    usePrevious((prevAlarms, prevNotifications) => {
        const newNotificationsWithoutAlarmCount = getNotificationsWithoutAlarmCount(notifications, alarms);
        // Only if the number of notifications without alarm has changed...
        if (notificationsWithoutAlarmCount < newNotificationsWithoutAlarmCount) {
            if (prevAlarms !== alarms) { // if alarms changed, show notification refresh button
                setUpdateAvailable(true);
            }
            if (prevNotifications.length < notifications.length) { // if notifications changed, activate alarm update callback
                updateAlarmCallback();
            }
        }
        setNotificationsWithoutAlarmCount(newNotificationsWithoutAlarmCount);
    }, [alarms, notifications, notificationsWithoutAlarmCount, updateAlarmCallback]);

    const classes = useStyles();

    return (
        <Card className={className}>
            <CardContent>
                <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid container  item xs={12} sm={12} md={8} lg={9} alignItems={"flex-start"} alignContent={"flex-start"}>
                        <Typography className={classes.cardTittle} variant={"h5"}>{t("alarmScreen.notifications.notifications")}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Button
                            data-testid={"delete-notification-button"}
                            fullWidth
                            disabled={loading || !enableDelete }
                            variant="contained"
                            onClick={handleDeleteButtonPressed}
                            color="success"
                            startIcon={<DoneIcon />}
                        >
                            {t("action.mark_as_read")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.rowTable} >
                        <DataGrid
                            className={classes.table}
                            rows={rows}
                            columns={columns}
                            columnBuffer={8}
                            pagination
                            checkboxSelection
                            pageSize={pageSize}
                            rowsPerPageOptions={[pageSize]}
                            rowCount={notificationCount}
                            paginationMode="server"
                            onPageChange={(newPage) => {
                                setPage(newPage);
                            }}
                            keepNonExistentRowsSelected
                            name="dataGrid2"
                            selectionModel={selectionModel}
                            onSelectionModelChange={onSelectionModelChange}
                            density={"standard"}
                            getRowId={(row) => row.eventId}
                            loading={loading}
                            hideFooterPagination={loading}
                            disableColumnFilter
                            disableColumnMenu
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LastUpdate date={currentLastUpdate}
                                    updateAvailable={updateAvailable}
                                    handleRefreshCallback={handleRefreshNotifications}/>
                    </Grid>
                </Grid>
            </CardContent>
            {
                alarmStatePopUp !== null &&
                <AlarmStatePopUp alarm={alarmStatePopUp} handleClose={handleCloseAlarmStatePopUp}/>
            }
        </Card>
    );
};

export default NotificationsCardView;
