import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {unitsMap} from "../../unitsNames";
import {compareStatisticRequest} from "../../../../../requests/compare/compareStatisticsRequest";
import {useSelector} from "react-redux";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import useRequest from "../../../../../hooks/useRequest";
import {DEFAULT_PERIOD, sensor} from "../../../../../constants";
import useMessage from "../../../../../hooks/useMessage";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {useTranslation} from "react-i18next";
import {isEqual} from "lodash";
import {mapStationsToIdAlias} from "../../../../../utils/stationUtil";
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";
import {getVariableUnit} from "../../../../../utils/getVariableUnit";

const useStyles = makeStyles({
    table: {
        paddingTop: 10,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20
    }
});

const CompareStatisticCardView = ({className}) => {

    const {t} = useTranslation();
    const notFoundMessage = useMemo(() => {
        return t("dataNotFound");
    }, [t]);
    const {handleErrorResponse} = useRequest();
    const initialState = {rawData: {}, hiddenVariables: [], error: notFoundMessage, loading: false};
    const [{loading, rawData, error}, updateState] = useState(initialState);
    const selectCompareStations = useSelector(state => mapStationsToIdAlias(state.dashboardUI.selectCompareStations), isEqual);
    const { units } = useSelector( state => state.auth );
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [dateRange, setDateRange] = useState(null);
    const selectedRange = useMemo(() => getDateRange(period, dateRange), [period, dateRange]);
    const [selectedSensor, setSelectedSensor] = useState();
    const isMounted = useMountComponent();
    const { setError } = useMessage();

    const classes = useStyles();

    useEffect(() => {
        updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectCompareStations, selectedRange]);

    const updateData = useCallback(() => {

        updateState(state => {
            return {
                ...state,
                loading: true,
                data: [],
                error: "",
                rawData:{}
            };
        });

        compareStatisticRequest({
            "stations": selectCompareStations.map(item=> item.id),
            "pollutants": units.pollutants,
            "temperature": units.temperature,
            "time": selectedRange[0],
            "endtime": selectedRange[1]
        }, (err, data) => {

            if (isMounted.current) {
                if (!err) {
                    updateState(state => ({
                        ...state,
                        loading: false,
                        rawData: data
                    }));
                } else {
                    if (data.status === 404){
                        updateState(state =>{
                            return {...state,loading: false, error:notFoundMessage};
                        });
                    } else {
                        updateState(state =>{
                            return {...state,loading: false,error:data.status};
                        });
                        handleErrorResponse(data, response => {
                            setError(response, false,
                                "compareScreen.statistic.could_not_update");
                        });
                    }
                }
            }
        });
    }, [selectCompareStations, units.pollutants, units.temperature, selectedRange, isMounted, notFoundMessage, handleErrorResponse, setError]);

    const statsTableColumns = useMemo(() => [
        {
            id: "min",
            title: t("analyticScreen.statistic.min")
        },
        {
            id: "p5",
            title: `${t("analyticScreen.statistic.percentile_short")} 5`
        },
        {
            id: "p25",
            title: `${t("analyticScreen.statistic.percentile_short")} 25`
        },
        {
            id: "mean",
            title: t("analyticScreen.statistic.mean")
        },
        {
            id: "p75",
            title: `${t("analyticScreen.statistic.percentile_short")} 75`
        },
        {
            id: "p95",
            title: `${t("analyticScreen.statistic.percentile_short")} 95`
        },
        {
            id: "max",
            title: t("analyticScreen.statistic.max")
        }
    ], [t]);

    const sensorList = useMemo(() => {
        const sensors = [];
        statsTableColumns.forEach(({id}) => {
            const columnData = rawData?.[id];
            if (columnData) {
                Object.keys(columnData).forEach(pollutant => {
                    if (!sensors.includes(pollutant)) sensors.push(pollutant);
                });
            }
        });
        return Object.values(sensor).filter((id) => sensors.includes(id));
    }, [rawData, statsTableColumns]);

    useEffect(() => {
        if (sensorList[0] && !selectedSensor) {
            setSelectedSensor(sensorList[0]);
        }
    }, [sensorList, selectedSensor]);

    const statsTableData = useMemo(() => (
        selectCompareStations.map((station) => {
            const newStation = {...station};
            statsTableColumns.forEach(({id}) => {
                const value = rawData?.[id]?.[selectedSensor]?.[station.id]?.value;
                newStation[id] = value === undefined ? "-" : value;
            });
            return newStation;
        })
    ), [statsTableColumns, rawData, selectCompareStations, selectedSensor]);

    const sensorCallback = useCallback((sensor) => {
        setSelectedSensor(sensor);
    }, []);

    const variableUnit = useMemo(() => (
        unitsMap.get(getVariableUnit(selectedSensor, units))
    ), [selectedSensor, units]);

    return (
        <CardLayout className={className} title={t("compareScreen.statistic.title")}
                    helpText={t("compareScreen.statistic.en_compare_stationStatistic")}>

            <ChartLayout error={error} loading={loading} emptyData={Object.keys(rawData).length === 0} height={240}
                         period={period} onPeriodChange={setPeriod} dateRange={dateRange}
                         onDateRangeChange={setDateRange} onPollutantSelect={sensorList.length > 0 && sensorCallback}
                         selectedPollutant={selectedSensor} pollutantList={sensorList}>
                <TableContainer>
                    <Table stickyHeader aria-label="event table" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
                                    {t("alarmScreen.station")}
                                </TableCell>
                                <TableCell align="left">
                                    {t("analyticScreen.statistic.unit")}
                                </TableCell>
                                {statsTableColumns.map(({id, title}) => (
                                    <TableCell key={id} align="right">
                                        {title}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {statsTableData.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="left">
                                        {row.alias}
                                    </TableCell>
                                    <TableCell align="left">
                                        {variableUnit}
                                    </TableCell>
                                    {statsTableColumns.map(({id}) => (
                                        <TableCell key={id} align="right">
                                            {row[id] ?? "-"}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ChartLayout>
        </CardLayout>
    );
};

export default CompareStatisticCardView;
