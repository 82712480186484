import React from 'react';
import {Checkbox, FormControlLabel} from "@mui/material";

const StationCheckboxSelector = ({stationList, onCheckChange}) => {
    return (
        <div style={{display: "flex"}}>
            {stationList.map((station, index) => {
                return (<FormControlLabel key={index}
                        control={
                            <Checkbox checked={station.checked}
                                      onChange={event => onCheckChange(event.target.name, event.target.checked)}
                                      name={station.name}
                                      sx={{
                                          color: station.color,
                                          '&.Mui-checked': {
                                              color: station.color,
                                          },
                                      }}
                            />
                        }
                        label={station.name}
                    />
                );
            })}
        </div>
    );
};

export default StationCheckboxSelector;
