import _ from "lodash";
import {compareStationsColors} from "../../CompareScreen";


/**
 *
 * @param startOnSunday {boolean}
 * @param weekDaysStarOnSunday {[string]}
 * @param unit {string}
 * @param hourDayOfWeek
 * @returns {Object}
 */
export const getHourDayOfWeekChartLayout = (startOnSunday, weekDaysStarOnSunday, unit, hourDayOfWeek) => {

    const yRange = getYRange(hourDayOfWeek);

    const dayNames = startOnSunday === true ? weekDaysStarOnSunday : [...weekDaysStarOnSunday.slice(1, 7), weekDaysStarOnSunday[0]];

    const dayWidth = 1 / 7;
    let weekLayout = {};

    for (let i = 1; i <= 7; i++) {
        weekLayout[i === 1 ? "xaxis" : `xaxis${i}`] = {
            anchor: `y${i}`,
            domain: [i * dayWidth - dayWidth, i * dayWidth],
            range: [0, 24],
            showgrid: true,
            side: "top",
            mirror: true,
            ticklen: 8,
            title: {
                text: dayNames[i - 1],
            },
            linecolor: '#000000',
            tickmode: "array",
            tickvals: [0, 6, 12, 18],
            autotick: false,
            fixedrange: true
        };
        weekLayout[i === 1 ? "yaxis" : `yaxis${i}`] = {
            anchor: `x${i}`,
            showticklabels: i === 1,
            title: {text: i === 1 ? unit : "", standoff: 10},
            zerolinecolor: '#ffffff',
            range: yRange,
            domain: [0.6, 1],
            showgrid: true,
            ticklen: i === 1 ? 8 : 0,
            mirror: i === 7 ? 'ticks' : false,
            showline: true,
            linecolor: '#000000',
            linewidth: 1,
            fixedrange: true,
            zeroline:false
        };
    }
    return weekLayout;
};


export const wrapColors = [ '#696ed2',
    '#d08da3',
    '#9ed590',
    '#d4c890',
    '#97e0cf',
    '#708098',
    '#dbae9c',
    '#bf98d0',
    '#918e8e',
    '#d2cece'
];
export const fillColors = (index, isP75)=>{

    const transparentColor = [
        'rgba(26,35,208,0.2)',
        'rgba(208,26,85,0.2)',
        'rgba(64,208,26,0.2)',
        'rgba(239,202,17,0.2)',
        'rgba(26,208,166,0.2)',
        'rgba(73,97,134,0.2)',
        'rgba(231,103,53,0.2)',
        'rgba(153,26,208,0.2)',
        'rgba(0,0,0,0.2)',
        'rgba(123,123,123,0.2)'
    ];

    const solidColor = [
        'rgba(26,35,208,0.5)',
        'rgba(208,26,85,0.5)',
        'rgba(64,208,26,0.5)',
        'rgba(239,202,17,0.5)',
        'rgba(26,208,166,0.5)',
        'rgba(73,97,134,0.5)',
        'rgba(231,103,53,0.5)',
        'rgba(153,26,208,0.5)',
        'rgba(0,0,0,0.5)',
        'rgba(123,123,123,0.5)'
    ];
    return isP75 ? solidColor[index] : transparentColor[index];
};






/**
 *
 * @param pollutant {string}
 * @param unit {string}
 * @param type {"min"|"mean"|"max"|"p25"|"p75"}
 * @param dayData {{data:{Object}}}
 * @param chartIndex {Number}
 * @param seriesIndex
 * @returns {{[p: string]: *, mode: string, hoverlabel: {namelength: number}, fillcolor: string, marker: {color: (string)}, name: string, fill: (string), type: string, hovertemplate: string}}
 */
export const getWeekDaySeries = (pollutant, unit, type, dayData, chartIndex, seriesIndex) => {

    const chartData = {x: dayData.map(item => item.x), y: dayData.map(item => item[type])};

    const color = type === "mean" ? compareStationsColors[seriesIndex] : wrapColors[seriesIndex];
    const yaxis = `y${chartIndex.toString()}`;
    const xaxis = `x${chartIndex.toString()}`;

    return {
        ...chartData,
        yaxis,
        xaxis,
        mode: "lines",
        fill: type === "mean" ? "none" : "tonexty",
        fillcolor: fillColors(seriesIndex, type === "p75"),
        line: {color: type === "mean" ? color: "transparent" },
        type: 'scatter',
        marker: {color},
        hoverlabel: {namelength: 0},
        hovertemplate: `<b>${type}</b>: %{y} ${unit}`
    };
};

export const getHourDayOfWeekData = (pollutant, unit, pollutantWeekData, index, stationId) => {

    const range = Array.from({length: 7}, (_, i) => i);

    let weekChartTempSeries = range.map((dayOfWeekData, dayIndex) => {
        const chartIndex = dayIndex + 1;
        const data = pollutantWeekData.find(item => item.day === dayIndex)?.data ??
            Array.from({length: 24}, () => null).map(() => ({min: null, max: null, mean: null, p25:null, p75:null}));

        const nextSeries = pollutantWeekData.find(item => item.day === (dayIndex !== 6 ? dayIndex + 1 : 0))?.data;
        if (nextSeries) {
            data.push({...nextSeries[0], x: 24});
        } else {
            data.push({min: null, max: null, mean: null, p25:null, p75:null, x: 24});
        }

        const mean = getWeekDaySeries(pollutant, unit, "mean", data, chartIndex, index, stationId);

        const min = getWeekDaySeries(pollutant, unit, "min", data, chartIndex, index, stationId);

        const max = getWeekDaySeries(pollutant, unit, "max", data, chartIndex, index, stationId);

        const p25 = getWeekDaySeries(pollutant, unit, "p25", data, chartIndex, index, stationId);

        const p75 = getWeekDaySeries(pollutant, unit, "p75", data, chartIndex, index, stationId);

        return [mean, min, p25, p75, max];
    });
    return _.flattenDeep(weekChartTempSeries);
};

const getYRange = (data) => {

    let minValue = Number.MAX_VALUE;
    let maxValue = Number.MIN_VALUE;

    data.forEach(item => {
        item.forEach(item => item.data.forEach(item => {
            if (item.min < minValue) minValue = item.min;
            if (item.max > maxValue) maxValue = item.max;
        }));
    });
    return [minValue, maxValue];
};


