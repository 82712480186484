import React from 'react';
import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    box: {
        zIndex:9999,
        position:"absolute",
        top:0,
        left:0,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width:"100vw",
        height:"100vh",
        background:"white",
    }
});

const ServerErrorScreen = ({error}) => {

    const classes = useStyles();

    return (
         <div className={classes.box} >
            <Typography
                data-testid =  {"server-error-text"}
                variant={"h1"}>{error}</Typography>
        </div>
    );
};

export default ServerErrorScreen;
